import React from 'react'
import logo from 'assets/images/businesslogo.png'

const BusinessHeader = ({ info }) => {
  if (!info) return <div></div>
  return (
    <div className="business-info">
      <img src={info.image_url} alt="logo" />
      <div>
        <div className="title">{info.business_name}</div>
        <div
          style={{
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#505050'
          }}
          className="title"
        >
          {info.description}
        </div>
      </div>

      {/* <Rating /> */}
    </div>
  )
}

export default BusinessHeader
