import { EditFilled } from '@ant-design/icons'
import { Button, Col, Input, Row, Space, Form, Select, Tag } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { _getBusinessInfo, _updateBusinessStatus } from '../api'
import './styles.scss'
import youtube from 'assets/icons/youtube.png'
import whatsapp from 'assets/icons/whatsapp.png'
import facebook from 'assets/icons/facebook.png'
import twitter from 'assets/icons/twitter.png'
import instagram from 'assets/icons/instagram.png'
import external from 'assets/icons/external-link.png'

const ReviewBusiness = (props) => {
  const params = useParams()
  const [form] = Form.useForm()
  const [data, setData] = useState('')
  const [status, setStatus] = useState('')
  const [isSpinning, setIsSpinning] = useState(false)
  function onFinish() {
    _updateBusinessStatus(setIsSpinning, params.business_id, status)
  }

  useEffect(() => {
    _getBusinessInfo(params.business_id, (data) => {
      setData(data)
      setStatus(data.status)
      form.setFieldsValue({
        blNumber: data.blno,
        description: data.description,
        opration: data.hrs_of_operation,
        village: data.village,
        location: data.address,
        name: data.name,
        phone: data.phone,
        email: data.email,
        website: data.website,
        instagram: data.instagram,
        twitter: data.twitter,
        youtube: data.youtube,
        whatsapp: data.whatsapp,
        facebook: data.facebook
      })
    })
    return () => {}
  }, [])
  return (
    <div className="update-admin-info-wrapper">
      <div>
        <SpinLoader spinning={!data || isSpinning}>
          <div className="page-title">
            <h1>Update Information</h1>
          </div>
          <div style={{ margin: '32px' }}>
            {data && (
              <div className="business-info">
                <div>
                  <h1>
                    {data.business_name}
                    {/* (DBA) */}
                  </h1>
                </div>
              </div>
            )}

            <div className="form-wrapper">
              <Row>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <Form
                    form={form}
                    requiredMark={false}
                    labelAlign="left"
                    name="businessInformation"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    // initialValues={{ remember: true }}
                  >
                    <Form.Item label="Status" name="blNumber">
                      <div className="business-info">
                        <div>
                          <Select
                            size="large"
                            value={status}
                            style={{ width: '200px' }}
                            onChange={(val) => setStatus(val)}
                          >
                            <Select.Option value={'APPROVED'}>
                              APPROVED
                            </Select.Option>
                            <Select.Option value={'REJECTED'}>
                              {' '}
                              REJECTED
                            </Select.Option>
                            <Select.Option value={'SUBMITTED'}>
                              SUBMITTED
                            </Select.Option>
                          </Select>
                        </div>
                        <div>
                          <Button
                            size="large"
                            type="primary"
                            onClick={onFinish}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form.Item>
                    <h2>
                      <i>Business Information</i>
                    </h2>
                    <Form.Item label="BL Number" name="blNumber">
                      <Input className="input-style" readOnly />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                      <Input.TextArea
                        rows={4}
                        className="input-style"
                        readOnly
                      />
                    </Form.Item>
                    <Form.Item label="Hours of Operation" name="opration">
                      <Input className="input-style" readOnly />
                    </Form.Item>
                    <Form.Item label="Village" name="village">
                      <Input className="input-style" readOnly />
                    </Form.Item>
                    <Form.Item label="Home-Based Location" name="location">
                      <Input className="input-style" readOnly />
                    </Form.Item>

                    <Form.Item label="Name" name="name">
                      <Input className="input-style" readOnly />
                    </Form.Item>
                    <Form.Item label="Phone" name="phone">
                      <Input className="input-style" readOnly />
                    </Form.Item>
                    <Form.Item label="email" name="email">
                      <Input className="input-style" readOnly />
                    </Form.Item>
                    <Form.Item label="Website" name="website">
                      <Input
                        className="input-style"
                        readOnly
                        value={data.website}
                      />
                      {data.website && (
                        <a
                          href={data.website}
                          target="_blank"
                          rel="noreferrer"
                          className="ext-link"
                        >
                          <img src={external} alt=";ink" height={15} />
                        </a>
                      )}
                    </Form.Item>
                    <Form.Item
                      className="remove-content"
                      label={<img width={20} src={facebook} alt="logo" />}
                      name="facebook"
                    >
                      <Input
                        className="input-style"
                        readOnly
                        value={data.facebook}
                      />
                      {data.facebook && (
                        <a
                          href={data.facebook}
                          target="_blank"
                          rel="noreferrer"
                          className="ext-link"
                        >
                          <img src={external} alt=";ink" height={15} />
                        </a>
                      )}
                    </Form.Item>
                    <Form.Item
                      className="remove-content"
                      label={<img width={20} src={instagram} alt="logo" />}
                      name="instagram"
                    >
                      <Input
                        className="input-style"
                        readOnly
                        value={data.instagram}
                      />
                      {data.instagram && (
                        <a
                          href={data.instagram}
                          target="_blank"
                          rel="noreferrer"
                          className="ext-link"
                        >
                          <img src={external} alt=";ink" height={15} />
                        </a>
                      )}
                    </Form.Item>
                    <Form.Item
                      className="remove-content"
                      label={<img width={20} src={twitter} alt="logo" />}
                      name="twitter"
                    >
                      <Input
                        className="input-style"
                        readOnly
                        value={data.twitter}
                      />
                      {data.twitter && (
                        <a
                          href={data.twitter}
                          target="_blank"
                          rel="noreferrer"
                          className="ext-link"
                        >
                          <img src={external} alt=";ink" height={15} />
                        </a>
                      )}
                    </Form.Item>
                    <Form.Item
                      className="remove-content"
                      label={<img width={20} src={youtube} alt="logo" />}
                      name="youtube"
                    >
                      <Input
                        className="input-style"
                        readOnly
                        value={data.youtube}
                      />
                      {data.youtube && (
                        <a
                          href={data.youtube}
                          target="_blank"
                          rel="noreferrer"
                          className="ext-link"
                        >
                          <img src={external} alt=";ink" height={15} />
                        </a>
                      )}
                    </Form.Item>
                    <Form.Item
                      className="remove-content"
                      label={<img width={20} src={whatsapp} alt="logo" />}
                      name="whatsapp"
                    >
                      <Input className="input-style" readOnly />
                    </Form.Item>
                    <Form.Item label="Tags" name="tags">
                      {data.category &&
                        data.category
                          .split(',')
                          .map((tag) => <Tag style={{ margin: 4 }}>{tag}</Tag>)}
                      {data.tags &&
                        data.tags
                          .split(',')
                          .map((tag) => <Tag style={{ margin: 4 }}>{tag}</Tag>)}
                    </Form.Item>
                    <Form.Item></Form.Item>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                  <div style={{ marginLeft: 50 }}>
                    <img
                      src={
                        data.image_url ||
                        `https://guma-bus-registry.s3.us-west-1.amazonaws.com/business/logo/${data.id}.png`
                      }
                      width={200}
                      alt="logo"
                    />
                  </div>
                </Col>
                <Col span={10}>
                  <div className="uploadfile">{/* <UploadFile /> */}</div>
                </Col>
              </Row>
            </div>
          </div>
        </SpinLoader>
      </div>
    </div>
  )
}

export default ReviewBusiness
