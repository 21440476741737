import { Input, Form, Button, Row, Col, Select } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useEffect, useState } from 'react'
import { updateProduct } from './api'

const EditProduct = ({ record }) => {
  const [showLoader, setShowLoader] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      product_name: record.product_name,
      product_name_japanese: record.product_name_japanese,
      product_name_korean: record.product_name_korean,
      product_name_chinese: record.product_name_chinese,
      product_name_chamoru: record.product_name_chamoru,
      product_name_chuukese: record.product_name_chuukese,
      product_description: record.product_description,
      product_description_japanese: record.product_description_japanese,
      product_description_korean: record.product_description_korean,
      product_description_chinese: record.product_description_chinese,
      product_description_chamoru: record.product_description_chamoru,
      product_description_chuukese: record.product_description_chuukese
    })
  }, [])
  const inputList = [
    { name: 'product_name', label: 'Name' },
    { name: 'product_name_japanese', label: 'Name Japanese' },
    { name: 'product_name_korean', label: 'Name Korean' },
    { name: 'product_name_chinese', label: 'Name Chinese' },
    { name: 'product_name_chamoru', label: 'Name Chamoru' },
    { name: 'product_name_chuukese', label: 'Name Chukkese' }
  ]
  const textAreaList = [
    { name: 'product_description', label: 'Description' },
    { name: 'product_description_japanese', label: 'Description Japanese' },
    { name: 'product_description_korean', label: 'Description Korean' },
    { name: 'product_description_chinese', label: 'Description Chinese' },
    { name: 'product_description_chamoru', label: 'Description Chamoru' },
    { name: 'product_description_chuukese', label: 'Description Chukkese' }
  ]
  return (
    <SpinLoader spinning={showLoader}>
      <Form
        form={form}
        name="editCategory"
        labelAlign="left"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={values => updateProduct(setShowLoader, values, record)}
      >
        <Row gutter={24}>
          <Col md={12}>
            <div>
              {inputList.map(input => (
                <Form.Item name={input.name} label={input.label}>
                  <Input />
                </Form.Item>
              ))}
            </div>
          </Col>
          <Col md={12}>
            <div>
              {textAreaList.map(input => (
                <Form.Item name={input.name} label={input.label}>
                  <Input.TextArea cols={3} />
                </Form.Item>
              ))}
            </div>
          </Col>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </SpinLoader>
  )
}

export default EditProduct
