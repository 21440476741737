import React, { useEffect } from 'react'
import home1 from 'assets/images/guam.png'
import './styles.scss'
import { Button, Col, Input, Row, Space } from 'antd'
import { StarFilled } from '@ant-design/icons'
import logo1 from 'assets/images/GUMA IBM LOGO - ROUND.png'
import logo2 from 'assets/images/GUMA Logo 2021-01.png'
// import { fetchSubcategoryList } from 'pages/ExploreBusiness/api'
import appStore from 'assets/icons/App_store@2x.png'
import playStore from 'assets/icons/App_Store_Google@2x.png'
import banner2 from 'assets/images/pexels-cottonbro-5081918.jpg'

import searchIcon from 'assets/icons/search.png'
import locationIcon from 'assets/icons/location.png'
import socialMediaIcon from 'assets/icons/social-media.png'
import store from 'redux/store'
import { handleIsVisibleVillageModal } from 'pages/common.slice'
import { useSelector } from 'react-redux'
import { handleCategoryLanguage } from 'helpers/language'
import locales from 'locales'

const { Search } = Input
const Home = props => {
  const common = useSelector(state => state.common)

  const featuredVendors = [
    {
      type: 'Food & Beverage',
      image:
        'https://c.ndtvimg.com/2020-05/9iuj3h1g_indian-food_625x300_19_May_20.jpg',

      title: 'Gaurmet Guam',
      rating: '4.5',
      ratingCount: '2.5k'
    },
    {
      type: 'Entertainment',
      image:
        'https://c.ndtvimg.com/2020-05/9iuj3h1g_indian-food_625x300_19_May_20.jpg',

      title: 'Local Deluxe',
      rating: '5.0',
      ratingCount: '3.5k'
    },
    {
      type: 'Event Services',
      image:
        'https://c.ndtvimg.com/2020-05/9iuj3h1g_indian-food_625x300_19_May_20.jpg',
      title: 'Eve Gaum',
      rating: '4.3',
      ratingCount: '2.5k'
    }
  ]

  const howItWorks = [
    {
      icon: searchIcon,
      title: locales.needText,
      description: locales.needDesc
    },
    {
      icon: locationIcon,
      title: locales.availablityText,
      description: locales.availablityDesc
    },
    {
      icon: socialMediaIcon,
      title: locales.whereText,
      description: locales.whereDesc
    }
  ]

  function handleSubheader(category) {
    if (common.selectedVillage) {
      props.history.push(
        `/business?search=${category}&village=${common.selectedVillage}&category=${category}`
      )
    } else {
      store.dispatch(handleIsVisibleVillageModal(true))
    }
  }
  useEffect(() => {
    // fetchSubcategoryList()
    return () => {}
  }, [])
  return (
    <div className="home-wrapper">
      <div className="banner" style={{ backgroundImage: `url(${home1})` }}>
        <div>
          <Search
            placeholder={locales.searchText}
            enterButton={locales.search}
            size="large"
            onSearch={value => {
              if (!value) return
              if (common.selectedVillage) {
                props.history.push(
                  `/business?search=${value}&village=${common.selectedVillage}`
                )
              } else {
                store.dispatch(handleIsVisibleVillageModal(true))
              }
            }}
            // loading
          />
          <div className="suggestion-item">
            {!!common.categories.length &&
              common.categories.slice(3, 8).map(cat => (
                <div
                  className="item"
                  onClick={() => handleSubheader(cat.category)}
                >
                  {handleCategoryLanguage(cat)}
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* FEATURED VENDORS */}
      <div className="feature-card">
        <b>
          <h1>{locales.featureVendorTitle}</h1>
        </b>
        <div className="feature-card-wrapper">
          <Row>
            {featuredVendors.map(item => (
              <Col xs={24} sm={24} md={24} xl={8} lg={8}>
                <div className="feature-card-container">
                  <div className="heading">
                    <div className="type-tag">{item.type}</div>
                    <div className="title-wrapper">
                      <div className="title">{item.title}</div>
                      <div className="rating">
                        <span className="rating-star">
                          <StarFilled
                            style={{ fontSize: '16px', color: '#ffc107' }}
                          />
                          {item.rating}{' '}
                        </span>
                        <span className="rating-count">
                          ({item.ratingCount})
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="image-wrapper"
                    style={{ backgroundImage: `url(${item.image})` }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      {/* how it works*/}

      <div className="how-it-works">
        <h3>
          <b>{locales.howItWorks}</b>
        </h3>
        <br />
        <div className="how-it-works-wrapper">
          <Row>
            {howItWorks.map((item, index) => (
              <Col xs={24} sm={24} md={24} xl={8} lg={8}>
                <div className="how-it-works-container">
                  <div className="icon">
                    <img src={item.icon} alt="icon" />
                  </div>
                  <div className="title">
                    <b>
                      {index + 1}. {item.title}
                    </b>
                  </div>
                  <div className="description">{item.description}</div>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <Button type="default">{locales.learnMore}</Button>
      </div>

      {/* app*/}

      <div className="app-description">
        <Row>
          <div className="image">
            <div>
              <img src={banner2} alt="coming soon" />
            </div>
          </div>
          <div className="description">
            <div className="logo">
              <span>
                <img src={logo1} alt="logo" />
              </span>
              <span>
                <img src={logo2} alt="logo" />
              </span>
            </div>
            <br />
            <div className="title">
              <b>
                <div>{locales.comingSoon}</div>
                <div>The Village Resource</div>
                <div>{locales.directoryApp}</div>
              </b>
            </div>
            <div className="app-info">{locales.comingSoonDesc}</div>
            <div className="store-icons">
              <span>
                <img src={playStore} alt="" />
              </span>
              <span>
                <img src={appStore} alt="" />
              </span>
            </div>
          </div>
        </Row>
      </div>
    </div>
  )
}

export default Home
