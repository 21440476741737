import { Upload, message } from 'antd'
import React from 'react'
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons'
import './styles.scss'
import constants from 'config/constants'
import routes from 'config/routes'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
//   if (!isJpgOrPng) {
//     message.error('You can only upload JPG/PNG file!')
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2
//   if (!isLt2M) {
//     message.error('Image must smaller than 2MB!')
//   }
//   return isJpgOrPng && isLt2M
// }

export default class UploadFile extends React.Component {
  state = {
    loading: false,
    imageUrl: ''
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        this.props.handleFileUpload &&
          this.props.handleFileUpload(info.file.response.data)
        this.setState({
          imageUrl,
          loading: false
        })
      })
    }
  }

  render() {
    const { loading, imageUrl } = this.state

    const uploadButton = (
      <div className="btn-label">
        {loading ? <LoadingOutlined /> : <UploadOutlined />}
        <div>{this.props.label || 'Upload Photo'}</div>
      </div>
    )
    return (
      <div className="uploader-wrapper">
        <Upload
          maxCount={1}
          multiple={false}
          accept="image/*"
          // fileList={state.xMarksheet}
          // onChange={(e) => _onChangeFile('xMarksheet', e)}
          data={this.props.data}
          action={this.props.action}
          name={'doc'}
          // headers={authorization().headers}
          // beforeUpload={beforeUpload}
          onChange={this.handleChange}
          showUploadList={{
            showRemoveIcon: false
          }}
        >
          {imageUrl || this.props.imageUrl ? (
            <img
              src={imageUrl || this.props.imageUrl}
              alt="avatar"
              style={{ width: '100%', cursor: 'pointer' }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
    )
  }
}

// .avatar-uploader > .ant-upload {
//   width: 128px;
//   height: 128px;
// }
