import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messages: {}
}

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    updateBusinessList: (state, action) => {
      state.businessData = action.payload
    },
    updateMessages: (state, action) => {
      const { bus_id, data } = action.payload
      console.log(state)
      state.messages[bus_id] = data
    },
    updateMessageFromSocket: (state, action) => {
      const { bus_id, data } = action.payload
      state.messages[bus_id] = data

      const doc = document.querySelector('#admin-message-chat')
      setTimeout(() => {
       doc.scrollTo(0, doc.scrollHeight)
      }, 100);
    }
  }
})

export const {
  updateBusinessList,
  updateMessages,
  updateMessageFromSocket
} = adminSlice.actions
export default adminSlice.reducer
