import { Input, Form, Button, Row, Col, Select } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useEffect, useState } from 'react'
import { updateSubCategoty } from './api'

const EditSubCategory = ({ record }) => {
  const [showLoader, setShowLoader] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      subcategory: record.subcategory,
      subcategory_chamoru: record.subcategory_chamoru,
      subcategory_chinese: record.subcategory_chinese,
      subcategory_chuukese: record.subcategory_chuukese,
      subcategory_japanese: record.subcategory_japanese,
      subcategory_korean: record.subcategory_korean
    })
  }, [])
  console.log(record)
  return (
    <SpinLoader spinning={showLoader}>
      <Row>
        <Col md={12}>
          <Form
            form={form}
            name="editSubCategory"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            onFinish={values =>
              updateSubCategoty(setShowLoader, values, record)
            }
          >
            <Form.Item name="subcategory" label="Subcategory English">
              <Input />
            </Form.Item>
            <Form.Item name="subcategory_japanese" label="Subcategory Japanese">
              <Input />
            </Form.Item>
            <Form.Item name="subcategory_korean" label="Subcategory Korean">
              <Input />
            </Form.Item>
            <Form.Item name="subcategory_chinese" label="Subcategory Chinese">
              <Input />
            </Form.Item>
            <Form.Item name="subcategory_chamoru" label="Subcategory CHamoru">
              <Input />
            </Form.Item>
            <Form.Item name="subcategory_chuukese" label="Subcategory Chuukese">
              <Input />
            </Form.Item>
            {/* <Form.Item name="description" label="Description">
              <Input.TextArea />
            </Form.Item> */}
            {/* <Form.Item name="status" label="Status">
              <Select>
                <Select.Option value={'ACTIVE'}>ACTIVE</Select.Option>
                <Select.Option value={'INACTIVE'}>INACTIVE</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </SpinLoader>
  )
}

export default EditSubCategory
