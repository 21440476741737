import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import { getCategory } from 'pages/SignUp/api'
import store from 'redux/store'

export const createNewBusiness = (history, setIsSpinning) => {
  setIsSpinning(true)
  const { signup } = store.getState()
  const formData = new FormData()
  formData.append('logo', signup.imageUrl)
  formData.append(
    'data',
    JSON.stringify({
      userId: window.localStorage.getItem('userid'),
      name: signup.name,
      blNumber: signup.blNumber,
      dba: signup.dba,
      phone: signup.phone,
      website: signup.website,
      facebook: signup.facebook,
      instagram: signup.instagram,
      twitter: signup.twitter,
      youtube: signup.youtube,
      whatsapp: signup.whatsapp,
      email: signup.email,
      tags: signup.subcategory.join(','),
      address: signup.address,
      village: signup.village,
      businessName: signup.businessName,
      description: signup.description,
      operation: signup.hrs_of_operation,
      category: getCategory(signup).join(','),
      opration: signup.hrs_of_operation
    })
  )
  axios
    .post(`${constants.HOST}${routes.createNewBusiness}`, formData, {
      'Content-Type': 'multipart/form-data'
    })
    .then(({ data: response }) => {
      setIsSpinning(false)
      if (response.success) {
        message.success('Business has been successfully added ')
        history.push('/dashboard')
      } else {
        message.warning(response.message)
      }
    })
    .catch(error => {
      setIsSpinning(false)
      message.error('Internal server error')
    })
}
