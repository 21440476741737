import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'

export function fetchSubCategotyList(setSubCategoryList) {
  axios
    .get(`${constants.HOST}/manager/subcategory/list`)
    .then(function({ data: response }) {
      if (response.success) {
        setSubCategoryList(response.data)
      }
    })
    .catch(function(error) {})
}

export function updateSubCategoty(handleLoader, values, record) {
  console.log(values)
  handleLoader(true)
  axios
    .post(`${constants.HOST}/manager/subcategory/update`, {
      subcategory_id: record.subid,
      subcategory: values.subcategory,
      subcategory_japanese: values.subcategory_japanese,
      subcategory_chamoru: values.subcategory_chamoru,
      subcategory_chinese: values.subcategory_chinese,
      subcategory_chuukese: values.subcategory_chuukese,
      subcategory_korean: values.subcategory_korean
    })
    .then(function({ data: response }) {
      handleLoader(false)
      if (response.success) message.success(response.data)
      else message.error(response.message)
    })
    .catch(function(error) {
      handleLoader(false)
    })
}
