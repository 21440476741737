export function handleCategoryLanguage(cat) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return cat.category_japanese || cat.category
    case '中国人':
      return cat.category_chinese || cat.category
    case 'chamoru':
      return cat.category_chamoru || cat.category
    case 'chuukese':
      return cat.category_chuukese || cat.category
    case '한국인':
      return cat.category_korean || cat.category
    default:
      return cat.category
  }
}

export function handleSubCategoryLanguage(cat) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return cat.subcategory_japanese || cat.subcategory
    case '中国人':
      return cat.subcategory_chinese || cat.subcategory
    case 'chamoru':
      return cat.subcategory_chamoru || cat.subcategory
    case 'chuukese':
      return cat.subcategory_chuukese || cat.subcategory
    case '한국인':
      return cat.subcategory_korean || cat.subcategory
    default:
      return cat.subcategory
  }
}

export function handleProductLanguage(product) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return product.product_name_japanese || product.product_name
    case '中国人':
      return product.product_name_chinese || product.product_name
    case 'chamoru':
      return product.product_name_chamoru || product.product_name
    case 'chuukese':
      return product.product_name_chuukese || product.product_name
    case '한국인':
      return product.product_name_korean || product.product_name
    default:
      return product.product_name
  }
}

export function handleProductDescLanguage(product) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return product.product_description_japanese || product.product_description
    case '中国人':
      return product.product_description_chinese || product.product_description
    case 'chamoru':
      return product.product_description_chamoru || product.product_description
    case 'chuukese':
      return product.product_description_chuukese || product.product_description
    case '한국인':
      return product.product_description_korean || product.product_description
    default:
      return product.product_description
  }
}

export function handleBusinessLanguage(business) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return business.business_name_japanese || business.business_name
    case '中国人':
      return business.business_name_chinese || business.business_name
    case 'chamoru':
      return business.business_name_chamoru || business.business_name
    case 'chuukese':
      return business.business_name_chuukese || business.business_name
    case '한국인':
      return business.business_name_korean || business.business_name
    default:
      return business.business_name
  }
}

export function handleBusinessDescLanguage(business) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return business.description_japanese || business.description
    case '中国人':
      return business.description_chinese || business.description
    case 'chamoru':
      return business.description_chamoru || business.description
    case 'chuukese':
      return business.description_chuukese || business.description
    case '한국인':
      return business.description_korean || business.description
    default:
      return business.description
  }
}

export function handleChatTypeLanguage(chat) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return chat.type_japanese || chat.type
    case '中国人':
      return chat.type_chinese || chat.type
    case 'chamoru':
      return chat.type_chamoru || chat.type
    case 'chuukese':
      return chat.type_chuukese || chat.type
    case '한국인':
      return chat.type_korean || chat.type
    default:
      return chat.type
  }
}

export function handleChatQueLanguage(chat) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return chat.question_japanese || chat.question
    case '中国人':
      return chat.question_chinese || chat.question
    case 'chamoru':
      return chat.question_chamoru || chat.question
    case 'chuukese':
      return chat.question_chuukese || chat.question
    case '한국인':
      return chat.question_korean || chat.question
    default:
      return chat.question
  }
}


export function handleChatAnsLanguage(chat) {
  const lang = window.localStorage.getItem('lang')
  switch (lang) {
    case '日本':
      return chat.answer_japanese || chat.answer
    case '中国人':
      return chat.answer_chinese || chat.answer
    case 'chamoru':
      return chat.answer_chamoru || chat.answer
    case 'chuukese':
      return chat.answer_chuukese || chat.answer
    case '한국인':
      return chat.answer_korean || chat.answer
    default:
      return chat.answer
  }
}
