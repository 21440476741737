// import Rating from 'components/Ratings'
import React from 'react'
import './styles.scss'
import productImage from 'assets/images/product.png'
import {
  handleProductDescLanguage,
  handleProductLanguage
} from 'helpers/language'
import locales from 'locales'

const ProductCard = ({ product, handleEdit }) => {
  return (
    <div
      className={`product-card ${handleEdit ? 'show-hover' : ''}`}
      onClick={() => {
        handleEdit && handleEdit(product.id)
      }}
    >
      {/* <img src={productImage} alt="product" /> */}
      <img
        src={
          product.img_url ||
          `https://guma-bus-registry.s3.us-west-1.amazonaws.com/products/${product.id}.png`
        }
        alt="product"
      />
      <br />
      <br />
      <h3>
        <b>{handleProductLanguage(product)}</b>
      </h3>
      <div className="rating">{/* <Rating /> */}</div>
      <div>
        <b>{locales.productDesctiption}: </b>
        <span>{handleProductDescLanguage(product) || '-'}</span>
      </div>
      <div>
        <b>{locales.estAvailability}: </b>
        <span>
          {product.estimated_availability || product.availability || '-'}
        </span>
      </div>
    </div>
  )
}

export default ProductCard
