import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'

export function fetchMayorList(setMayorList) {
  axios
    .get(`${constants.HOST}/mayor/get`)
    .then(function({ data: response }) {
      if (response.success) {
        setMayorList(response.data)
      }
    })
    .catch(function(error) {})
}

export function updateMayor(handleLoader, values, record, setVisible, setShow) {
  console.log('setShow:', setShow)
  console.log('setVisible:', setVisible)
  console.log('values:', values)
  console.log('handleLoader:', handleLoader)
  console.log('record:', record)
  handleLoader(true)
  setShow && setShow(true)

  if (record.id) {
    axios
      .put(`${constants.HOST}/mayor/update`, {
        id: record.id,
        name: values.name,
        village: values.village,
        mayorcode: values.mayorcode,
        user_name: values.user_name
      })
      .then(function({ data: response }) {
        console.log('response:', response.success)
        handleLoader(false)
        setShow && setShow(false)
        setVisible && setVisible(false)
        if (response.success) message.success(response.message)
        else message.error(response.message)
      })
      .catch(function(error) {
        setShow && setShow(false)

        setVisible && setVisible(false)
        handleLoader(false)
      })
  } else {
    axios
      .post(`${constants.HOST}/mayor/create`, {
        name: values.name,
        village: values.village,
        mayorcode: values.mayorcode,
        user_name: values.user_name,
        password: values.password
      })
      .then(function({ data: response }) {
        console.log('response:', response.success)
        handleLoader(false)
        setVisible && setVisible(false)
        setShow && setShow(false)

        if (response.success) message.success(response.message)
        else message.error(response.message)
      })
      .catch(function(error) {
        setVisible && setVisible(false)
        setShow && setShow(false)

        handleLoader(false)
      })
  }
}

export function deleteMayor(handleLoader, id) {
  handleLoader(true)
  axios
    .delete(`${constants.HOST}/mayor/delete/${id}`)
    .then(function({ data: response }) {
      console.log('response:', response.success)
      handleLoader(false)

      if (response.success) message.success(response.message)
      else message.error(response.message)
    })
    .catch(function(error) {
      handleLoader(false)
    })
}
