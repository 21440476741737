import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './styles.scss'
import {
  EllipsisOutlined,
  MenuOutlined,
  TranslationOutlined
} from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import logo from 'assets/images/GUMA IBM LOGO - ROUND.png'
import SelectVillage from './SelectVillage'
import {
  fetchBusinessList,
  fetchCategoryList,
  fetchSubcategoryList
} from 'pages/ExploreBusiness/api'
import {
  handleIsVisibleVillageModal,
  updateSubCategoryList
} from 'pages/common.slice'
import { useDispatch, useSelector } from 'react-redux'
import { _handleSignout } from 'redux/utils'
import { handleCategoryLanguage } from 'helpers/language'
import DrawerMenu from './DrawerMenu'
import locales from 'locales'

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const common = useSelector(state => state.common)
  function handleSubheader(category) {
    if (common.selectedVillage) {
      dispatch(updateSubCategoryList([]))
      fetchSubcategoryList(category)
      history.push(
        `/business?search=${category}&category=${category}&village=${common.selectedVillage}`
      )
      fetchBusinessList()
    } else {
      dispatch(handleIsVisibleVillageModal(true))
    }
  }

  const name = window.localStorage.getItem('name')
  const imageUrl = window.localStorage.getItem('image_url')

  function handleMenuClick(e) {
    window.localStorage.setItem('lang', e.key)
    window.location.reload()
  }

  const menuTranslate = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="english">English</Menu.Item>
      <Menu.Item key="chamoru">CHamoru</Menu.Item>
      {/* chinease */}
      <Menu.Item key="中国人">中国人</Menu.Item>
      <Menu.Item key="chuukese">Chuukese</Menu.Item>
      {/* japanese */}
      <Menu.Item key="日本">日本</Menu.Item>
      {/* korean */}
      <Menu.Item key="한국인">한국인</Menu.Item>
    </Menu>
  )

  useEffect(() => {
    fetchCategoryList()
  }, [])
  return (
    <header className="header-wrapper">
      <div className="top-header">
        <div className="info logo-top">
          <Link to="/">
            <span>
              <img src={logo} alt="" />
            </span>
            <span>Village Resource Directory</span>
          </Link>
        </div>
        <div className="info mobile-only">
          <span onClick={() => setVisibleDrawer(true)}>
            <MenuOutlined />
          </span>
          <DrawerMenu
            onClose={() => setVisibleDrawer(false)}
            visible={visibleDrawer}
          />
        </div>

        <div className="info btns-top">
          <span className="tab" style={{ textTransform: 'capitalize' }}>
            <Dropdown overlay={menuTranslate}>
              <div>
                <TranslationOutlined />{' '}
                {window.localStorage.getItem('lang') || 'english'}
              </div>
            </Dropdown>
          </span>

          <span className="tab">
            {common.isVisibleVillageModal && <SelectVillage />}
            <span
              className="select-village-btn"
              onClick={() => dispatch(handleIsVisibleVillageModal(true))}
            >
              {common.selectedVillage || locales.selectVillage}
            </span>
          </span>

          {common.signedIn ? (
            <>
              <span className="tab">
                <Link to="/dashboard">{name}</Link>
              </span>
              <span className="tab sign-out" onClick={_handleSignout}>
                {locales.singout}
              </span>
            </>
          ) : (
            <>
              <span className="tab icon-round">
                <Dropdown overlay={menu}>
                  <div className="joinus">
                    <span>{locales.joinUs}</span>
                    <EllipsisOutlined style={{ marginLeft: '-4px' }} />
                  </div>
                </Dropdown>
              </span>
            </>
          )}
        </div>
      </div>
      <div className="bottom-header">
        {!!common.categories.length && (
          <nav>
            <ul>
              {common.categories.slice(0, 3).map(cat => (
                <>
                  <li>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleSubheader(cat.category)}
                    >
                      {handleCategoryLanguage(cat)}
                    </span>
                  </li>
                  <span>|</span>
                </>
              ))}
              <li>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/')}
                >
                  {locales.more}
                </span>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  )
}

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/signup">{locales.joinNow}</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/signin">{locales.singin}</Link>
    </Menu.Item>
  </Menu>
)

export default Header
