import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'

export function fetchProdcuts(setProductList) {
  axios
    .get(`${constants.HOST}/manager/products/list`)
    .then(function({ data: response }) {
      if (response.success) {
        setProductList(response.data)
      }
    })
    .catch(function(error) {})
}

export function updateProduct(setShowLoader, values, record) {
  setShowLoader(true)
  axios
    .post(`${constants.HOST}/manager/product/update`, {
      product_id: record.id,
      product_name: values.product_name,
      product_name_japanese: values.product_name_japanese,
      product_name_korean: values.product_name_korean,
      product_name_chinese: values.product_name_chinese,
      product_name_chuukese: values.product_name_chuukese,
      product_name_chamoru: values.product_name_chamoru,
      product_description: values.product_description,
      product_description_japanese: values.product_description_japanese,
      product_description_korean: values.product_description_korean,
      product_description_chinese: values.product_description_chinese,
      product_description_chamoru: values.product_description_chamoru,
      product_description_chuukese: values.product_description_chuukese
    })
    .then(function({ data: response }) {
      setShowLoader(false)
      if (response.success) message.success(response.data)
      else message.error(response.message)
    })
    .catch(function(error) {
      setShowLoader(false)
    })
}
