import { createSlice } from '@reduxjs/toolkit'

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    subcategory: []
  },
  reducers: {
    _handleForm: (state, action) => {
      const { attr, value } = action.payload
      state[attr] = value
    },
    _updateSubcategoryList: (state, action) => {
      state.subcategory = action.payload
    },
    _updateCategoryList: (state, action) => {
      state.category = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { _handleForm, _updateCategoryList, _updateSubcategoryList } =
  signupSlice.actions

export default signupSlice.reducer
