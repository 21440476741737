import React, { useEffect, useState } from 'react'
// import Loader from '_component/Loader'
import { _verifyToken } from './api'
import './styles.scss'
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined
} from '@ant-design/icons'

const EmailVerification = () => {
  const [isVerified, setIsVerified] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    _verifyToken(setIsLoading, setIsVerified)
  }, [])

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          fontSize: 50,
          minHeight: '30vh',
          marginTop:240
        }}
      >
        <div>
          <LoadingOutlined />
        </div>
      </div>
    )
  return (
    <div className="email-verification-wrapper">
      {isVerified ? (
        <>
          <div className="success">
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: 70 }}
            />
            <br />
            <br />
            <div>Your email id successfully verified</div>
          </div>
        </>
      ) : (
        <div className="error">
          <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: 70 }} />
          <br />
          <br />
          <div>Link has expired or invalid</div>
        </div>
      )}
    </div>
  )
}

export default EmailVerification
