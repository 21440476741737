import { Button, Form, Input } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useState } from 'react'
import { _forgotPassword } from '../api'

const PasswordReset = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')

  const onFinish = (values) => {
    console.log('Success:', values)
    _forgotPassword(email, setIsLoading, props.history)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <div>
      <SpinLoader spinning={isLoading}>
        <h1>Did You Forgot Your Password?</h1>
        <p>
          Enter your email you are using for your account and we will send you a
          password reset link.
        </p>
        <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input
              className="input-style"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Request Reset Link
            </Button>
          </Form.Item>
        </Form>
        <br />
        <br />

        <br />
        <br />
        <div style={{ textAlign: 'center' }}>
          Don't have an account?{' '}
          <u onClick={() => props.history.push('/signup')}>Sign Up Now</u>
        </div>
      </SpinLoader>
    </div>
  )
}

export default PasswordReset
