import { Input, Form, Button, Row, Col, Select } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useEffect, useState } from 'react'
import { textTransalate, updateBusiness } from './api'
import { useDispatch } from 'react-redux'

const EditBusiness = ({ record }) => {
  const [showLoader, setShowLoader] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    form.setFieldsValue({
      business_name: record.business_name,
      business_name_chamoru: record.business_name_chamoru,
      business_name_chinese: record.business_name_chinese,
      business_name_chuukese: record.business_name_chuukese,
      business_name_japanese: record.business_name_japanese,
      business_name_korean: record.business_name_korean
    })
  }, [])

  console.log('values', form)

  return (
    <SpinLoader spinning={showLoader}>
      <Row>
        <Col md={8}>
          <Form
            form={form}
            name="editCategory"
            labelAlign="left"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onFinish={values => updateBusiness(setShowLoader, values, record)}
          >
            <Form.Item name="business_name" label="Name English">
              <Input />
            </Form.Item>
            <Form.Item name="business_name_japanese" label="Name Japanese">
              <Input />
            </Form.Item>
            <Form.Item name="business_name_korean" label="Name Korean">
              <Input />
            </Form.Item>
            <Form.Item name="business_name_chinese" label="Name Chinese">
              <Input />
            </Form.Item>
            <Form.Item name="business_name_chamoru" label="Name CHamoru">
              <Input />
            </Form.Item>
            <Form.Item name="business_name_chuukese" label="Name Chuukese">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ margin: '5px 5px 0px 0px' }}
                onClick={async () => {
                  textTransalate(
                    setShowLoader,
                    form.setFieldsValue,
                    form.getFieldValue()
                  )
                }}
              >
                Transalate
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: '5px 5px 0px 0px' }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </SpinLoader>
  )
}

export default EditBusiness
