import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import Modal from 'antd/lib/modal/Modal'
import { Radio, Select, Space } from 'antd'

let marker

class DragMarker extends Component {
  // constructor(props){
  //   super(props);

  // }

  state = {
    center: {
      lat: parseFloat(this.props.center.lat),
      lng: parseFloat(this.props.center.lng)
    },
    render: false,
    showModal: false,
    addresses: []
  }

  componentDidMount() {
    console.log(this.props.center)
    if (this.props.center && this.props.center.lat != this.state.center.lat) {
      this.setState({ center: this.props.center, render: false })
      setTimeout(() => {
        this.setState({ render: true })
      }, 100)
    }
  }

  loadMap = (map, maps) => {
    const cityCircle = new maps.Circle({
      map,
      center: this.state.center,
      draggable: true
    })

    marker = new maps.Marker({
      position: this.state.center,
      map,
      draggable: true
    })

    marker.addListener('dragend', () => {
      let latlng = JSON.stringify(marker.getPosition())
      const center = {
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng()
      }
      this.setState({ center })
      this.fetchPlaces(center)
    })
  }

  fetchPlaces = async center => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat},${center.lng}&key=AIzaSyCvKlVyVvzlPtc8Orthx0tJFQNUfxFZsTo`
      )
      .then(async ({ data: response }) => {
        const addresses = response.results.filter(address =>
          address.types.includes('street_address')
        )
        this.setState({
          showModal: !!addresses.length,
          addresses,
          place_id: addresses.length ? addresses[0].place_id : ''
        })
        await axios.post(`${constants.HOST}/lookup/location/new`, response)
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  onChange = (place_id = this.state.place_id) => {
    console.log(place_id)

    const address = this.state.addresses.filter(
      add => place_id === add.place_id
    )[0]
    console.log(address)
    this.props.handleUpdatCenter &&
      this.props.handleUpdatCenter({
        address: address.formatted_address,
        latitude: address.geometry.location.lat,
        longitude: address.geometry.location.lng
      })
    this.setState({
      place_id
    })
    setTimeout(() => {
      this.setState({
        showModal: false
      })
    }, 200)
  }

  render() {
    let { render } = this.state
    return (
      <div style={{ height: '200px', width: '100%' }}>
        <Modal
          footer={null}
          title={'Select your address'}
          visible={this.state.showModal}
          onCancel={() => this.onChange()}
        >
          <Radio.Group
            onChange={e => this.onChange(e.target.value)}
            value={this.state.place_id}
          >
            <Space direction="vertical">
              {this.state.addresses.map(address => {
                return (
                  <Radio value={address.place_id}>
                    {address.formatted_address}
                  </Radio>
                )
              })}
            </Space>
          </Radio.Group>
        </Modal>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${'AIzaSyCvKlVyVvzlPtc8Orthx0tJFQNUfxFZsTo'}`
          }}
          defaultCenter={this.state.center}
          defaultZoom={12}
          center={this.state.center}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.loadMap(map, maps)}
          // onRegionChangeComplete={region => {
          //   setZoom(Math.round(Math.log(360 / region.latitudeDelta) / Math.LN2))
          // }}
        />

        {/* { this.state.center?(this.state.center.center.lat - this.state.center.center.lng):null} */}
      </div>
    )
  }
}

export default DragMarker
