import Header from './Header'
import React, { useEffect } from 'react'
import './styles.scss'
import { Route, Switch } from 'react-router-dom'
import Home from './Home'
import VillagesManagement from './Villages'
import CategoryManagement from './Category'
import SubcategoryTable from './Subcategory'
import SignIn from 'pages/Mayor/SignIn'
import Business from './Business'
import Products from './Products'
import MayorTable from './Mayor'

const Manager = () => {
  useEffect(() => {
    document.querySelector('header').style.display = 'none'
    document.querySelector('footer').style.display = 'none'
    return () => {
      document.querySelector('header').style.display = 'block'
      document.querySelector('footer').style.display = 'block'
    }
  }, [])
  if (
    !window.localStorage.getItem('admin_id') ||
    window.localStorage.getItem('admin_role') !== 'translator'
  )
    return <SignIn title="Translator Portal" />

  return (
    <div className="super-admin-wrapper">
      <Route component={Header} />
      <Switch>
        <Route path="/manager/subcategory" component={SubcategoryTable} />
        <Route path="/manager/category" component={CategoryManagement} />
        <Route path="/manager/villages" component={VillagesManagement} />
        <Route path="/manager/business" component={Business} />
        <Route path="/manager/products" component={Products} />
        <Route exact path="/manager" component={VillagesManagement} />
        <Route exact path="/manager/mayor" component={MayorTable} />
      </Switch>
    </div>
  )
}

export default Manager
