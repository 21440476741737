import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import AdminHeader from './Header'
import banner1 from 'assets/images/banner.png'
import './styles.scss'
import PageNotFound from 'components/PageNotFound'
import RegistrationDashboard from './RegistrationDashboard'
import Messages from './Messages'
import Profile from './Profile'
import ReviewBusiness from './Review'
import SignIn from './SignIn'

const Admin = () => {
  useEffect(() => {
    document.querySelector('header').style.display = 'none'
    document.querySelector('footer').style.display = 'none'
    return () => {
      document.querySelector('header').style.display = 'block'
      document.querySelector('footer').style.display = 'block'
    }
  }, [])
  if (
    !window.localStorage.getItem('admin_id') ||
    window.localStorage.getItem('admin_role') !== 'mayor'
  )
    return <SignIn />
  return (
    <div className="admin-wrapper">
      <Route component={AdminHeader} />
      <div
        style={{ backgroundImage: `url(${banner1})` }}
        className="search-banner"
      ></div>
      <Switch>
        <Route path="/mayor/profile" component={Profile} />
        <Route path="/mayor/messages" component={Messages} />
        <Route path="/mayor/edit-:business_id" component={ReviewBusiness} />
        <Route path="/mayor" component={RegistrationDashboard} exact />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  )
}

export default Admin
