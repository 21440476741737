import { LeftOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import UploadFile from 'components/UploadFile'
import constants from 'config/constants'
import routes from 'config/routes'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _handleForm } from 'pages/SignUp/signup.slice'
import FilePicker from 'components/FilePicker'
// import 'pages/signup/styles.scss'

const NewBusinessName = props => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const signup = useSelector(state => state.signup)

  const onFinish = values => {
    if (props.onFinish) {
      props.onFinish(values)
      return
    }
    props.history.push('/signup/business/service')
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    dispatch(_handleForm({ attr, value }))
  }

  function handleFileUpload(imageUrl) {
    handleChange('imageUrl', imageUrl)
    form.setFieldsValue({
      imageUrl
    })
  }

  useEffect(() => {
    handleChange('name', window.localStorage.getItem('name'))
    form.setFieldsValue({
      businessName: signup.businessName
    })
    return () => {}
  }, [])
  return (
    <div className="email-signup-form">
      <div
        style={{ cursor: 'pointer', width: '30px', marginBottom: '12px' }}
        onClick={() => props.history.goBack()}
      >
        <LeftOutlined />
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="signup-card-flex">
          <div className="top-container">
            <div style={{ textAlign: 'center' }}>
              <Form.Item
                name="imageUrl"
                rules={[
                  {
                    required: !signup.imageUrl,
                    message: 'Please upload your business logo!'
                  }
                ]}
              >
                <FilePicker
                  imageUrl={signup.imageUrl}
                  label="Upload Logo"
                  value={signup.imageUrl}
                  data={{
                    email: window.localStorage.getItem('email')
                  }}
                  handleFileUpload={handleFileUpload}
                />
              </Form.Item>
            </div>
            <h1>Confirm your Business Name?</h1>
            <Form.Item
              name="businessName"
              rules={[
                { required: true, message: 'Please enter your business name!' }
              ]}
            >
              <Input
                placeholder="Enter Business Name"
                onChange={e => handleChange('businessName', e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="bottom-container">
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Get Started
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default NewBusinessName
