import React, { useEffect, useState } from 'react'
import './styles.scss'
import logo from 'assets/images/GUMA IBM LOGO - ROUND.png'
import 'components/Header/styles.scss'

const SignUpHeader = (props) => {
  const [isBusiness, setIsBusiness] = useState(false)

  useEffect(() => {
    // if (props.location.pathname.includes('business')) setIsBusiness(true)
    // else setIsBusiness(false)

    return () => {}
  }, [])
  return (
    <div className="sign-up-header header-wrapper">
      <div className="top-header">
        <div
          className="info"
          onClick={() => {
            props.history.push('/')
          }}
        >
          <span>
            <img src={logo} alt="logo" />
          </span>
          <span>Village Resource Directory</span>
        </div>
        <div className="info">
          <span
          // onClick={() => {
          //   props.history.push('/')
          // }}
          >
            {/* Register Your Business */}
          </span>
        </div>
      </div>
    </div>
  )
}

export default SignUpHeader
