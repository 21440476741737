import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'

export function fetchVillages(setVillagesList) {
  axios
    .get(`${constants.HOST}/manager/village/list`)
    .then(function({ data: response }) {
      if (response.success) setVillagesList(response.data)
    })
    .catch(function(error) {})
}

export function updateVillage(setShowLoader, values) {
  setShowLoader(true)
  axios
    .post(`${constants.HOST}/manager/village/list`, {

    })
    .then(function({ data: response }) {
      setShowLoader(false)

      // if (response.success) setVillagesList(response.data)
    })
    .catch(function(error) {
      setShowLoader(false)
    })
}
