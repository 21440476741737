import React from 'react'
import { useHistory } from 'react-router-dom'

const SignUpLabel = () => {
  const history = useHistory()
  return (
    <div>
      Don't have an account?{' '}
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => history.push('/signup/business')}
      >
        <u>Sign Up Now</u>
      </span>
    </div>
  )
}

export default SignUpLabel
