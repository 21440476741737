import { CheckOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Space, Tag } from 'antd'
import Rating from 'components/Ratings'
import SpinLoader from 'components/SpinLoader'
import UploadFile from 'components/UploadFile'
import constants from 'config/constants'
import routes from 'config/routes'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  _addProduct,
  _deleteProduct,
  _getCategoryTags,
  _getProduct,
  _updateProduct
} from '../api'
import BusinessHeader from '../BusinessHeader'
import '../AddProduct/styles.scss'
import { useParams } from 'react-router-dom'
import EditableTagGroup from 'components/AddTags'

const EditProduct = (props) => {
  const [formData, setFormData] = useState({ tags: [] })
  const dashboard = useSelector((state) => state.dashboard)
  const [isSpinning, setIsSpinning] = useState(false)
  const [form] = Form.useForm()
  const params = useParams()
  const onFinish = (values) => {
    _updateProduct(
      setIsSpinning,
      { ...formData, category: values.category },
      params.product_id,
      params.business_id
    )
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    console.log(attr, value)
    setFormData({ ...formData, [attr]: value })
  }

  function handleFileUpload(imageUrl) {
    handleChange('imageUrl', imageUrl)
    form.setFieldsValue({
      photo: imageUrl
    })
  }

  function handleSelectSubCategory(tags) {
    handleChange('tags', tags)
    form.setFieldsValue({
      tags
    })
  }

  function handleCategoryChange(value) {
    let list = []
    handleChange('category', value)
    _getCategoryTags(value.join(','), (data) => {
      data.map((tagObj) => {
        list = [...list, ...tagObj.tags.split(',')]
        return true
      })

      handleSelectSubCategory(list)
    })
  }

  useEffect(() => {
    _getProduct(setIsSpinning, params.product_id, (data) => {
      form.setFieldsValue({
        category: data.subcategory_tags.split(','),
        imageUrl: data.img_url,
        name: data.product_name,
        description: data.product_description,
        availability: data.estimated_availability,
        priceMinimum: data.min_price,
        priceMaximum: data.max_price,
        status: data.status,
        tags: data.tags.split(',')
      })
      setFormData({
        category: data.subcategory_tags.split(','),
        imageUrl: data.img_url,
        name: data.product_name,
        description: data.product_description,
        availability: data.estimated_availability,
        priceMinimum: data.min_price,
        priceMaximum: data.max_price,
        status: data.status,
        tags: data.tags.split(',').map((item) => item.trim())
      })
    })
    return () => {}
  }, [])
  return (
    <div className="add-new-product">
      <SpinLoader spinning={isSpinning}>
        <div className="page-title">
          <h1>Edit Product/ Service</h1>
        </div>
        <div className="form-wrapper">
          <BusinessHeader />

          <div style={{ margin: '16px 0' }} />
          <Form
            form={form}
            requiredMark={false}
            labelAlign="left"
            name="productDetail"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 6 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="imageUrl"
              rules={[
                {
                  required: !formData.imageUrl,
                  message: 'Please upload product photo!'
                }
              ]}
            >
              <UploadFile
                imageUrl={formData.imageUrl}
                label="Upload product photo"
                action={`${constants.HOST}${routes.uploadBusinessProduct}`}
                data={{
                  product_id: window.localStorage.getItem('business_id')
                }}
                handleFileUpload={handleFileUpload}
              />
            </Form.Item>
            {/* <Form.Item
              label="Category"
              name="category"
              rules={[
                {
                  required: true,
                  message: 'Please select at least one category'
                }
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select at least one category"
                onChange={handleCategoryChange}
                optionLabelProp="label"
              >
                {dashboard.businessInfo.tags &&
                  dashboard.businessInfo.tags.split(',').map((tag) => {
                    return (
                      <Select.Option value={tag} label={tag}>
                        {tag}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Product Name"
              name="name"
              rules={[
                { required: true, message: 'Please enter product name!' }
              ]}
            >
              <Input onChange={(e) => handleChange('name', e.target.value)} />
            </Form.Item>

            <Form.Item
              label="Product Description"
              name="description"
              rules={[
                { required: true, message: 'Please enter product description!' }
              ]}
            >
              <Input.TextArea
                rows={4}
                onChange={(e) => handleChange('description', e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Est. Availibity"
              name="availability"
              rules={[
                {
                  required: true,
                  message: 'Please enter estimated availability!'
                }
              ]}
            >
              <Input
                onChange={(e) => handleChange('availability', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Price Range (Minimum)"
              name="priceMinimum"
              rules={[
                {
                  required: true,
                  message: 'Please enter product minimum price!'
                }
              ]}
            >
              <Input
                type={'number'}
                onChange={(e) => handleChange('priceMinimum', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Price Range (Maximum)"
              name="priceMaximum"
              rules={[
                {
                  required: true,
                  message: 'Please enter product paximum price!'
                }
              ]}
            >
              <Input
                type={'number'}
                onChange={(e) => handleChange('priceMaximum', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: 'Please select product status!'
                }
              ]}
            >
              <Select onSelect={(value) => handleChange('status', value)}>
                <Select.Option value="active">Active</Select.Option>
                <Select.Option value="inactive">Inactive</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Tags"
              name="tags"
              rules={[
                {
                  required: !formData.tags.length,
                  message: 'Please pick at least 1 tag!'
                }
              ]}
            >
              <EditableTagGroup
                value={formData.tags}
                handleTagChange={handleSelectSubCategory}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 0 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
                <Button
                  type=""
                  onClick={() =>
                    _deleteProduct(
                      setIsSpinning,
                      params.product_id,
                      props.history,
                      params.business_id
                    )
                  }
                >
                  Delete
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </SpinLoader>
    </div>
  )
}

export default EditProduct
