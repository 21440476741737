import { LeftOutlined } from '@ant-design/icons'
import { Button, Input, Form } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useState } from 'react'
import { _resendVerificationEmail, _verifyEmail } from '../api'

const VerifyEMail = props => {
  const [isSpinning, setIsSpinning] = useState(false)
  const [otp, setOtp] = useState('')
  const onFinish = () => {
    props.history.push('/signup/business/name')
    // _verifyEmail(props.history, setIsSpinning, otp)
  }

  return (
    <div className="verify-wrapper ">
      <div
        style={{ cursor: 'pointer', width: '30px', marginBottom: '12px' }}
        onClick={() => props.history.goBack()}
      >
        <LeftOutlined />
      </div>
      <SpinLoader spinning={isSpinning}>
        <Form name="verifyForm" onFinish={onFinish}>
          <div className="signup-card-flex">
            <div className="top-container">
              <h1>Verify Your Email</h1>
              <p>
                A link was sent to your email address. Please check your email
                and click the link to verify your email.
              </p>

              <br />
              {/* <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter otp sent to given email!'
                  }
                ]}
              >
                <Input
                  placeholder="OTP"
                  className="input-style"
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Item> */}
              <div
                style={{ cursor: 'pointer', width: 100 }}
                onClick={() => _resendVerificationEmail(setIsSpinning)}
              >
                <u>Resend Link</u>
              </div>
            </div>
            <div className="bottom-container">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  Continue
                </Button>
              </Form.Item>

            </div>
          </div>
        </Form>
      </SpinLoader>
    </div>
  )
}

export default VerifyEMail
