import React, { useEffect, useState } from 'react'
import ChatBot from 'react-simple-chatbot'
import logo from 'assets/images/GUMA IBM LOGO - ROUND.png'
import './styles.scss'
import { fetchChatBotFAQ } from './api'

const Chatbot = () => {
  const steps = [
    {
      id: '0',
      message: 'Welcome to Village resource library!',
      trigger: '1'
    },
    {
      id: '1',
      end: false,
      options: [
        { label: 'About VRD', value: 'about', trigger: '2' },
        {
          label: 'Business Membership',
          value: 'Business Membership',
          trigger: '15'
        },
        { label: 'Ratings', value: 'rating', trigger: '24' },
        {
          label: 'Mayors Screening',
          value: 'mayors_creening',
          trigger: '30'
        },
        {
          label: 'VRD Tech Support',
          value: 'vrd_tech_support',
          trigger: '35'
        },
        {
          label: 'Account & Security',
          value: 'account_security',
          trigger: '40'
        },
        {
          label: 'Purchases',
          value: 'purchases',
          trigger: '5'
        }
      ]
    },

    // -----------------About VRD
    {
      id: '2',
      end: false,
      options: [
        {
          label: 'What is the Village Resource Directory?',
          value: 'About',
          trigger: '3'
        },
        {
          label: 'How does the Village Resource Directory search button work?',
          value: 'About',
          trigger: '4'
        },
        {
          label: 'How do I use tags to find businesses?',
          value: 'About',
          trigger: '5'
        },
        {
          label:
            'Is the Village Resource Directory associated with the Mayors Council of Guam?',
          value: 'About',
          trigger: '6'
        },
        {
          label: 'How are businesses added to the Village Resource Directory?',
          value: 'About',
          trigger: '7'
        },
        {
          label:
            'Can I find locally made items in the Village Resource Directory?',
          value: 'About',
          trigger: '8'
        },
        { label: 'What services are available?', value: 'About', trigger: '9' },
        {
          label: 'What villages have business?',
          value: 'About',
          trigger: '10'
        },
        { label: 'Can I do refunds?', value: 'About', trigger: '11' },
        {
          label: 'Do businesses accept Paypal?',
          value: 'About',
          trigger: '12'
        },
        {
          label: 'Can I contract a business for their service?',
          value: 'About',
          trigger: '13'
        },
        {
          label: 'Can I speak to a real person?',
          value: 'About',
          trigger: '14'
        }
      ]
    },
    {
      id: '3',
      message:
        'The Village Resource Directory is a listing of licensed Home-Based Businesses that are in every village. The directory will display Home-Based products and services that are available in your village or the surrounding villages.',
      trigger: '1'
    },
    {
      id: '4',
      message:
        'The Village Resource Directory searches the directory and provides options on what best meets the needs of your search. ',
      trigger: '1'
    },
    {
      id: '5',
      message:
        'You simply search keywords of an item or service you are looking for. For example, if you are looking to purchase Empanada type that into the search bar and all associated businesses who sell empanada will pop up. ',
      trigger: '1'
    },
    {
      id: '6',
      message: 'Yes, we are in partnership with the Mayors Council of Guam',
      trigger: '1'
    },
    {
      id: '7',
      message: `Businesses that would like to be added to the Village Resource Directory will need to:
      1. Download the Village Resource Directory (insert hyperlink) or go to the I Bisinun Mami Website (insert hyperlink) 
      2. Sign up and create a profile
      3. Complete the registration information and submit for review by MCOG.`,
      trigger: '1'
    },
    {
      id: '8',
      message:
        'Yes, you will be able to find locally made products in your village and the surrounding villages',
      trigger: '1'
    },
    {
      id: '9',
      message:
        'The Village Resource Directory has a wide range of Home-Based services available. Please use the search bar to assist you with your search for a specific product or service.',
      trigger: '1'
    },
    {
      id: '10',
      message:
        'All 19 Villages will have a Home-Based Business representing their village. If the product or service you are looking for is not available in your village, options in other villages will be available.',
      trigger: '1'
    },
    {
      id: '11',
      message:
        'The Village Resource Directory does not process any payment transactions. You would need to contact the Business directly for more information. ',
      trigger: '1'
    },
    {
      id: '12',
      message:
        'The Village Resource Directory does not process any payment transactions. You would need to contact the Business directly for more information.',
      trigger: '1'
    },
    {
      id: '13',
      message:
        'You would need to contact the Business directly for more information. Business contact informaiton  will be available in their profile.',
      trigger: '1'
    },
    {
      id: '14',
      message:
        'We apologize for the inconvenience, but that option is not available.',
      trigger: '1'
    },

    // ---------------------------Business Membership

    {
      id: '15',
      end: false,
      options: [
        {
          label: 'Do I have to pay to register my business?',
          value: 'About',
          trigger: '16'
        },
        {
          label: 'Am I able to register more than one business?',
          value: 'About',
          trigger: '17'
        },
        {
          label: 'What information is required to register?',
          value: 'About',
          trigger: '18'
        },
        {
          label:
            'Do I need a Business License to become a member of the Village Resource Directory?',
          value: 'About',
          trigger: '19'
        },
        {
          label:
            'How can I make it easier for customers to find my business profile on the Village Resource Directory',
          value: 'About',
          trigger: '20'
        },
        {
          label: 'Can I link my website to the Village Resource Directory?',
          value: 'About',
          trigger: '21'
        },
        {
          label: 'How do I remove old product listings?',
          value: 'About',
          trigger: '22'
        },
        {
          label: 'What tags do I use to get my business found',
          value: 'About',
          trigger: '23'
        }
      ]
    },

    {
      id: '16',
      message:
        'No, this is a free service for Home-Based Businesses to assist with increasing awareness of their service and/ or products. If you’re interested in showcasing your business on the Village Resource Directory, click here (add hyperlink) to get started.',
      trigger: '1'
    },
    {
      id: '17',
      message:
        'Yes, you can register more than one business and you will be able to register for more than one category and sub-category',
      trigger: '1'
    },
    {
      id: '18',
      message: `You will need the following information to register your business:
      1. Identification (ie; driver’s license, passport, etc...)
      2. Business License
      3. Verification of residency from Mayor’s Office`,
      trigger: '1'
    },
    {
      id: '19',
      message:
        'Yes, all businesses on the Village Resource Directory are required to have a Guam business license',
      trigger: '1'
    },
    {
      id: '20',
      message:
        'The keywords and tags that are provided in the Business profile will ensure that customers are able to find your business',
      trigger: '1'
    },
    {
      id: '21',
      message:
        'Yes, website information can be uploaded directly to your profile.',
      trigger: '1'
    },
    {
      id: '22',
      message:
        'You can add/remove products or services when you select “edit profile.”',
      trigger: '1'
    },
    {
      id: '23',
      message:
        'Hashtags enable people to categorize and tag their posts with relevant keywords and phrases. It is suggested that you use tags that have your product/service, product/service description, business name, and village that your business is in.',
      trigger: '1'
    },
    // -------------------------------------------------Ratings
    {
      id: '24',
      end: false,
      options: [
        {
          label: 'What are the business ratings?',
          value: 'About',
          trigger: '25'
        },
        {
          label:
            'How do I know I can trust the ratings on the Village Resource Directory?',
          value: 'About',
          trigger: '26'
        },
        {
          label:
            'Does the Village Resource Directory accept anonymous reviews?',
          value: 'About',
          trigger: '27'
        },
        {
          label:
            'Does the Village Resource Directory have Community Guidelines?',
          value: 'About',
          trigger: '28'
        },
        {
          label:
            'Can I write a review without signing into the Village Resource Directory?',
          value: 'About',
          trigger: '29'
        }
      ]
    },

    {
      id: '25',
      message:
        ' I Bisinun Mami provides a reputation mechanism that allows you(registered user) to rate your experience after each transaction.',
      trigger: '1'
    },
    {
      id: '26',
      message:
        'This rating system is developed by you and for you, by telling buyers whether previous consumers were satisfied with the seller. All ratings are coming from customers registered from the directory that have proof of purchase.',
      trigger: '1'
    },
    {
      id: '27',
      message:
        'No, all reviews must come from a registered user with proof of purchase.',
      trigger: '1'
    },
    {
      id: '28',
      message:
        'Yes, the Village Resource Directory has Community Guidelines to learn more click here',
      trigger: '1'
    },
    {
      id: '29',
      message:
        'Reviews can only be made by a registered user who has already made a transaction.',
      trigger: '1'
    },

    // -------------------------- Mayors Screening

    {
      id: '30',
      end: false,
      options: [
        {
          label: 'Do the registered businesses get vetted in any way?',
          value: 'About',
          trigger: '31'
        },
        {
          label: "How long does it take to get verified by my mayor's office?",
          value: 'About',
          trigger: '32'
        },
        {
          label: 'Who can I contact to see the status of my verification? ',
          value: 'About',
          trigger: '33'
        },
        {
          label: 'What happens if my application is rejected? Can I reapply?',
          value: 'About',
          trigger: '34'
        }
      ]
    },

    {
      id: '31',
      message:
        "Yes, all business need preapproval from their village Mayor's Office before being added to the Village Resource Directory",
      trigger: '1'
    },
    {
      id: '32',
      message:
        'The verification process should take approximately 3 working days.',
      trigger: '1'
    },
    {
      id: '33',
      message:
        'Please visit your dashboard to view the status of your verification.',
      trigger: '1'
    },
    {
      id: '34',
      message: `If your application is rejected you have a chance to reapply and update your status and/ or add required documentation. 
        A. Lack of required documents
        B. Business does not follow Community Guidelines`,
      trigger: '1'
    },

    // -----------------------------------------VRD Tech Support
    {
      id: '35',
      end: false,
      options: [
        {
          label: "I can't sign into my account.",
          value: 'About Us',
          trigger: '36'
        },
        {
          label:
            'Who can I contact if something goes wrong with the Village Resource Directory website?',
          value: 'Product',
          trigger: '37'
        },
        {
          label:
            'Can I contact the Village Resource Directory support team directly?',
          value: 'Product',
          trigger: '38'
        },
        {
          label: 'Can I contact a business directly?',
          value: 'Product',
          trigger: '39'
        }
      ]
    },

    {
      id: '36',
      message: `If you are having trouble logging in ask vince/chantay`,
      trigger: '1'
    },
    {
      id: '37',
      message: `You can email the Village Resource Directory at (insert Tech email)`,
      trigger: '1'
    },
    {
      id: '38',
      message: `Our services are available by email or contacting your village Mayor’s Office`,
      trigger: '1'
    },
    {
      id: '39',
      message: `Yes, each business added to the Village Resource Directory has a contact listing in their business profile. Simply select a business by name or use the search function to access their profile and on their profile you will get full access to information provided. `,
      trigger: '1'
    },

    // -----------------------------------Account & Security
    {
      id: '40',
      end: false,
      options: [
        {
          label: 'How can I change my password or username?',
          value: 'Payment',
          trigger: '5'
        },
        {
          label:
            'How do I know my information is secure on the Village Resource Directory? ',
          value: 'Payment',
          trigger: '5'
        },
        {
          label: 'How do I delete my User Profile?',
          value: 'Payment',
          trigger: '5'
        },
        {
          label: 'How do I Delete my Business Profile?',
          value: 'Payment',
          trigger: '5'
        },
        {
          label:
            'Does the Village Resource Directory insure items bought from the Directory?',
          value: 'Payment',
          trigger: '5'
        }
      ]
    },

    {
      id: '41',
      message: `To change your password, simply log in to your account and go to Manage My Account in the menu. You’ll see the option to change your password under Personal Details.
      
      Don’t know your password? Choose the Send Sign-In Link option at log-in. We’ll email you a link that you can use to sign in without entering a password.`,
      trigger: '1'
    },
    {
      id: '42',
      message:
        'The Village Resource Directory takes security very seriously and have taken precautions to insure that your information is held in the highest confidentiality',
      trigger: '1'
    },
    {
      id: '43',
      message:
        'Log into the Village Resource Directory settings and find “Delete my profile” and wait for confirmation that your user profile has been deleted.',
      trigger: '1'
    },
    {
      id: '44',
      message:
        'The Village Resource Directory is not responsible for any of the transactions that take place beyond connecting users to business. ',
      trigger: '1'
    },

    // -----------------------------------Purchases
    {
      id: '45',
      end: false,
      options: [
        { label: 'What is the return policy?', value: 'Payment', trigger: '1' },
        {
          label: 'What are the shipping options?',
          value: 'Payment',
          trigger: '1'
        },
        {
          label: 'What are the onisland pick up options?',
          value: 'Payment',
          trigger: '1'
        },
        {
          label:
            'What are the international taxes, duties, etc. that I have to pay? ',
          value: 'Payment',
          trigger: '1'
        },
        {
          label: 'When will I receive my order?',
          value: 'Payment',
          trigger: '1'
        },
        {
          label: 'What would I do if I never received my order?',
          value: 'Payment',
          trigger: '1'
        },
        {
          label: 'What do I do if I receive a defective order?',
          value: 'Payment',
          trigger: '1'
        },
        {
          label: 'How do I make changes to an order I’ve already placed?',
          value: 'Payment',
          trigger: '1'
        },
        { label: 'Where are you located?', value: 'Payment', trigger: '1' },
        {
          label: 'How is the product made? Where do the materials come from?',
          value: 'Payment',
          trigger: '1'
        },
        {
          label: 'How do I make sure I order the right size?',
          value: 'Payment',
          trigger: '1'
        },
        {
          label:
            'How do I contact your company if my question isn’t answered here?',
          value: 'Payment',
          trigger: '1'
        }
      ]
    }
    // {
    //   id: '2',
    //   user: true
    //   // trigger: '3'
    // },
    // {
    //   id: '3',
    //   message: 'How can i help you?',
    //   trigger: '4'
    // },

    // {
    //   id: '4',
    //   end: false,
    //   options: [
    //     { label: 'About Us', value: 'About Us', trigger: '5' },
    //     { label: 'Product Delivery', value: 'Product Delivery', trigger: '5' },
    //     { label: 'Payment', value: 'Payment', trigger: '5' }
    //   ]
    // },
  ]
  const [chatBotFaq, setChatBotFaq] = useState('')
  useEffect(() => {
    fetchChatBotFAQ(setChatBotFaq)
  }, [])
  if (!chatBotFaq) return <div></div>
  return (
    <div className="chatbot-wrapper">
      <ChatBot
        steps={chatBotFaq}
        opened={false}
        floatingStyle={{
          backgroundColor: 'green'
        }}
        bubbleOptionStyle={{
          backgroundColor: 'green'
        }}
        bubbleStyle={{
          backgroundColor: 'green',
          color: 'white',
          fontSize: 11
        }}
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          fontSize: 10,
          padding: 4,
          borderRadius: 4
        }}
        inputAttributes={{ style: { color: 'black' } }}
        floating
        //hideBotAvatar
        botAvatar={logo}
      />
    </div>
  )
}

export default Chatbot
