import { Input, Form, Button, Row, Col } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useEffect, useState } from 'react'
import { updateVillage } from './api'

const EditVillage = ({ record }) => {
  const [showLoader, setShowLoader] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({ name: record.village })
  }, [])
  return (
    <SpinLoader spinning={showLoader}>
      <Row>
        <Col md={8}>
          <Form
            form={form}
            name="editVillages"
            labelAlign="left"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onFinish={values => updateVillage(setShowLoader, values, record)}
          >
            <Form.Item name="name" label="Village ">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </SpinLoader>
  )
}

export default EditVillage
