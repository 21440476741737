import React, { useEffect, useState } from 'react'
import { Table, Tag, Space, Input, Button } from 'antd'
import './styles.scss'
import { fetchBusinessList } from './api'
import { getParameterByName } from 'helpers/string'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import EditBusiness from './EditBusiness'

const Business = () => {
  const [businessList, setBusinessList] = useState('')
  const [count, setCount] = useState(0)
  const [searchBusiness, setSearchBusiness] = useState('')
  const params = {
    page: getParameterByName('page') || 1,
    items: getParameterByName('items') || 20,
    filter: {
      key: '',
      search: ''
    }
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    params.filter.key = dataIndex
    params.filter.search = selectedKeys[0]
    fetchBusinessList(setBusinessList, setCount, params)

    console.log(dataIndex, selectedKeys)
    // this.setState({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex,
    // });
  }

  const handleReset = clearFilters => {
    clearFilters()
    // this.setState({ searchText: '' })
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              // this.setState({
              //   searchText: selectedKeys[0],
              //   searchedColumn: dataIndex
              // })
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    )
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex]
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase())
    //     : '',
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select(), 100)
    //   }
    // }
    // render: text =>
    //   this.state.searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[this.state.searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   )
  })

  const catgory_id = getParameterByName('category')

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'business_name',
      dataIndex: 'business_name',
      key: 'business_name',
      // ...getColumnSearchProps('business_name')
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Link>ok</Link>
    //     </Space>
    //   )
    // }
  ]

  useEffect(() => {
    fetchBusinessList(setBusinessList, setCount, params)
    return () => {}
  }, [])
  if (!businessList)
    return (
      <div className="full-screen-loader ">
        <div>
          <LoadingOutlined />
        </div>
      </div>
    )
  return (
    <div className="business-managerment-wrapper">
      <h1>Businesses</h1>
      <Table
        // pagination={false}
        columns={columns}
        dataSource={
          businessList
            ? businessList.map((cat, key) => {
                return { ...cat, key }
              })
            : []
        }
        expandable={{
          expandedRowRender: record => <EditBusiness record={record} />
        }}
      />
    </div>
  )
}
export default Business
