import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import HeaderDashboard from './Header'
import Profile from './Profile'
import banner1 from 'assets/images/banner.png'
import './styles.scss'
import PageNotFound from 'components/PageNotFound'
import AddProduct from './AddProduct'
import UpdateInformation from './UpdateInformation'
import Status from './Status'
import { _getUserInfo } from './api'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import EditProduct from './EditProduct'
import UserBusinessList from './BusinessList'
import NewBusinessModal from './NewBusinessModal'

const Dashboard = props => {
  // const dashboard = useSelector(state => state.dashboard)
  useEffect(() => {
    document.querySelector('header').style.display = 'none'
    document.querySelector('footer').style.display = 'none'
    const email = window.localStorage.getItem('email')
    const userid = window.localStorage.getItem('userid')
    if (!email) {
      props.history.push('/signin')
      return
    }
    // if (!userid) {
    //   props.history.push('/signup/business/verify')
    //   return
    // }
    // _getUserInfo(userid)
    return () => {
      document.querySelector('header').style.display = 'block'
      document.querySelector('footer').style.display = 'block'
    }
  }, [])
  return (
    <div className="dashboard-wrapper">
      <HeaderDashboard />

      <div
        id={'dash-banner'}
        className="search-banner"
        style={{ backgroundImage: `url(${banner1})` }}
      ></div>

      {/* {dashboard.businessInfo ? ( */}
      <Switch>
        <Route path="/dashboard/new" component={NewBusinessModal} />
        <Route
          path="/dashboard/:business_id/new"
          component={AddProduct}
          exact
        />
        <Route
          path="/dashboard/:business_id/edit-:product_id"
          component={EditProduct}
          exact
        />
        <Route
          path="/dashboard/:business_id/update-info"
          component={UpdateInformation}
          exact
        />
        <Route path="/dashboard/:business_id/status" component={Status} exact />
        <Route path="/dashboard/:business_id" component={Profile} exact />
        <Route path="/dashboard" component={UserBusinessList} exact />
        <Route component={PageNotFound} />
      </Switch>
      {/* ) : (
        <div className="spin-loader-font">
          <LoadingOutlined style={{ fontSize: 40 }} />
        </div>
      )} */}
    </div>
  )
}

export default Dashboard
