import { Button, Input, Select, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { _handleForm } from '../signup.slice'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { _createUser } from '../api'
import SpinLoader from 'components/SpinLoader'
import { LeftOutlined } from '@ant-design/icons'
import SignInLabel from '../SignInLabel'

const { Option } = Select

const EmailForm = (props) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const signup = useSelector((state) => state.signup)
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (values) => {
    _createUser(props.history, setIsLoading)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    dispatch(_handleForm({ attr, value }))
  }

  useEffect(() => {
    form.setFieldsValue({
      email: signup.email,
      name: signup.name,
      password: signup.password,
      confirmPassword: signup.confirmPassword,
      question: signup.question,
      answer: signup.answer
    })
    return () => {}
  }, [])
  return (
    <div className="email-signup-form">
      <div
        style={{ cursor: 'pointer', width: '30px', marginBottom: '12px' }}
        onClick={() => props.history.goBack()}
      >
        <LeftOutlined />
      </div>
      <SpinLoader spinning={isLoading}>
        <h1>Sign Up With Your Email</h1>
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please enter your valid email!' }
            ]}
          >
            <Input
              placeholder="Email"
              className="input-style"
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please enter your name!' }]}
          >
            <Input
              placeholder="Name"
              className="input-style"
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter password!' }]}
          >
            <Input
              type="password"
              placeholder="Password"
              className="input-style"
              onChange={(e) => handleChange('password', e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Please enter same password as above!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }

                  return Promise.reject(
                    new Error('The passwords that you entered do not match!')
                  )
                }
              })
            ]}
          >
            <Input
              type="password"
              placeholder="Confirm Password"
              className="input-style"
              onChange={(e) => handleChange('confirmPassword', e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="question"
            rules={[
              { required: true, message: 'Please select a security question!' }
            ]}
          >
            <Select
              placeholder="Choose your security question"
              className="input-style"
              onSelect={(value) => handleChange('question', value)}
              // style={{ width: 120 }}
              // onChange={handleChange}
            >
              <Option value="Who was your favorite teacher?">
                Who was your favorite teacher?
              </Option>
              <Option value="What is your favorite movie?">
                What is your favorite movie?
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="answer"
            rules={[
              {
                required: true,
                message: 'Please enter answer for security question!'
              }
            ]}
          >
            <Input
              onChange={(e) => handleChange('answer', e.target.value)}
              placeholder="Answer above question"
              className="input-style"
            />
          </Form.Item>

          <div>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ width: '100%', background: '#7E7C61 !imoprtant' }}
              >
                Submit
              </Button>
              <div style={{ textAlign: 'center' }}>
                <SignInLabel />
              </div>
            </Form.Item>
          </div>
        </Form>
      </SpinLoader>
    </div>
  )
}

export default EmailForm
