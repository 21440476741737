import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import { getParameterByName } from 'helpers/string'
import { updateBusinessList } from 'pages/common.slice'
import store from 'redux/store'
import { updateMessages } from './admin.slice'

export function _getAdminInfo() {
  axios
    .get(`${constants.HOST}${routes.userInfo}`)
    .then(function(response) {
      console.log(response)
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function _getBusinessList(callback) {
  const village = window.localStorage.getItem('admin_village')
  axios
    .get(`${constants.HOST}${routes.adminFetchBusinessAll}?village=${village}`)
    .then(function({ data: response }) {
      if (response.success) {
        callback && callback(response.data)
        // store.dispatch(updateBusinessList())
        return
      } else {
        return []
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function _getBusinessInfo(business_id, callback) {
  axios
    .get(
      `${constants.HOST}${routes.adminFetchBusinessData}?business_id=${business_id}`
    )
    .then(function({ data: response }) {
      if (response.success) {
        callback && callback(response.data)
        // store.dispatch(updateBusinessList())
        return
      } else {
        return []
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function _updateAdminProfile(form, setIsSpinning) {
  setIsSpinning(true)
  axios
    .post(`${constants.HOST}${routes.updateBusinessStatus}`, {
      username: form.user_id,
      password: form.status
    })
    .then(function({ data: response }) {
      setIsSpinning(false)
      console.log(response)
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function _postAdminLogin(form, setIsSpinning) {
  setIsSpinning(true)
  axios
    .post(`${constants.HOST}${routes.adminLogin}`, {
      userName: form.userName,
      password: form.password
    })
    .then(function({ data: response }) {
      if (response.success) {
        window.localStorage.setItem('admin_id', response.data.id)
        window.localStorage.setItem('admin_name', response.data.name)
        window.localStorage.setItem('admin_village', response.data.village)
        window.localStorage.setItem('admin_role', response.data.role)
        if (response.data.role === 'mayor') window.location.href = '/mayor'
        if (response.data.role === 'translator')
          window.location.href = '/manager'
      } else {
        message.error(response.message)
      }
      setIsSpinning(false)
      console.log(response)
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function _updateBusinessStatus(setIsSpinning, business_id, status) {
  setIsSpinning(true)
  axios
    .post(`${constants.HOST}${routes.adminUpdateStatus}`, {
      business_id,
      status
    })
    .then(function({ data: response }) {
      setIsSpinning(false)
      if (response.success) {
        message.success(response.data)
      } else message.warn(response.message)

      console.log(response)
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function fetchMessagesIds(setIsSpinning, setData, adminId, businessId) {
  setIsSpinning(true)
  axios
    .get(`${constants.HOST}${'/admin/message/fetch-id'}?admin_id=${adminId}`)
    .then(function({ data: response }) {
      setIsSpinning(false)
      if (response.success) {
        setData(response.data)
        if (response.data.length)
          fetchMessages(
            setIsSpinning,
            businessId || response.data[0].id,
            adminId
          )
      } else message.warn(response.message)
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function fetchMessages(setIsSpinning, businessId, adminId) {
  setIsSpinning(true)
  axios
    .get(
      `${
        constants.HOST
      }${'/admin/message/fetch-messages'}?admin_id=${adminId}&receiver_id=bus_${businessId}`
    )
    .then(function({ data: response }) {
      setIsSpinning(false)
      if (response.success) {
        store.dispatch(
          updateMessages({ data: response.data, bus_id: businessId })
        )
        const doc = document.querySelector('#admin-message-chat')
        doc.scrollTo(0, doc.scrollHeight)
      } else message.warn(response.message)
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}
