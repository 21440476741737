import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import './styles.scss'
import background from 'assets/images/login-background.jpeg'
import SignUpFooter from 'pages/SignUp/SignUpFooter'
import SignUpHeader from 'pages/SignUp/SignUpHeader'
import { Button, Form, Input } from 'antd'
import { _postAdminLogin } from '../api'
import SpinLoader from 'components/SpinLoader'

const SignIn = props => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [isSpinning, setIsSpinning] = useState(false)

  const onFinish = values => {
    if (props.handleSignin) {
      props.handleSignin(values, setIsSpinning)
    } else {
      _postAdminLogin(values, setIsSpinning)
    }
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    setFormData({ ...formData, [attr]: value })
  }

  useEffect(() => {
    document.querySelector('header').style.display = 'none'
    document.querySelector('footer').style.display = 'none'
    return () => {
      document.querySelector('header').style.display = 'block'
      document.querySelector('footer').style.display = 'block'
    }
  }, [])

  useEffect(() => {
    return () => {}
  }, [])
  return (
    <div
      className="signup-wrapper"
      style={{ backgroundImage: `url(${background})` }}
    >
      <Route component={SignUpHeader} />
      <div className="signup-card">
        <div className="signup-card-container">
          <h1>{props.title || "Mayor's Office"}</h1>
          <h2>Sign In</h2>
          <SpinLoader spinning={isSpinning}>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="login"
              form={form}
            >
              <Form.Item
                name="userName"
                rules={[
                  { required: true, message: 'Please input your username!' }
                ]}
              >
                <Input
                  placeholder="Username"
                  onChange={e => handleChange('userName', e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' }
                ]}
              >
                <Input
                  type="password"
                  placeholder="Password"
                  onChange={e => handleChange('password', e.target.value)}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 0 }}>
                <Button type="primary" htmlType="submit">
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </SpinLoader>
        </div>
      </div>
      <Route component={SignUpFooter} />
    </div>
  )
}

export default SignIn
