import React, { useEffect, useState } from 'react'
import { FacebookFilled, GoogleOutlined, MailFilled } from '@ant-design/icons'
import { Button } from 'antd'
import Btn from 'components/Button'
import './styles.scss'
import { initGoogleClient, initGoogleapi } from 'helpers/google'
import SignUpLabel from '../SignUpLabel'
import { _postLoginWithAuth } from '../api'
import SpinLoader from 'components/SpinLoader'
import logo from 'assets/images/GUMA IBM LOGO - ROUND.png'

const SignIn = props => {
  const [isLoading, setIsLoading] = useState(true)
  function loginWithGoogle() {
    initGoogleClient(data => {
      _postLoginWithAuth(data, setIsLoading, props.history)
    })
  }
  useEffect(() => {
    setTimeout(() => {
      initGoogleapi(setIsLoading)
    }, 1000)
  }, [])
  return (
    <div>
      <div className="signin-home">
        <div className="top-container">
          <img src={logo} width={130} alt="logo" />
          <br />
          <br />
          <h1> Sign In</h1>
          <br />
          <SpinLoader spinning={isLoading}>
            <div onClick={loginWithGoogle}>
              <Btn icon={<GoogleOutlined />} label="Sign In With Google" />
            </div>
            {/* <Btn icon={<FacebookFilled />} label="Sign In With Facebook" /> */}
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => props.history.push('/signin/email')}
            >
              <Btn icon={<MailFilled />} label="Sign In With Email" />
            </div>
          </SpinLoader>
        </div>
        <div className="bottom-container">
          <SignUpLabel />
        </div>
      </div>
    </div>
  )
}

export default SignIn
