import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'

export function fetchBusinessList(setCategoryList, setCount, params) {
  axios
    .get(
      `${constants.HOST}/manager/businesses/list?page=${params.page}
      &items=${params.items}&filter_key=${params.filter.key}&filter_value=${params.filter.value}`
    )
    .then(function({ data: response }) {
      if (response.success) {
        setCategoryList(response.data)
      }
    })
    .catch(function(error) {})
}

export function updateBusiness(setShowLoader, values, record) {
  setShowLoader(true)
  axios
    .post(`${constants.HOST}/manager/business/update`, {
      business_id: record.id,
      business_name: values.business_name,
      business_name_japanese: values.business_name_japanese,
      business_name_chamoru: values.business_name_chamoru,
      business_name_chinese: values.business_name_chinese,
      business_name_chuukese: values.business_name_chuukese,
      business_name_korean: values.business_name_korean
    })
    .then(function({ data: response }) {
      setShowLoader(false)
      if (response.success) message.success(response.data)
      else message.error(response.message)
    })
    .catch(function(error) {
      setShowLoader(false)
    })
}

export function textTransalate(setShowLoader, setFieldsValue, text) {
  setShowLoader(true)
  console.log('text', text)
  axios
    .post(`${constants.HOST}/transalate`, {
      text: text.business_name
    })
    .then(function({ data: response }) {
      console.log('response', response)
      setShowLoader(false)
      setFieldsValue({
        business_name_chamoru:
          text.business_name_chamoru === ''
            ? response.translatelanguage.chamoru
            : text.business_name_chamoru,
        business_name_chinese:
          text.business_name_chinese === ''
            ? response.translatelanguage.chinese
            : text.business_name_chinese,
        business_name_chuukese:
          text.business_name_chuukese === ''
            ? response.translatelanguage.chuukese
            : text.business_name_chuukese,
        business_name_japanese:
          text.business_name_japanese === ''
            ? response.translatelanguage.japanese
            : text.business_name_japanese,
        business_name_korean:
          text.business_name_korean === ''
            ? response.translatelanguage.korean
            : text.business_name_korean
      })
      if (response.success) message.success(response.data)
      else message.error(response.message)
      return response
    })
    .catch(function(error) {
      setShowLoader(false)
    })
}
