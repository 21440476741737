export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function insertParam(key, value, callbackUrl) {
  key = encodeURIComponent(key)
  value = encodeURIComponent(value)
  // kvp looks like ['key1=value1', 'key2=value2', ...]
  var kvp = callbackUrl
    ? callbackUrl().split('&')
    : document.location.search.substr(1).split('&')
  let i = 0

  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(key + '=')) {
      let pair = kvp[i].split('=')
      pair[1] = value.trim()
      kvp[i] = pair.join('=')
      break
    }
  }

  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join('=')
  }

  // can return this or...
  let params = kvp.join('&')

  // reload page with new params
  return params
}

export function validateUrl(value) {
  return (
    /^(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    ) ||
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    )
  )
}

export const deviceCheck = function () {
  var ua = navigator.userAgent,
    browser = /Edge\/\d+/.test(ua)
      ? 'ed'
      : /MSIE 9/.test(ua)
      ? 'ie9'
      : /MSIE 10/.test(ua)
      ? 'ie10'
      : /MSIE 11/.test(ua)
      ? 'ie11'
      : /MSIE\s\d/.test(ua)
      ? 'ie?'
      : /rv\:11/.test(ua)
      ? 'ie11'
      : /Firefox\W\d/.test(ua)
      ? 'ff'
      : /Chrome\W\d/.test(ua)
      ? 'gc'
      : /Chromium\W\d/.test(ua)
      ? 'oc'
      : /\bSafari\W\d/.test(ua)
      ? 'sa'
      : /\bOpera\W\d/.test(ua)
      ? 'op'
      : /\bOPR\W\d/i.test(ua)
      ? 'op'
      : typeof MSPointerEvent !== 'undefined'
      ? 'ie?'
      : '',
    os = /Windows NT 10/.test(ua)
      ? 'win10'
      : /Windows NT 6\.0/.test(ua)
      ? 'winvista'
      : /Windows NT 6\.1/.test(ua)
      ? 'win7'
      : /Windows NT 6\.\d/.test(ua)
      ? 'win8'
      : /Windows NT 5\.1/.test(ua)
      ? 'winxp'
      : /Windows NT [1-5]\./.test(ua)
      ? 'winnt'
      : /Mac/.test(ua)
      ? 'mac'
      : /Linux/.test(ua)
      ? 'linux'
      : /X11/.test(ua)
      ? 'nix'
      : '',
    mobile = /IEMobile|Windows Phone|Lumia/i.test(ua)
      ? 'w'
      : /iPhone|iP[oa]d/.test(ua)
      ? 'i'
      : /Android/.test(ua)
      ? 'a'
      : /BlackBerry|PlayBook|BB10/.test(ua)
      ? 'b'
      : /Mobile Safari/.test(ua)
      ? 's'
      : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua)
      ? 1
      : 0,
    tablet = /Tablet|iPad/i.test(ua),
    touch = 'ontouchstart' in document.documentElement
  return {
    browser,
    os,
    mobile,
    tablet,
    touch
  }
}
