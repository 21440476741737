import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import store from 'redux/store'
import { _handleSignout } from 'redux/utils'

export function fetchUserAccountDetails(history, setIsLoading) {
  const userid = window.localStorage.getItem('userid')
  if (!userid) {
    setIsLoading(false)
    return
  }
  setIsLoading(true)
  axios
    .get(`${constants.HOST}/user/account-details?id=${userid}`)
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        if (!response.data.email_verified)
          history.push('/signup/business/verify')
        if (response.data.status === 'registration')
          history.push('/signup/business/name')
        if (response.data.status === 'dashboard') history.push('/dashboard')
      }
    })
    .catch(function(error) {
      setIsLoading(false)
      console.log(error)
    })
}

export function _createUser(history, setIsLoading) {
  const { signup } = store.getState()
  const { pathname } = window.location
  const isBusinessPage = pathname.includes('business')
  setIsLoading(true)
  axios
    .post(`${constants.HOST}${routes.createUser}`, {
      email: signup.email,
      name: signup.name,
      password: signup.password,
      question: signup.question,
      answer: signup.answer,
      role: 'business'
    })
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        window.localStorage.setItem('email', signup.email)
        window.localStorage.setItem('name', signup.name)
        window.localStorage.setItem('userid', response.id)
        history.push('/signup/business/verify')
        return
      }
      message.warn(response.message)
    })
    .catch(function(error) {
      setIsLoading(false)

      console.log(error)
    })
}

export function _verifyEmail(history, setIsLoading, otp) {
  setIsLoading(true)
  axios
    .post(`${constants.HOST}${routes.verifyEmail}`, {
      otp: 1223,
      email: window.localStorage.getItem('email')
    })
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        window.localStorage.setItem('userid', response.data.user_id)
        history.push('/signup/business/name')
        return
      }
      message.warn(response.message)
    })
    .catch(function(error) {
      setIsLoading(false)
      console.log(error)
    })
}

export function _resendVerificationEmail(setIsLoading) {
  setIsLoading(true)
  axios
    .post(`${constants.HOST}${routes.resendVerificationEmail}`, {
      email: window.localStorage.getItem('email')
    })
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) message.success(response.data)
      else message.warn(response.message)
    })
    .catch(function(error) {
      setIsLoading(false)
      console.log(error)
    })
}

// for buiness only
export function _updateUser(history, setIsLoading) {
  const { signup } = store.getState()
  setIsLoading(true)
  const formData = new FormData()
  formData.append('logo', signup.imageUrl)
  formData.append(
    'data',
    JSON.stringify({
      userId: window.localStorage.getItem('userid'),
      name: signup.name,
      blNumber: signup.blNumber,
      dba: signup.dba,
      phone: signup.phone,
      website: signup.website,
      facebook: signup.facebook,
      instagram: signup.instagram,
      twitter: signup.twitter,
      youtube: signup.youtube,
      whatsapp: signup.whatsapp,
      email: signup.email || window.localStorage.getItem('userid'),
      tags: signup.subcategory.join(','),
      address: signup.address,
      village: signup.village,
      businessName: signup.businessName,
      //  imageUrl: signup.imageUrl,
      description: signup.description,
      operation: signup.hrs_of_operation,
      category: getCategory(signup).join(','),
      opration: signup.hrs_of_operation
    })
  )

  axios
    .post(`${constants.HOST}${routes.createNewBusiness}`, formData, {
      'Content-Type': 'multipart/form-data'
    })
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        history.push('/signup/business/success')
        if (!window.GoogleAuth.isSignedIn.get()) {
          _handleSignout()
          return
        }
        window.localStorage.setItem('business_id', response.data.business_id)
      } else message.warn(response.message)
    })
    .catch(function(error) {
      setIsLoading(false)
      console.log(error)
    })
}

export const getCategory = signup => {
  const category = [],
    categoryList = {}

  store.getState().common.categories.forEach(catObj => {
    categoryList[catObj.id] = catObj.category
  })
  const subCategories = store
    .getState()
    .common.subCategories.filter(subCatergoryObj =>
      signup.subcategory.includes(subCatergoryObj.subcategory)
    )

  subCategories.forEach(cat => {
    if (!category.includes(categoryList[cat.category_id])) {
      category.push(categoryList[cat.category_id])
    }
  })
  console.log('category', category)
  return category
  // subCategories.map(subCatergoryObj => {
  //   if(category.includes(subCatergoryObj))
  // })
}
