import japanese from './japanese.json'
import english from './english.json'
import chamoru from './chamoru.json'
import chinese from './chinese.json'
import chuukuese from './chuukuese.json'
import korean from './korean.json'

const getLocale = lang => {
  switch (lang) {
    case '日本':
      return japanese
    // case 'Chamoru':
    //   return chamoru
    // case '中国人':
    //   return chinese
    // case 'chuukuese':
    //   return chuukuese
    // case '한국인':
    //   return korean
    default:
      return english
  }
}

export default getLocale(window.localStorage.getItem('lang'))
