import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import { getParameterByName } from 'helpers/string'
import {
  updateBusinessData,
  updateBusinessList,
  updateBusinessListLoader,
  updateCategoryList,
  updateLocationData,
  updateProductsData,
  updateSubCategoryList,
  updatProductsListLoader
} from 'pages/common.slice'
import store from 'redux/store'

export function fetchBusinessList() {
  const search = getParameterByName('search')
  const village = getParameterByName('village')
  const subcategories = getParameterByName('subcategory')
  const category = getParameterByName('category')
  store.dispatch(updateBusinessListLoader(true))

  axios
    .get(
      `${constants.HOST}${routes.fetchBusinesses}?search=${search}&category=${category}&location=${village}&subcategories=${subcategories}`
    )
    .then(function ({ data: response }) {
      store.dispatch(updateBusinessListLoader(false))
      if (response.success) {
        store.dispatch(updateBusinessList(response.data))
      } else {
      }
      console.log(response)
    })
    .catch(function (error) {
      store.dispatch(updateBusinessListLoader(false))
      console.log(error)
    })
}

export function fetchCategoryList() {
  axios
    .get(`${constants.HOST}${routes.fetchCategories}`)
    .then(function ({ data: response }) {
      if (response.success) {
        store.dispatch(updateCategoryList(response.data))
      } else {
      }
    })
    .catch(function (error) {
      console.log(error)
    })
}

export function fetchSubcategoryList(category_id = '', callback) {
  callback && callback(true)
  axios
    .get(
      `${constants.HOST}${routes.fetchSubCategories}?category_id=${category_id} `
    )
    .then(function ({ data: response }) {
      if (response.success) {
        store.dispatch(updateSubCategoryList(response.data))
      } else {
      }
      callback && callback(false)
      console.log(response)
    })
    .catch(function (error) {
      callback && callback(false)
      console.log(error)
    })
}

export function fetchBusinessDetails(businessId = '') {
  store.dispatch(updatProductsListLoader(true))
  axios
    .get(`${constants.HOST}${routes.fetchBusiness}?business_id=${businessId} `)
    .then(function ({ data: response }) {
      store.dispatch(updatProductsListLoader(false))
      if (response.success) {
        store.dispatch(updateBusinessData(response.data.business))
        store.dispatch(updateProductsData(response.data.products))
        store.dispatch(updateLocationData(response.data.address))
      } else {
      }
      console.log(response)
    })
    .catch(function (error) {
      store.dispatch(updatProductsListLoader(false))
      console.log(error)
    })
}
