import axios from 'axios'
import { message } from 'antd'
import constants from 'config/constants'
import routes from 'config/routes'
import { getParameterByName } from 'helpers/string'

export function _verifyToken(
  setIsLoading,
  setIsVerified,
  token = getParameterByName('token')
) {
  setIsLoading(true)
  axios
    .post(`${constants.HOST}${routes.verifyEmail}`, {
      token
    })
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        setIsVerified(true)
        window.localStorage.setItem('userid', response.data.user_id)
      } else {
        setIsVerified(false)
      }
    })
    .catch(function(error) {
      setIsLoading(false)
      console.log(error)
    })
}
