import { EditFilled } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Space, Tag } from 'antd'
import Rating from 'components/Ratings'
import UploadFile from 'components/UploadFile'
import React, { useEffect, useState } from 'react'
import './styles.scss'
import youtube from 'assets/icons/youtube.png'
import whatsapp from 'assets/icons/whatsapp.png'
import facebook from 'assets/icons/facebook.png'
import twitter from 'assets/icons/twitter.png'
import instagram from 'assets/icons/instagram.png'
import { useParams } from 'react-router-dom'
import BusinessHeader from '../BusinessHeader'
import { _getBusinessInfo } from '../api'
import SpinLoader from 'components/SpinLoader'
import Messanger from 'components/Messanger'

const Status = props => {
  const [isSpinning, setIsSpinning] = useState(false)
  const [form] = Form.useForm()
  const [data, setData] = useState('')
  const params = useParams()
  useEffect(() => {
    _getBusinessInfo(params.business_id, setIsSpinning, data => {
      setData(data)
      form.setFieldsValue({
        blNumber: data.blno,
        description: data.description,
        opration: data.hrs_of_operation,
        village: data.village,
        location: data.address,
        name: data.name,
        phone: data.phone,
        email: data.email,
        website: data.website,
        instagram: data.instagram,
        twitter: data.twitter,
        youtube: data.youtube,
        whatsapp: data.whatsapp,
        facebook: data.facebook
      })
    })

    return () => {}
  }, [])
  return (
    <div className="status-info-wrapper">
      <Messanger name={data.business_name} {...props} />
      <div className="page-title">
        <h1>Registration Status</h1>
      </div>
      <BusinessHeader />
      <SpinLoader spinning={isSpinning}>
        <div className="form-wrapper">
          <div className="form-header-title">
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
              <i>Business Information</i>
            </div>

            <div className="tag-item">
              <BusStatus status={data.status || ' '} />
            </div>
          </div>
          <Row>
            <Col xs={24} sm={24} md={12} lg={10} xl={10}>
              <Form
                form={form}
                requiredMark={false}
                labelAlign="left"
                name="businessInformation"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                // initialValues={{ remember: true }}
              >
                <Form.Item label="BL Number" name="blNumber">
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item label="Description" name="description">
                  <Input.TextArea rows={4} className="input-style" readOnly />
                </Form.Item>
                <Form.Item label="Hours of Operation" name="opration">
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item label="Village" name="village">
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item label="Home-Based Location" name="location">
                  <Input className="input-style" readOnly />
                </Form.Item>
                {/* <Form.Item label="Categoty" name="category">
                  <Input className="input-style" readOnly />
                </Form.Item> */}
                <Form.Item label="Name" name="name">
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item label="email" name="email">
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item label="Website" name="website">
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item
                  className="remove-content"
                  label={<img width={20} src={facebook} alt="logo" />}
                  name="facebook"
                >
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item
                  className="remove-content"
                  label={<img width={20} src={instagram} alt="logo" />}
                  name="instagram"
                >
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item
                  className="remove-content"
                  label={<img width={20} src={twitter} alt="logo" />}
                  name="twitter"
                >
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item
                  className="remove-content"
                  label={<img width={20} src={youtube} alt="logo" />}
                  name="youtube"
                >
                  <Input className="input-style" readOnly />
                </Form.Item>
                <Form.Item
                  className="remove-content"
                  label={<img width={20} src={whatsapp} alt="logo" />}
                  name="whatsapp"
                >
                  <Input className="input-style" readOnly />
                </Form.Item>
              </Form>
              <Button
                type="primary"
                onClick={() =>
                  props.history.push(
                    '/dashboard/' + params.business_id + '/update-info'
                  )
                }
              >
                Edit <EditFilled />
              </Button>
            </Col>
            <Col xs={24} sm={24} md={12} lg={14} xl={14}>
              <div style={{ marginLeft: 50 }}>
                <img src={data.image_url} width={200} alt="logo" />
              </div>
            </Col>
          </Row>
        </div>
      </SpinLoader>
    </div>
  )
}

const BusStatus = ({ status }) => {
  switch (status.toLowerCase()) {
    case 'submitted':
      return <Tag color="blue"> Application Submitted</Tag>
    case 'approved':
      return <Tag color="green"> Application Approved</Tag>
    case 'rejected':
      return <Tag color="error"> Application Rejected</Tag>
    default:
      return <div></div>
  }
}

export default Status
