import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import { updateMayorMessages } from 'pages/Dashboard/dashboard.slice'
import store from 'redux/store'

export function _fetchMessages(setIsLoading, business_id, setMayorId) {
  setIsLoading(true)
  axios
    .get(
      `${constants.HOST}/business/message/fetch-messages-mayor?business_id=${business_id}`
    )
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        store.dispatch(updateMayorMessages(response.data))
        if (response.data.length) {
          const mayorId = response.data.filter(msg =>
            msg.auther_id.includes('admin')
          )[0].auther_id
          setMayorId(mayorId)
        }
        const doc = document.querySelector('#message-chat')
        if (doc) doc.scrollTo(0, doc.scrollHeight)
      }
    })
    .catch(function(error) {
      setIsLoading(false)
      console.log(error)
    })
}
