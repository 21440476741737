import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Table, Tree } from 'antd'
import './styles.scss'
import { deleteMayor, fetchMayorList, fetchSubCategotyList } from './api'
import {
  DeleteTwoTone,
  ExclamationCircleFilled,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons'
import EditMayor from './EditMayor'
import SpinLoader from 'components/SpinLoader'

const MayorTable = ({ category }) => {
  const [mayorList, setMayorList] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [visible, setVisible] = useState(false)

  const { confirm } = Modal

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: 'Village',
      dataIndex: 'village',
      key: 'village'
    },
    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name'
    },
    {
      title: 'Mayor Code',
      dataIndex: 'mayorcode',
      key: 'mayorcode'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <DeleteTwoTone
            twoToneColor={'red'}
            onClick={() => {
              return showDeleteConfirm(record.id)
            }}
          />
        )
      }
    }
  ]
  useEffect(() => {
    fetchMayorList(setMayorList)
    return () => {}
  }, [showLoader === false])

  if (!mayorList)
    return (
      <div className="full-screen-loader ">
        <div>
          <LoadingOutlined />
        </div>
      </div>
    )

  const showDeleteConfirm = id => {
    confirm({
      title: 'Are you sure delete this Mayor?',
      icon: <ExclamationCircleFilled />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      centered: true,
      cancelText: 'No',
      onOk() {
        deleteMayor(setShowLoader, id)
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  return (
    <SpinLoader spinning={showLoader}>
      <Modal
        visible={visible}
        title="Create Mayor"
        onCancel={() => {
          setVisible(false)
        }}
        footer={null}
      >
        <EditMayor
          setVisible={setVisible}
          setShow={setShowLoader}
          record={{ name: '', user_name: '', mayorcode: '', village: '' }}
        />
      </Modal>

      <div className="subcategory-managerment-wrapper">
        <h1 style={{ display: 'flex', justifyContent: 'space-between' }}>
          Mayor List{' '}
          <Button
            style={{ alignSelf: 'end' }}
            type="primary"
            onClick={() => {
              setVisible(true)
            }}
          >
            <PlusOutlined />
            Add New Mayor
          </Button>{' '}
        </h1>

        <Table
          pagination={false}
          columns={columns}
          dataSource={
            mayorList
              ? mayorList.map((cat, key) => {
                  return { ...cat, key }
                })
              : []
          }
          expandable={{
            expandedRowRender: record => <EditMayor record={record} />
          }}
        />
      </div>
    </SpinLoader>
  )
}
export default MayorTable
