import { Button, Col, Row, Space } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { _getUserBusinessList } from '../api'
import './styles.scss'

const UserBusinessList = () => {
  const [list, setList] = useState('')
  const history = useHistory()

  function handleAddBusiness() {
    if (document.querySelector('#dash_sub_header'))
      document.querySelector('#dash_sub_header').style.display = 'none'
    if (document.querySelector('#dash-banner'))
      document.querySelector('#dash-banner').style.display = 'none'
    history.push('/dashboard/new')
  }
  useEffect(() => {
    _getUserBusinessList(setList, history)
    if (document.querySelector('#dash_sub_header'))
      document.querySelector('#dash_sub_header').style.display = 'none'
    return () => {
      if (document.querySelector('#dash_sub_header'))
        document.querySelector('#dash_sub_header').style.display = 'block'
    }
  }, [])
  return (
    <div className="user-bueiness-wrapper">
      <SpinLoader spinning={!list}>
        {list && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center'
              }}
            >
              <h1>My Business List</h1>
              <Button type="text" onClick={handleAddBusiness}>
                + Add New Business
              </Button>
            </div>

            <div className="business-list-contatiner">
              <Row>
                {list.map(item => (
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <div className="business-card">
                      <Link to={'/dashboard/' + item.id}>
                        <img src={item.image_url} alt="logo" />
                        <div className="title">{item.business_name}</div>
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </>
        )}
      </SpinLoader>
    </div>
  )
}

export default UserBusinessList
