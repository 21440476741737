import { Input, Form, Button, Row, Col, Select } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useEffect, useState } from 'react'
import { updateCategory } from './api'
// import { updateVillage } from './api'

const EditCategory = ({ record }) => {
  const [showLoader, setShowLoader] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      category: record.category,
      category_chamoru: record.category_chamoru,
      category_korean: record.category_korean,
      category_chinese: record.category_chinese,
      category_chuukese: record.category_chuukese,
      category_japanese: record.category_japanese
    })
  }, [])
  return (
    <SpinLoader spinning={showLoader}>
      <Row>
        <Col md={12}>
          <Form
            form={form}
            name="editCategory"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            onFinish={values => updateCategory(setShowLoader, values, record)}
          >
            {/* japanese, korean and chinese CHamoru and Chuukese */}
            <Form.Item name="category" label="Category English">
              <Input />
            </Form.Item>
            <Form.Item name="category_japanese" label="Category Japanese">
              <Input />
            </Form.Item>
            <Form.Item name="category_korean" label="Category Korean">
              <Input />
            </Form.Item>
            <Form.Item name="category_chinese" label="Category Chinese">
              <Input />
            </Form.Item>
            <Form.Item name="category_chamoru" label="Category CHamoru">
              <Input />
            </Form.Item>
            <Form.Item name="category_chuukese" label="Category Chuukese">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </SpinLoader>
  )
}

export default EditCategory
