import { LeftOutlined } from '@ant-design/icons'
import { Button, Input, Form, Select } from 'antd'
import DragMarker from 'components/DragMarker'
import { fetchAllVillages } from 'components/Header/api'
import CompleteLocation, { _searchLocation } from 'components/LocationSearch'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _handleForm } from '../signup.slice'

const SelectLocation = props => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const signup = useSelector(state => state.signup)
  const common = useSelector(state => state.common)
  const [center, setCenter] = useState('')

  const onFinish = values => {
    if (props.onFinish) {
      props.onFinish(values)
      return
    }
    props.history.push('/signup/business/info')
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    dispatch(_handleForm({ attr, value }))
  }

  useEffect(() => {
    fetchAllVillages()
    form.setFieldsValue({
      location: signup.address,
      village: signup.village
    })
    return () => {}
  }, [])
  console.log(center)
  return (
    <div className="email-signup-form">
      <div
        style={{ cursor: 'pointer', width: '30px', marginBottom: '12px' }}
        onClick={() => props.history.goBack()}
      >
        <LeftOutlined />
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="signup-card-flex">
          <div className="top-container">
            <h1>Where Home-Based Business Thrive</h1>
            <Form.Item
              name="village"
              rules={[
                {
                  required: true,
                  message: 'Please pick village where yhou want to business!'
                }
              ]}
            >
              <Select
                placeholder={'Select village'}
                style={{
                  border: '1px solid #ccc',
                  width: 300,
                  borderRadius: 4
                }}
                onChange={val => handleChange('village', val)}
              >
                {common.villages.map(villageObj => {
                  return (
                    <Select.Option value={villageObj.village}>
                      {villageObj.village}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>

            {signup.address ? (
              <CompleteLocation
                placeholder={'Enter your business address'}
                defaultValue={signup.address}
                value={signup.address}
                handleSelect={loc => {
                  handleChange('location', loc)
                  handleChange('address', loc.address)
                }}
              />
            ) : (
              <Form.Item
                name="address"
                rules={[
                  { required: true, message: 'Please pick your location!' }
                ]}
              >
                <CompleteLocation
                  placeholder={'Enter your business address'}
                  defaultValue={signup.address}
                  value={signup.address}
                  handleSelect={loc => {
                    handleChange('location', loc)
                    handleChange('address', loc.address)
                  }}
                />
              </Form.Item>
            )}

            <br />
            <br />
            <div>
              {signup.location && (
                <>
                  <DragMarker
                    center={{
                      lat: parseFloat(signup.location.latitude),
                      lng: parseFloat(signup.location.longitude)
                    }}
                    handleUpdatCenter={data => {
                      handleChange('location', data)
                      handleChange('address', data.address)
                      form.setFieldsValue({
                        address: data.data
                      })
                    }}
                  />
                  <br />
                </>
              )}
            </div>
          </div>
          <div className="bottom-container">
            <Form.Item>
              <div className="btn-flex" style={{ width: '100%' }}>
                <Button
                  size="large"
                  type={'default'}
                  style={{ width: '130px' }}
                  onClick={() => props.history.goBack()}
                >
                  Back
                </Button>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ width: '130px' }}
                >
                  Continue
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default SelectLocation
