import React from 'react'
import { useHistory } from 'react-router-dom'

const SignInLabel = () => {
  const history = useHistory()
  return (
    <div className="signinlabel">
      Have an account?{' '}
      <span onClick={() => history.push('/signin')}>
        <u>Sign In Now</u>
      </span>
    </div>
  )
}

export default SignInLabel
