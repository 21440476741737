import Axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import { updateVillages } from 'pages/common.slice'
import store from 'redux/store'

export function fetchAllVillages() {
  Axios.get(`${constants.HOST}${routes.fetchAllVillages}`)
    .then(({ data: response }) => {
      if (response.success) {
        console.log(store.dispatch(updateVillages(response.data)))
        store.dispatch(updateVillages(response.data))
      } else {
      }
    })
    .catch((error) => {
      console.log(error)
    })
}
