import { Button, Form, Input, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { _handleForm } from '../signup.slice'
import { useDispatch, useSelector } from 'react-redux'
import SpinLoader from 'components/SpinLoader'
import { _updateUser } from '../api'
import { LeftOutlined } from '@ant-design/icons'
import { validateUrl } from 'helpers/string'

const { Option } = Select

const BusinessDetails = props => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const signup = useSelector(state => state.signup)
  const [isSpinning, setIsSpinning] = useState(false)

  const onFinish = values => {
    if (props.onFinish) {
      props.onFinish(props.history, setIsSpinning)
      return
    }
    _updateUser(props.history, setIsSpinning)
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    dispatch(_handleForm({ attr, value }))
  }

  useEffect(() => {
    form.setFieldsValue({
      name: signup.name,
      email: signup.email,
      blNumber: signup.blNumber,
      description: signup.description,
      dba: signup.dba,
      phone: signup.phone,
      website: signup.website,
      facebook: signup.facebook,
      instagram: signup.instagram,
      twitter: signup.twitter,
      youtube: signup.youtube,
      whatsapp: signup.whatsapp,
      hrs_of_operation: signup.hrs_of_operation
    })
    return () => {}
  }, [])

  return (
    <div>
      <div
        style={{ cursor: 'pointer', width: '30px', marginBottom: '12px' }}
        onClick={() => props.history.goBack()}
      >
        <LeftOutlined />
      </div>
      <SpinLoader spinning={isSpinning}>
        <div className="email-signup-form">
          <h1>We need a little information</h1>
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please verify you name'
                }
              ]}
            >
              <Input
                placeholder={'Full Name'}
                className="input-style"
                onChange={e => handleChange('name', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="blNumber"
              rules={[
                {
                  required: true,
                  message: 'Please enter business license no.!'
                }
              ]}
            >
              <Input
                placeholder={'Business License No.'}
                className="input-style"
                onChange={e => handleChange('blNumber', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please enter business description!'
                }
              ]}
            >
              <Input.TextArea
                placeholder="Business description"
                className="input-style"
                rows={4}
                onChange={e => handleChange('description', e.target.value)}
              />
            </Form.Item>
            <Form.Item name="dba">
              <Input
                placeholder={'DBA (if Applicable)'}
                className="input-style"
                onChange={e => handleChange('dba', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your business email id!'
                }
              ]}
            >
              <Input
                placeholder={'Business email id'}
                className="input-style"
                onChange={e => handleChange('email', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: 'Please enter your phone number!' }
              ]}
            >
              <Input
                placeholder={'Phone No. format: +1 (671) XXX-XXXX'}
                className="input-style"
                onChange={e => handleChange('phone', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="hrs_of_operation"
              rules={[
                { required: true, message: 'Please enter hours of operation!' }
              ]}
            >
              <Input
                placeholder={'Operating hours ex: 09:00 AM- 10:00 PM'}
                className="input-style"
                onChange={e => handleChange('hrs_of_operation', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="website"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || validateUrl(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Please enter valid url!'))
                  }
                })
              ]}
            >
              <Input
                placeholder={'website'}
                className="input-style"
                onChange={e => handleChange('website', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="facebook"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || validateUrl(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Please enter valid url!'))
                  }
                })
              ]}
            >
              <Input
                placeholder={'Facebook url'}
                className="input-style"
                onChange={e => handleChange('facebook', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="instagram"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || validateUrl(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Please enter valid url!'))
                  }
                })
              ]}
            >
              <Input
                placeholder={'Instagram url'}
                className="input-style"
                onChange={e => handleChange('instagram', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="twitter"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || validateUrl(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Please enter valid url!'))
                  }
                })
              ]}
            >
              <Input
                placeholder={'Twitter url'}
                className="input-style"
                onChange={e => handleChange('twitter', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="youtube"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || validateUrl(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Please enter valid url!'))
                  }
                })
              ]}
            >
              <Input
                placeholder={'Youtube url'}
                className="input-style"
                onChange={e => handleChange('youtube', e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="whatsapp"
              rules={
                [
                  // { required: true, message: 'Please enter whatsapp number!' }
                ]
              }
            >
              <Input
                placeholder={'Whatsapp Number'}
                className="input-style"
                onChange={e => handleChange('whatsapp', e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <div className="btn-flex" style={{ width: '100%' }}>
                <Button
                  size="large"
                  type={'default'}
                  style={{ width: '130px' }}
                  onClick={() => props.history.goBack()}
                >
                  Back
                </Button>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ width: '130px' }}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </SpinLoader>
    </div>
  )
}

export default BusinessDetails
