import { Button, Col, Form, Input, Modal, Row, Select, Tag, Upload } from 'antd'
import SpinLoader from 'components/SpinLoader'
import UploadFile from 'components/UploadFile'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _getBusinessInfo, _updateInformation } from '../api'
import './styles.scss'
import youtube from 'assets/icons/youtube.png'
import whatsapp from 'assets/icons/whatsapp.png'
import facebook from 'assets/icons/facebook.png'
import twitter from 'assets/icons/twitter.png'
import instagram from 'assets/icons/instagram.png'
import BusinessHeader from '../BusinessHeader'
import { validateUrl } from 'helpers/string'
import routes from 'config/routes'
import constants from 'config/constants'
import { fetchAllVillages } from 'components/Header/api'
import SelectService from 'pages/SignUp/SelectService'
import {
  fetchCategoryList,
  fetchSubcategoryList
} from 'pages/ExploreBusiness/api'
import { _updateSubcategoryList } from 'pages/SignUp/signup.slice'
import {
  CheckOutlined,
  InboxOutlined,
  LoadingOutlined,
  UploadOutlined
} from '@ant-design/icons'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import UploadFileWithDrag from 'components/UploadFileWithDrag'
import './styles.scss'

const UpdateInformation = () => {
  const dashboard = useSelector(state => state.dashboard)
  const common = useSelector(state => state.common)
  // const signup = useSelector((state) => state.signup)

  const [signup, setSignup] = useState({ subcategory: [] })

  const [form] = Form.useForm()
  const [isSpinning, setIsSpinning] = useState(false)
  const params = useParams()

  const [serviceModal, setServiceModal] = useState(false)
  const [data, setData] = useState({})

  const [uploadModel, setUploadModel] = useState(false)

  const onFinish = values => {
    _updateInformation(setIsSpinning, {
      ...values,
      id: params.business_id,
      signup: signup
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  function handleFileUpload(imageUrl) {
    setData({
      ...data,
      image_url: imageUrl
    })

    form.setFieldsValue({
      imageUrl: imageUrl
    })
  }

  function handleSelectSubCategory(subcategory) {
    if (signup.subcategory.includes(subcategory)) {
      setSignup({
        ...setSignup,
        subcategory: signup.subcategory.filter(i => i !== subcategory)
      })
    } else {
      setSignup({
        ...signup,
        subcategory: [...signup.subcategory, subcategory]
      })
    }
  }

  useEffect(() => {
    fetchAllVillages()
    _getBusinessInfo(params.business_id, setIsSpinning, data => {
      const _subCategoty = data.tags.split(',')
      setSignup({ ...signup, subcategory: _subCategoty })
      setData(data)
      form.setFieldsValue({
        blNumber: data.blno,
        description: data.description,
        opration: data.hrs_of_operation,
        village: data.village,
        location: data.address,
        name: data.name,
        phone: data.phone,
        email: data.email,
        website: data.website,
        instagram: data.instagram,
        facebook: data.facebook,
        twitter: data.twitter,
        youtube: data.youtube,
        whatsapp: data.whatsapp,
        imageUrl: data.image_url
        // service: dashboard.businessInfo.
      })
    })

    fetchCategoryList()
    fetchSubcategoryList()

    return () => {}
  }, [])

  return (
    <>
      <div className="signup-card-flex">
        <div className="top-container">
          <Modal
            title="Update Services"
            visible={serviceModal}
            onCancel={() => setServiceModal(false)}
            onOk={() => setServiceModal(false)}
            destroyOnClose={true}
            centered
            bodyStyle={{
              maxHeight: '400px',
              overflowY: 'auto'
            }}
            okButtonProps={{
              style: { backgroundColor: 'green' },
              type: 'primary'
            }}
          >
            {common.categories.length && common.subCategories.length ? (
              <div>
                {common.categories.map(categoryObj => (
                  <div>
                    <div>{categoryObj.category}</div>
                    <div>
                      {common.subCategories
                        .filter(
                          subcategory =>
                            categoryObj.id === subcategory.category_id
                        )
                        .map(subCatergoryObj => {
                          const checked = signup.subcategory.includes(
                            subCatergoryObj.subcategory
                          )
                          return (
                            <Tag.CheckableTag
                              style={{
                                backgroundColor: checked ? 'green' : ''
                              }}
                              checked={checked}
                              onChange={() =>
                                handleSelectSubCategory(
                                  subCatergoryObj.subcategory
                                )
                              }
                            >
                              {checked ? <CheckOutlined /> : '+'}{' '}
                              {subCatergoryObj.subcategory}
                            </Tag.CheckableTag>
                          )
                        })}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <LoadingOutlined />
            )}
          </Modal>
        </div>

        <div className="top-container">
          <Modal
            title="Upload image"
            visible={uploadModel}
            onCancel={() => setUploadModel(false)}
            onOk={() => setUploadModel(false)}
            destroyOnClose={true}
            centered
            bodyStyle={{
              maxHeight: '400px',
              overflowY: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            okButtonProps={{
              style: { backgroundColor: 'green' },
              type: 'primary'
            }}
          >
            <Form.Item name="imageUrl">
              <UploadFileWithDrag
                handleFileUpload={handleFileUpload}
                imageUrl={data?.image_url}
                action={`${constants.HOST}${routes.uploadBusinessLogo}`}
                data={{
                  business_id: params.business_id
                }}
              />
            </Form.Item>
          </Modal>
        </div>
      </div>

      <div className="update-info-wrapper">
        <SpinLoader spinning={isSpinning}>
          <div className="page-title">
            <h1>Update Information</h1>
          </div>
          <BusinessHeader />

          <div className="form-wrapper">
            <h1>
              <i>Business Information</i>
            </h1>
            <Form
              form={form}
              requiredMark={false}
              labelAlign="left"
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              // initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <Form.Item
                    label="BL Number"
                    name="blNumber"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your business license no.!'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter business description!'
                      }
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  <Form.Item
                    label="Hours of Operation"
                    name="opration"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter time of operation!'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Village"
                    name="village"
                    rules={[
                      { required: true, message: 'Please enter your village!' }
                    ]}
                  >
                    <Select placeholder={'Select village'} style={{}}>
                      {common.villages.map(villageObj => {
                        return (
                          <Select.Option value={villageObj.village}>
                            {villageObj.village}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Home-Based Location"
                    name="location"
                    rules={[
                      { required: true, message: 'Please enter your location!' }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: 'Please enter your name!' }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[
                      { required: true, message: 'Please enter your phone!' }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="email"
                    name="email"
                    rules={[
                      { required: true, message: 'Please enter your email!' },
                      {
                        required: 'email',
                        message: 'Please enter valid email!'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Website"
                    name="website"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || validateUrl(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error('Please enter valid url!')
                          )
                        }
                      })
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={<img width={20} src={facebook} alt="logo" />}
                    className="remove-content"
                    name="facebook"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || validateUrl(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error('Please enter valid url!')
                          )
                        }
                      })
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={<img width={20} src={instagram} alt="logo" />}
                    className="remove-content"
                    name="instagram"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || validateUrl(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error('Please enter valid url!')
                          )
                        }
                      })
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={<img width={20} src={twitter} alt="logo" />}
                    className="remove-content"
                    name="twitter"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || validateUrl(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error('Please enter valid url!')
                          )
                        }
                      })
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={<img width={20} src={youtube} alt="logo" />}
                    className="remove-content"
                    name="youtube"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || validateUrl(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error('Please enter valid url!')
                          )
                        }
                      })
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className="remove-content"
                    label={<img width={20} src={whatsapp} alt="logo" />}
                    name="whatsapp"
                    rules={[]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                  <div style={{ marginLeft: 50 }}>
                    {/* <img
                      height={'200px'}
                      style={{ cursor: 'pointer' }}
                      src={data?.image_url}
                      alt="logo"
                      onClick={() => setUploadModel(true)}
                    /> */}
                    <Form.Item name="imageUrl">
                      {data.image_url !== '' ? (
                        <img
                          height={'200px'}
                          style={{ cursor: 'pointer' }}
                          src={data?.image_url}
                          alt="logo"
                          onClick={() => setUploadModel(true)}
                        />
                      ) : (
                        <Button
                          onClick={() => setUploadModel(true)}
                          type="primary"
                        >
                          <UploadOutlined />
                          Upload Image
                        </Button>
                      )}
                    </Form.Item>
                  </div>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item wrapperCol={{ offset: 0 }}>
                    <Button
                      onClick={() => setServiceModal(!serviceModal)}
                      type="primary"
                      // htmlType="submit"
                      style={{ margin: '12px 12px 0px 0px' }}
                    >
                      Update service
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ margin: '12px 0px 0px 0px' }}
                    >
                      Update Information
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </SpinLoader>
      </div>
    </>
  )
}

export default UpdateInformation
