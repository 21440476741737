import { GoogleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import React from 'react'
import './styles.scss'

const Button = (props) => {
  return (
    <button className="button-component-wrapper">
      <Row>
        <Col span={4}>
          <div className="icon">
            {props.icon}
            {/* <GoogleOutlined /> */}
          </div>
        </Col>
        <Col span={20}>
          <div className="btn-text">
            <span>{props.label}</span>
          </div>
        </Col>
      </Row>
    </button>
  )
}

export default Button
