import { Upload, message, Input } from 'antd'
import React from 'react'
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons'
import '../UploadFile/styles.scss'

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
//   if (!isJpgOrPng) {
//     message.error('You can only upload JPG/PNG file!')
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2
//   if (!isLt2M) {
//     message.error('Image must smaller than 2MB!')
//   }
//   return isJpgOrPng && isLt2M
// }

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default class FilePicker extends React.Component {
  state = {
    loading: false,
    imageUrl: '',
    files: ''
  }

  componentDidMount() {
    if (this.props.imageUrl) {
      getBase64(this.props.imageUrl, imageUrl => {
        this.setState({ imageUrl })
      })
    }
  }

  handleChange = e => {
    const files = e.target.files
    this.setState({ files })

    getBase64(files[0], imageUrl => {
      //   this.props.handleFileUpload &&
      //     this.props.handleFileUpload(files[0].file.response.data)
      //   this.setState({
      //     imageUrl,
      //     loading: false
      //   })
      this.setState({ imageUrl })
      this.props.handleFileUpload && this.props.handleFileUpload(files[0])
    })

    // console.log(info.file.response)
    // if (info.file.status === 'uploading') {
    //   this.setState({ loading: true })
    //   return
    // }
    // if (info.file.status === 'done') {
    //   // Get this url from response in real world.

    // }
  }

  render() {
    const { loading, imageUrl } = this.state

    const uploadButton = (
      <div className="btn-label">
        {loading ? <LoadingOutlined /> : <UploadOutlined />}
        <div>{this.props.label || 'Upload Photo'}</div>
      </div>
    )
    return (
      <div
        className="uploader-wrapper"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <label for="upload-photo">
          {imageUrl || this.props.imageUrl ? (
            <img
              src={imageUrl || this.props.imageUrl}
              alt="avatar"
              style={{ width: '200px', cursor: 'pointer' }}
            />
          ) : (
            uploadButton
          )}
        </label>
        <input
          style={{ display: 'none' }}
          type="file"
          multiple={false}
          accept=".png"
          name="photo"
          id="upload-photo"
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
