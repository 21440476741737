import { Button, Result } from 'antd'
import React from 'react'
import './styles.scss'

const PageNotFound = (props) => {
  return (
    <div className="page-not-found">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => props.history.push('/')}>
            Back Home
          </Button>
        }
      />
    </div>
  )
}

export default PageNotFound
