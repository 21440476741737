import {
  LoadingOutlined,
  RightSquareFilled,
  SendOutlined
} from '@ant-design/icons'
import { Button, Col, Empty, Form, Input, Row } from 'antd'
import React from 'react'
import './styles.scss'
import { io } from 'socket.io-client'
import { fetchMessages, fetchMessagesIds } from '../api'
// import { useSelector } from 'react-redux'
// import { useDispatch } from 'react-redux'
import { updateMessageFromSocket } from '../admin.slice'
import { connect } from 'react-redux'
import store from 'redux/store'
import SearchBusiness from './SearchBusiness'
import { getParameterByName } from 'helpers/string'
import constants from 'config/constants'

class Messages extends React.Component {
  socket = io(constants.SOCKET)
  state = {
    messages: [],
    isSpinning: false,
    msgIds: '',
    activeId: '',
    auther_id: 'admin_' + window.localStorage.getItem('admin_id')
  }
  formRef = React.createRef()

  componentDidMount() {
    fetchMessagesIds(
      this.setIsSpinning,
      data => {
        if (getParameterByName('id') && getParameterByName('name')) {
          const bus = {
            id: parseInt(getParameterByName('id')),
            business_name: getParameterByName('name')
          }
          if (data.filter(msg => msg.id == bus.id).length) this.setMsgIds(data)
          else this.setMsgIds([bus, ...data])
          this.setActiveId(bus)
        } else {
          this.setMsgIds(data)
          if (data.length) this.setActiveId(data[0])
        }
      },
      this.state.auther_id,
      getParameterByName('id') || null
    )

    console.log(this.props.admin)
    this.socket.on('connect', () => {
      this.socket.emit('join', {
        user_id: this.state.auther_id
      })
    })

    this.socket.on('new_msg', data => {
      const bus_id = data.auther_id.split('_')[1]
      if (this.props.admin.messages[bus_id]) {
        store.dispatch(
          updateMessageFromSocket({
            data: [...this.props.admin.messages[bus_id], data],
            bus_id
          })
        )
      } else {
        store.dispatch(
          updateMessageFromSocket({
            data: [data],
            bus_id
          })
        )
      }
    })

    this.socket.on('disconnect', () => {
      // console.log(this.socket.id) // undefined
    })
  }

  setActiveId = activeId => this.setState({ activeId })
  setIsSpinning = isSpinning => this.setState({ isSpinning })
  setMsgIds = msgIds => this.setState({ msgIds })
  handleFinish = values => {
    if (!values.message) return
    const msg = {
      auther_id: this.state.auther_id,
      text: values.message,
      receiver_id: 'bus_' + this.state.activeId.id
    }
    const bus_id = this.state.activeId.id
    if (this.props.admin.messages[bus_id]) {
      store.dispatch(
        updateMessageFromSocket({
          data: [...this.props.admin.messages[bus_id], msg],
          bus_id
        })
      )
    } else {
      store.dispatch(
        updateMessageFromSocket({
          data: [msg],
          bus_id
        })
      )
    }

    this.socket.emit('new_message', msg)
    this.formRef.current.setFieldsValue({
      message: ''
    })
  }

  handleSelectSearch = business => {
    // msgIds
    if (this.state.msgIds.filter(bus => bus.id === business.id).length) {
      this.setState({
        activeId: business
      })
    } else {
      this.setState({
        msgIds: [business, ...this.state.msgIds],
        activeId: business
      })
    }
  }

  handleSelectBusinessId = business => {
    fetchMessages(this.setIsSpinning, business.id, this.state.auther_id)
    this.setState({ activeId: business })
  }

  render() {
    if (!this.state.msgIds)
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: 50,
            height: '60vh'
          }}
        >
          <div>
            <LoadingOutlined />
          </div>
        </div>
      )
    return (
      <div>
        <div className="page-title">
          <h1>Message Dashboard</h1>
        </div>
        <Row>
          <Col span={24}>
            <div className="messages-admin-wrapper">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 20
                }}
              >
                <SearchBusiness handleSelect={this.handleSelectSearch} />
              </div>
              {this.state.msgIds.length ? (
                <>
                  <div className="messages-component-container">
                    <Col span={6}>
                      <div className="messengers-list">
                        {this.state.msgIds &&
                          this.state.msgIds.map(msgID => (
                            <div
                              style={{
                                border:
                                  '1px solid ' +
                                  `${
                                    msgID.id === this.state.activeId.id
                                      ? 'blue'
                                      : '#eee'
                                  }`
                              }}
                              className="business-id"
                              onClick={() => this.handleSelectBusinessId(msgID)}
                            >
                              <img
                                src={
                                  msgID.image_url ||
                                  `https://guma-bus-registry.s3.amazonaws.com/business/logo/${msgID.id}.png`
                                }
                                alt="logo"
                              />
                              <span>{msgID.business_name}</span>
                            </div>
                          ))}
                      </div>
                    </Col>

                    <Col span={18}>
                      <div
                        className="messges-list-container"
                        id={'admin-message-chat'}
                      >
                        <div className="message-header-title"></div>
                        <div className="messages-list">
                          {this.state.activeId &&
                            this.props.admin.messages[this.state.activeId.id] &&
                            this.props.admin.messages[
                              this.state.activeId.id
                            ].map(msg => {
                              if (msg.auther_id === this.state.auther_id)
                                return (
                                  <>
                                    <div className="sender-wrapper name-sender">
                                      Mayor's Office
                                    </div>
                                    <div className="sender-wrapper">
                                      <pre className="sender">{msg.text}</pre>
                                    </div>
                                  </>
                                )
                              else
                                return (
                                  <>
                                    <div className="receiver-wrapper name-receiver">
                                      {this.state.activeId.business_name}
                                    </div>
                                    <div className="receiver-wrapper">
                                      <pre className="receiver">{msg.text}</pre>
                                    </div>
                                  </>
                                )
                            })}
                        </div>
                        <div className="input-form">
                          <div>
                            <Form
                              name="messanger"
                              ref={this.formRef}
                              onFinish={this.handleFinish}
                            >
                              <div className="input-container">
                                <Form.Item
                                  name="message"
                                  autoComplete={false}
                                  style={{ width: '100%' }}
                                >
                                  <Input
                                    placeholder="Type here"
                                    style={{ width: '100%' }}
                                    autoComplete={'off'}
                                  />
                                </Form.Item>
                                <Form.Item>
                                  <Button htmlType="submit">
                                    <SendOutlined />
                                  </Button>
                                </Form.Item>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </>
              ) : (
                <Empty description="No discussion found" />
              )}
            </div>
          </Col>
        </Row>
         
      </div>
    )
  }
}

const mapStateToProps = ({ admin }) => ({
  admin
})

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages)
