import React, { useEffect, useState } from 'react'
import { Table, Tag, Space } from 'antd'
import './styles.scss'
import { fetchSubCategotyList } from './api'
import { Link } from 'react-router-dom'
import { getParameterByName } from 'helpers/string'
import { LoadingOutlined } from '@ant-design/icons'
import EditSubCategory from './EditSubCategory'

const SubcategoryTable = ({ category }) => {
  const [subCategoryList, setSubCategoryList] = useState('')
  const columns = [
    {
      title: 'Id',
      dataIndex: 'subid',
      key: 'subid'
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      key: 'subcategory'
    },
    {
      title: 'Code',
      dataIndex: 'subcategory_code',
      key: 'subcategory_code'
    },
    {
      title: ' Description',
      dataIndex: 'subcategory_description',
      key: 'subcategory_description'
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Link>ok</Link>
    //     </Space>
    //   )
    // }
  ]

  const catgory_id = getParameterByName('category')
  useEffect(() => {
    fetchSubCategotyList(setSubCategoryList)
    return () => {}
  }, [])
  if (!subCategoryList)
    return (
      <div className="full-screen-loader ">
        <div>
          <LoadingOutlined />
        </div>
      </div>
    )
  return (
    <div className="subcategory-managerment-wrapper">
      <h1>Sub Catergory</h1>
      <Table
        pagination={false}
        columns={columns}
        dataSource={
          subCategoryList
            ? subCategoryList
                .filter(
                  sub => parseInt(sub.category_id) === parseInt(catgory_id)
                )
                .map((cat, key) => {
                  return { ...cat, key }
                })
            : []
        }
        expandable={{
          expandedRowRender: record => <EditSubCategory record={record} />
        }}
      />
    </div>
  )
}
export default SubcategoryTable
