import { Button, Col, Row, Tag } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import './styles.scss'

import youtube from 'assets/icons/youtube.png'
import whatsapp from 'assets/icons/whatsapp.png'
import facebook from 'assets/icons/facebook.png'
import twitter from 'assets/icons/twitter.png'
import instagram from 'assets/icons/instagram.png'
import locales from 'locales'

const BusinessInfromation = props => {
  const { rate, update, businessData, location } = props
  const history = useHistory()
  const key = 'AIzaSyAeTbG5b0AMWQOsIbVyOoLS9JBsUxaZwCA'
  const params = useParams()
  if (!businessData) return <div></div>
  return (
    <div>
      <div className="business-information-wrapper">
        <Row>
          <Col sm={24} xs={24} md={12} lg={10} xl={10}>
            <div>
              <h1>
                <i>{locales.businessInformation}</i>
              </h1>
            </div>
            <div className="logos">
              <span>{/* <img src={logo2} alt="logo" /> */ }</span>
              { businessData?.facebook && 
                <span>
                <a target='_blank' href={`${businessData?.facebook}`}>  <img src={facebook} alt="" /></a>
              </span>
              }

              { businessData?.instagram && 
                <span>
                <a target='_blank' href={`${businessData?.instagram}`}>  <img src={instagram} alt="" /></a>
              </span>
              }

              
{ businessData?.twitter && 
                <span>
                <a target='_blank' href={`${businessData?.twitter}`}>  <img src={twitter} alt="" /></a>
              </span>
              }
            
            { businessData?.youtube && 
                <span>
                <a target='_blank' href={`${businessData?.youtube}`}>  <img src={youtube} alt="" /></a>
              </span>
              }

{ businessData?.whatsapp && 
                <span>
                <a target='_blank' href={`${businessData?.whatsapp}`}>  <img src={whatsapp} alt="" /></a>
              </span>
              }
              
              
            </div>
            {/* <div>
              <b>Description : </b>
              {businessData.description || '-'}
            </div> */}
            <div>
              <b>Hours of Operations: </b>
              {businessData.hrs_of_operation || '-'}
            </div>
            <div>
              <b>{locales.address} :</b> {businessData.address || '-'}
            </div>
            <div>
              <b>{locales.village} :</b> {businessData.village || '-'}
            </div>
            <div>{/* <b>Category :</b> {businessData.category || '-'} */}</div>
            {/* <div>
              <b>Price Range :</b> {businessData.price_range || '-'}
            </div> */}
            <div>
              <b>{locales.name} :</b> {businessData.name || '-'}
            </div>
            <div>
              <b>{locales.phone} :</b> {businessData.phone || '-'}
            </div>
            <div>
              <b>{locales.email} :</b> {businessData.email || '-'}
            </div>
            <div>
              <b>{locales.website} :</b> {businessData.website || '-'}
            </div>
            {/* {rate && (
              <div>
                <Tag color="#7e7c61">Rate and Review the Business</Tag>
                <Tag color="#ddd" style={{ color: '#000' }}>
                  Report Abuse
                </Tag>
              </div>
            )} */}

            {update && (
              <div>
                <Button
                  type="text"
                  onClick={() =>
                    history.push(
                      '/dashboard/' + params.business_id + '/update-info'
                    )
                  }
                >
                  <Tag color="#7e7c61">{locales.updateInformation}</Tag>
                </Button>
              </div>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={14} xl={14}>
            {location && (
              <iframe
                title="location"
                width="100%"
                height="300px;"
                id="gmap_canvas"
                // src={`https://maps.google.com/maps?q=${location.latitude},${location.longitude}&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed`}
                // src={`https://maps.google.com/maps?q=13.522062452433,144.8623166411950&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed`}
                src={`https://www.google.com/maps/embed/v1/place?key=${key}&q=${location.latitude},${location.longitude}`}
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BusinessInfromation
