import { createSlice } from '@reduxjs/toolkit'

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    businessInfo: '',
    products: '',
    mayorMessages: []
  },
  reducers: {
    updateDashboardData: (state, action) => {
      state.products = action.payload.products
      state.businessInfo = action.payload.business || {}
    },
    updateMayorMessages: (state, action) => {
      state.mayorMessages = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
         updateDashboardData,
         updateMayorMessages
       } = dashboardSlice.actions

export default dashboardSlice.reducer
