import { Col, Row, Select } from 'antd'
import React, { useEffect } from 'react'
// import logo from 'assets/images/businesslogo.png'
import { LeftOutlined } from '@ant-design/icons'
import './styles.scss'
import BusinessInfromation from 'components/BusinessInformation'
// import Rating from 'components/Ratings'

// import { products } from './constants'
import { fetchBusinessDetails } from '../api'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SpinLoader from 'components/SpinLoader'
import ProductCarousel from 'components/ProductsCarousel'
import locales from 'locales'
import { handleBusinessDescLanguage, handleBusinessLanguage } from 'helpers/language'
const { Option } = Select

const Products = props => {
  const params = useParams()
  const common = useSelector(state => state.common)
  useEffect(() => {
    fetchBusinessDetails(params.business_id)
    window.scrollTo(0, 0)
    return () => {}
  }, [])
  return (
    <div className="products-list-wrapper">
      {/*  */}
      <SpinLoader spinning={common.productLoader}>
        <div className="back-container">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => props.history.goBack()}
          >
            <LeftOutlined style={{ fontSize: '10px', marginBottom: '2px' }} />{' '}
            {locales.backToResults}
          </div>
          {/* <div>
          <div>
            <span>Sort By </span>
            <Select
              defaultValue="lucy"
              style={{ width: 120 }}
              // onChange={handleChange}
            >
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="Yiminghe">yiminghe</Option>
            </Select>
          </div>
        </div> */}
        </div>
        {common.businessData ? (
          <div className="business-description">
            <img
              src={
                common.businessData.image_url ||
                `https://guma-bus-registry.s3.us-west-1.amazonaws.com/business/logo/${common.businessData.bus_id}.png`
              }
              alt="logo"
            />
            <div>
              <div className="title">
                {handleBusinessLanguage(common.businessData)}
              </div>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'normal',
                  color: '#505050'
                }}
                className="title"
              >
                { handleBusinessDescLanguage (common.businessData) }
                
              </div>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'normal',
                  color: '#505050'
                }}
                className="title"
              >
                { common.businessData.product }
                
              </div>
              
              {/* <Rating /> */}
            </div>
          </div>
        ) : (
          ''
        )}

        {/*  */}
        <ProductCarousel
          loader={common.productLoader}
          products={common.products}
        />

        {/*  */}
        <BusinessInfromation
          rate
          businessData={common.businessData}
          location={common.location}
        />
      </SpinLoader>
    </div>
  )
}

export default Products
