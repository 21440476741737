import React, { useEffect, useState } from 'react'
import '../../SignUp/EmailForm/styles.scss'
import './styles.scss'
import { Button, Input, Select, Form } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import SpinLoader from 'components/SpinLoader'
import { _postLogin } from '../api'
import SignUpLabel from '../SignUpLabel'
const { Option } = Select

const SignWithEmail = (props) => {
  const [formData, setFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (values) => {
    console.log('Success:', values)
    _postLogin(formData, setIsLoading, props.history)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    setFormData({
      ...formData,
      [attr]: value
    })
  }

  useEffect(() => {
    return () => {}
  }, [])
  return (
    <div className="email-signup-form sign-in-email">
      <SpinLoader spinning={isLoading}>
        <h1>Sign In With Your Email</h1>
        <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please enter your email!' }]}
          >
            <Input
              placeholder="Email"
              className="input-style"
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input
              type="password"
              placeholder="Password"
              className="input-style"
              onChange={(e) => handleChange('password', e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="question"
            rules={[
              { required: true, message: 'Please select security question!' }
            ]}
          >
            <Select
              placeholder="Choose your security question"
              className="input-style"
              onSelect={(value) => handleChange('question', value)}
            >
              <Option value="Who was your favorite teacher?">
                Who was your favorite teacher?
              </Option>
              <Option value="What is your favorite movie?">
                What is your favorite movie?
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="anwer"
            rules={[{ required: true, message: 'Please enter the answer!' }]}
          >
            <Input
              placeholder="Answer above question"
              className="input-style"
              onChange={(e) => handleChange('answer', e.target.value)}
            />
          </Form.Item>

          <div className="mandatory-message">
            <div>
              <InfoCircleFilled /> Mandatory secret question
            </div>
            <div
              style={{ curser: 'pointer' }}
              onClick={() => props.history.push('/signin/forgot')}
            >
              <u>Forgot password</u>
            </div>
          </div>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Sign In
            </Button>
          </Form.Item>
          <div style={{ textAlign: 'center' }}>
            <SignUpLabel />
          </div>
        </Form>
      </SpinLoader>
    </div>
  )
}

export default SignWithEmail
