import React from 'react'
import ProductCard from 'components/ProductCard'
import Slider from 'react-slick'
import { Col, Empty, Row } from 'antd'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { deviceCheck, mobileCheck } from 'helpers/string'

const ProductCarousel = ({ products, loader, handleEdit }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToShow: deviceCheck().tablet ? 3 : deviceCheck().mobile ? 1 : 5,
    slidesToScroll: 1
  }
  return (
    <div>
      <div className="products-wrapper">
        {products.length > 5 || deviceCheck().mobile ? (
          <Slider {...settings}>
            {products.map((product) => (
              <ProductCard product={product} handleEdit={handleEdit} />
            ))}
          </Slider>
        ) : (
          <Row>
            {products.length > 0 ? (
              products.map((product) => (
                <Col lg={4} md={4}>
                  <ProductCard product={product} handleEdit={handleEdit} />
                </Col>
              ))
            ) : (
              <Col lg={24} md="24">
                {!loader && (
                  <p style={{ textAlign: 'center' }}>
                    <Empty description={'No products found'} />,
                  </p>
                )}
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  )
}

export default ProductCarousel
