import { Upload, message } from 'antd'
import React from 'react'
import {
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons'
import './styles.scss'
import ImgCrop from 'antd-img-crop'

import 'antd/dist/antd.css'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default class UploadFileWithDrag extends React.Component {
  state = {
    loading: false,
    imageUrl: '',
    fileList: []
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        this.props.handleFileUpload &&
          this.props.handleFileUpload(info.file.response.data)
        this.setState({
          imageUrl,
          loading: false
        })
      })
    }
  }

  render() {
    const { loading, imageUrl } = this.state

    return (
      <div className="uploader-wrapper">
        <ImgCrop>
          <Upload.Dragger
            maxCount={1}
            multiple={false}
            action={this.props.action}
            name={'doc'}
            onChange={this.handleChange}
            showUploadList={{
              showRemoveIcon: false
            }}
          >
            <p className="ant-upload-drag-icon">
              {loading ? (
                <LoadingOutlined style={{ color: 'green' }} />
              ) : (
                <UploadOutlined style={{ color: 'green' }} />
              )}
            </p>
            <p
              className="ant-upload-text"
              style={{ padding: '0px 40px 0px 40px' }}
            >
              Drop File here or click to upload
            </p>{' '}
            <p className="ant-upload-text">[PNG,JPG,JPEG]</p>{' '}
          </Upload.Dragger>
        </ImgCrop>
      </div>
    )
  }
}

// .avatar-uploader > .ant-upload {
//   width: 128px;
//   height: 128px;
// }
