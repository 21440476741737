import React, { useEffect, useState } from 'react'
import PageNotFound from 'components/PageNotFound'
import SignUpFooter from 'pages/SignUp/SignUpFooter'
import SignUpHeader from 'pages/SignUp/SignUpHeader'
import { Route, Switch } from 'react-router-dom'
import SignIn from './SignIn'
import '../SignUp/styles.scss'
import SignWithEmail from './SignWithEmail'
import PasswordReset from './PasswordReset'
import PasswordResetMessage from './PasswordResetMessage'
import background from 'assets/images/bakery.jpg'
import { fetchUserAccountDetails } from 'pages/SignUp/api'
import { LoadingOutlined } from '@ant-design/icons'

const Login = props => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    document.querySelector('header').style.display = 'none'
    document.querySelector('footer').style.display = 'none'
    fetchUserAccountDetails(props.history, setIsLoading)
    return () => {
      document.querySelector('header').style.display = 'block'
      document.querySelector('footer').style.display = 'block'
    }
  }, [])

  if (isLoading) return <LoadingOutlined />

  return (
    <div
      className="signup-wrapper"
      style={{ backgroundImage: `url(${background})` }}
    >
      <Route component={SignUpHeader} />
      <div className="signup-card">
        <div className="signup-card-container">
          <Switch>
            <Route
              path="/signin/forgot/success"
              component={PasswordResetMessage}
              exact
            />
            <Route path="/signin/forgot" component={PasswordReset} exact />
            <Route path="/signin/email" component={SignWithEmail} exact />
            <Route path="/signin" component={SignIn} exact />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
      <Route component={SignUpFooter} />
    </div>
  )
}

export default Login
