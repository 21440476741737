import { Input, Form, Button, Row, Col, Select } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useEffect, useState } from 'react'
import { updateMayor } from './api'

const EditMayor = ({ record, setVisible, setShow }) => {
  const [showLoader, setShowLoader] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      name: record.name,
      user_name: record.user_name,
      village: record.village,
      mayorcode: record.mayorcode
    })
  }, [])
  return (
    <SpinLoader spinning={showLoader}>
      <Row>
        <Col md={record.id ? 12 : 24}>
          <Form
            form={form}
            name="create-update-mayor"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            onFinish={values =>
              updateMayor(setShowLoader, values, record, setVisible, setShow)
            }
          >
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="village" label="Village">
              <Input />
            </Form.Item>
            <Form.Item name="user_name" label="User Name">
              <Input />
            </Form.Item>
            <Form.Item name="mayorcode" label="Mayor Code">
              <Input />
            </Form.Item>

            {record.id ? (
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            ) : (
              <>
                <Form.Item name="password" label="Password">
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Create
                  </Button>
                </Form.Item>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </SpinLoader>
  )
}

export default EditMayor
