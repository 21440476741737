const { updatSignInStatus } = require('pages/common.slice')
const { default: store } = require('./store')

export const _handleSignout = () => {
  store.dispatch(updatSignInStatus(false))
  window.localStorage.removeItem('email')
  window.localStorage.removeItem('name')
  window.localStorage.removeItem('userid')
  window.localStorage.removeItem('business_id')
  window.localStorage.removeItem('admin_id')
  window.localStorage.removeItem('admin_village')
  window.localStorage.removeItem('admin_name')
  window.localStorage.removeItem('image_url')

  
  if (!window.location.pathname.includes('signup')) {
    window.location.href = '/'
  }
}

export const _handleSignIn = () => {
  store.dispatch(updatSignInStatus(true))
}
