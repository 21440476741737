import PageNotFound from 'components/PageNotFound'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BusinessSearchPage from './BusinessSearchPage'
import Products from './Products'
import './styles.scss'
import { Input } from 'antd'
import { getParameterByName } from 'helpers/string'
const { Search } = Input

const ExploreBusiness = (props) => {
  const village = getParameterByName('village')
  return (
    <div className="explore-business">
      <div
      // className="search-banner"
      // style={{ backgroundImage: `url(${banner1})` }}
      >
        {/* <Search
          placeholder="What can we help you find today?"
          enterButton="Search"
          size="large"
          onSearch={(value) => {
            if (!value) return
            if (village) {
              props.history.push(`/business?search=${value}&village=${village}`)
            } else {
              props.history.push(`/select-village`)
            }
          }}
        /> */}
      </div>
      <Switch>
        <Route path="/business/:business_id" component={Products} />
        <Route path="/business" component={BusinessSearchPage} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  )
}

export default ExploreBusiness
