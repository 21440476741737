import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown, Image } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/GUMA IBM LOGO - ROUND.png'
import Avatar from 'antd/lib/avatar/avatar'
import { _handleSignout } from 'redux/utils'

const AdminHeader = () => {
  return (
    <div className="header-wrapper">
      <div className="top-header">
        <div className="info">
          <Link to="/">
            <span>
              <img src={logo} alt="" />
            </span>
            <span>Village Resource Directory</span>
          </Link>
        </div>
        <div className="info">
          {/* <span>Translate</span> */}
          <span>
            <Link to="/manager">
              <div className="info-avatar">
                <div>
                  Hafa Adai, {window.localStorage.getItem('admin_name')}{' '}
                </div>
                <div>
                  {/* <Avatar
                  // src={
                  //   <Image src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  // }
                  >
                    {window.localStorage
                      .getItem('admin_name')[0]
                      .toLocaleUpperCase()}
                  </Avatar> */}
                </div>
              </div>
            </Link>

            {/* <Dropdown overlay={menu}>
              <div className="joinus">
                <span>Join Us</span>
                <EllipsisOutlined style={{ marginLeft: '-10px' }} />
              </div>
            </Dropdown> */}
          </span>
          <span className="tab sign-out" onClick={_handleSignout}>
            Sign Out
          </span>
        </div>
      </div>
      <div className="bottom-header">
        <nav>
          <ul>
            {/* <li>
              <Link to="/manager">Home</Link>
              <span>|</span>
            </li> */}
            <li>
              <Link to="/manager/villages">Villages</Link>
              <span>|</span>
            </li>
            <li>
              <Link to="/manager/category">Category</Link>
              <span>|</span>
            </li>
            <li>
              <Link to="/manager/business">Business</Link>
              <span>|</span>
            </li>
            <li>
              <Link to="/manager/products">Products</Link>
              <span>|</span>
            </li>

            {/* <li>
              <Link to="/manager/mayor" exact>
                Mayor
              </Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default AdminHeader
