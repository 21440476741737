import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import { _handleSignIn } from 'redux/utils'

export function _postLogin(formData, setIsLoading, history) {
  setIsLoading(true)
  axios
    .post(`${constants.HOST}${routes.login}`, {
      email: formData.email,
      question: formData.question,
      answer: formData.answer,
      password: formData.password
    })
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        console.log('userId', response.data)
        window.localStorage.setItem('name', response.data.name)
        window.localStorage.setItem('email', response.data.email)
        console.log('userId', response.data.id,'---',response.data.status)

        if (response.data.status == 'registration' || response.data.status == 'verified' || response.data.status == 'dashboard') {
          window.localStorage.setItem('userid', response.data.id)
        }
        if (response.data.business_id) {
          window.localStorage.setItem('business_id', response.data.business_id)
          // window.localStorage.setItem('image_url', response.data.image_url)
        }

        history.push('/dashboard')
        _handleSignIn()
        return
      }
      message.warn(response.message)
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function _postLoginWithAuth(data, setIsLoading, history) {
  setIsLoading(true)
  axios
    .post(`${constants.HOST}${routes.loginWithAuth}`, {
      email: data.email,
      name: data.name
    })
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        window.localStorage.setItem('name', response.data.name)
        window.localStorage.setItem('email', response.data.email)
        window.localStorage.setItem('userid', response.data.id)
        _handleSignIn()
        if (response.data.status === 'registration') {
          history.push('/signup/business/name')
        }
        if (response.data.status === 'dashboard') {
          history.push('/dashboard')
        }

        return
      }
      message.warn(response.message)
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function _forgotPassword(email, setIsLoading, history) {
  setIsLoading(true)
  axios
    .post(`${constants.HOST}${routes.userInfo}`, { email })
    .then(function({ data: response }) {
      setIsLoading(false)
      if (response.success) {
        return
      }
      message.warn(response.message)
    })
    .catch(function(error) {
      history.push('/signin/forgot/success')
      console.log(error)
    })
}
