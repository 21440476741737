import React, { useState } from 'react'
import { Input, AutoComplete, Form } from 'antd'
import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'

// function getRandomInt(max, min = 0) {
//   return Math.floor(Math.random() * (max - min + 1)) + min // eslint-disable-line no-mixed-operators
// }
const { Option } = AutoComplete

export async function _searchBusiness(string, setOptions) {
  return await axios
    .get(`${constants.HOST}${'/admin/business/search'}?search=${string}`)
    .then(function({ data: response }) {
      if (response.success) {
        setOptions(response.data)
      }
      return []
    })
    .catch(function(error) {
      console.log(error)
    })
}

const SearchBusiness = props => {
  const [options, setOptions] = useState()

  const handleSearch = async value => {
    if (value && value.length >= 3) _searchBusiness(value, setOptions)
    else setOptions([])
  }
  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      onSearch={handleSearch}
      placeholder="search"
      style={{ border: '1px solid #ccc', width: 300, borderRadius: 4 }}
      {...props}
      onSelect={(val, obj) =>
        props.handleSelect(options.filter(option => obj.key == option.id)[0])
      }
    >
      {options &&
        options.map(item => (
          <Option key={item.id} value={item.business_name}>
            {item.business_name}
          </Option>
        ))}
    </AutoComplete>
  )
}

export default SearchBusiness
