function constants() {
  switch (process.env.NODE_ENV) {
    case 'production':
      return {
        HOST: 'https://apiguma.nesiadev.com',
        SOCKET: 'https://wsguma.nesiadev.com'
      }
    case 'development':
      return {
        HOST: 'https://apiguma.nesiadev.com',
        SOCKET: 'https://wsguma.nesiadev.com',
        //HOST: 'http://localhost:4000'
      }
    default:
      return {}
  }
}

export default constants()
