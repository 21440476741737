import { Button, Col, Input, Row, Space } from 'antd'
import React from 'react'
import './styles.scss'
import logo2 from 'assets/images/GUMA Logo 2021-01.png'
import youtube from 'assets/icons/youtube.png'
import whatsapp from 'assets/icons/whatsapp.png'
import facebook from 'assets/icons/facebook.png'
import twitter from 'assets/icons/twitter.png'
import instagram from 'assets/icons/instagram.png'
import { useSelector } from 'react-redux'
import { handleCategoryLanguage } from 'helpers/language'
import locales from 'locales'

const Footer = () => {
  const common = useSelector(state => state.common)
  return (
    <footer className="footer-wrapper">
      <div className="site-data">
        <div>{locales.popularCategories}</div>
        <br />
        <div>
          {common.categories.map((catagory, index) => (
            <>
              {common.categories.length - 2 != index && (
                <span>{handleCategoryLanguage(catagory)} | </span>
              )}
            </>
          ))}
        </div>
        <br />
        <div className="menu-wrapper">
          <Row>
            <Col xs={12} sm={12} md={12} xl={6} lg={6}>
              {/* For customers */}
              <div className="item">
                <div>
                  <div>
                    <b>{locales.popularCategories}</b>
                  </div>
                  <div>{locales.joinOutNetwork}</div>
                  <div>{locales.findService}</div>
                </div>
                <div>
                  <div>
                    <b>{locales.forServicePros}</b>
                  </div>
                  <div>{locales.registerYourBusiness}</div>
                </div>
              </div>
            </Col>
            {/* Resources */}
            <Col xs={12} sm={12} md={12} xl={6} lg={6}>
              <div className="item">
                <div>
                  <b>{locales.resources}</b>
                </div>
                <div>
                  <div>{locales.FAQs}</div>
                  <div>{locales.helpVideos}</div>
                  <div>{locales.privacyPolicy}</div>
                  <div>{locales.termsofUse}</div>
                  <div>{locales.siteMap}</div>
                </div>
              </div>
            </Col>
            {/* About us */}
            <Col xs={12} sm={12} md={12} xl={6} lg={6}>
              <div className="item">
                <div>
                  <b>{locales.aboutUs}</b>
                  <div>
                    <div>{locales.howitWorks}</div>
                    <div>{locales.contactUs}</div>
                  </div>
                </div>
              </div>
            </Col>
            {/* Question, Comments, Suggestions? */}
            <Col xs={24} sm={24} md={12} xl={6} lg={6}>
              <div className="item">
                <div>
                  <b>{locales.questionsTitle}</b>
                </div>
                <div className="form-data">
                  <Space direction="vertical">
                    <div className="half-input">
                      <Space>
                        <Input placeholder="Name" />
                        <Input placeholder="Email" />
                      </Space>
                    </div>
                    <div>
                      <Input placeholder="Message" />
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <Button>{locales.submit}</Button>
                    </div>
                  </Space>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <CopyRightFooter />
    </footer>
  )
}

const CopyRightFooter = () => {
  return (
    <div className="copyright-info">
      <div>{locales.copyrightText}</div>
      <div className="logos">
        <span>
          <img src={logo2} alt="logo" />
        </span>
        <span>
          <img src={facebook} alt="" />
        </span>
        <span>
          <img src={instagram} alt="" />
        </span>
        <span>
          <img src={twitter} alt="" />
        </span>
        <span>
          <img src={youtube} alt="" />
        </span>
        <span>
          <img src={whatsapp} alt="" />
        </span>
      </div>
    </div>
  )
}

export { CopyRightFooter }

export default Footer
