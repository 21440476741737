import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'
import routes from 'config/routes'
import store from 'redux/store'
import { updateDashboardData } from './dashboard.slice'
import { getCategory } from 'pages/SignUp/api'

export function _getUserBusinessList(setList, history) {
  const userId = window.localStorage.getItem('userid')

  axios
    .get(`${constants.HOST}${routes.userBusinesses}?userId=${userId}`)
    .then(function({ data: response }) {
      if (response.success) {
        if (response.data.length) setList(response.data)
        else history.push('/signup/business')
      } else {
        setList([])
        message.warn(response.message)
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function _getUserInfo(business_id) {
  axios
    .get(`${constants.HOST}${routes.dashboard}?business_id=${business_id}`)
    .then(function({ data: response }) {
      if (response.success) {
        // setBusinessData(response.data)
        store.dispatch(updateDashboardData(response.data))
      } else {
        message.warn(response.message)
      }
      console.log(response)
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function _getBusinessInfo(business_id, setIsSpinning, callback) {
  setIsSpinning(true)
  axios
    .get(`${constants.HOST}${routes.businessInfo}?business_id=${business_id}`)
    .then(function({ data: response }) {
      setIsSpinning(false)
      if (response.success) {
        callback && callback(response.data)
      } else {
        message.warn(response.message)
      }
      console.log(response)
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function _getProduct(setIsSpinning, productId, callback) {
  setIsSpinning(true)
  axios
    .get(`${constants.HOST}${routes.getProduct}?product_id=${productId}`)
    .then(function({ data: response }) {
      setIsSpinning(false)
      if (response.success) {
        callback && callback(response.data)
      } else {
        message.warn(response.message)
      }
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function _addProduct(setIsSpinning, formData, business_id) {
  console.log(formData)
  setIsSpinning(true)
  axios
    .post(`${constants.HOST}${routes.addProduct}`, {
      businessId: business_id,
      productName: formData.name,
      description: formData.description,
      availability: formData.availability,
      tags: formData.tags.join(','),
      category: formData.category,
      imageUrl: formData.imageUrl,
      priceRangeMin: formData.priceMinimum,
      priceRangeMax: formData.priceMaximum,
      status: formData.status
    })
    .then(function({ data: response }) {
      setIsSpinning(false)
      if (response.success) {
        message.success(response.data)
        _getUserInfo(business_id)
      } else {
        message.warn(response.message)
      }
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function _updateProduct(
  setIsSpinning,
  formData,
  productId,
  business_id
) {
  setIsSpinning(true)
  axios
    .post(`${constants.HOST}${routes.updateProduct}`, {
      productId: productId,
      productName: formData.name,
      description: formData.description,
      availability: formData.availability,
      tags: formData.tags.join(','),
      imageUrl: formData.imageUrl,
      priceRangeMin: formData.priceMinimum,
      priceRangeMax: formData.priceMaximum,
      status: formData.status,
      category: formData.category
    })
    .then(function({ data: response }) {
      setIsSpinning(false)
      if (response.success) {
        message.success(response.data)
        _getUserInfo(business_id)
      } else {
        message.warn(response.message)
      }
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function _deleteProduct(setIsSpinning, productId, history, business_id) {
  setIsSpinning(true)
  axios
    .get(`${constants.HOST}${routes.deleteProduct}?product_id=${productId}`)
    .then(function({ data: response }) {
      setIsSpinning(false)
      if (response.success) {
        message.success(response.data)
        _getUserInfo(business_id)
        history.push('/dashboard/' + business_id)
      } else {
        message.warn(response.message)
      }
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function _updateInformation(setIsSpinning, data) {
  setIsSpinning(true)
  axios
    .post(`${constants.HOST}${routes.updateBusinessInfo}`, {
      businessId: parseInt(data.id),
      blNumber: data.blNumber,
      category: data.category,
      description: data.description,
      email: data.email,
      facebook: data.facebook,
      instagram: data.instagram,
      address: data.location,
      name: data.name,
      opration: data.opration,
      phone: data.phone,
      twitter: data.twitter,
      village: data.village,
      website: data.website,
      whatsapp: data.whatsapp,
      imageUrl: data.imageUrl,
      youtube: data.youtube,
      tags: data?.signup?.subcategory.join(','),
      category: getCategory(data.signup).join(',')
    })
    .then(function({ data: response }) {
      if (response.success) {
        message.success(response.data)
        _getUserInfo(parseInt(data.id))
      } else {
        message.warn(response.message)
      }
      setIsSpinning(false)
    })
    .catch(function(error) {
      setIsSpinning(false)
      console.log(error)
    })
}

export function _getCategoryTags(category, setTags) {
  axios
    .get(`${constants.HOST}${routes.fetchTags}?category=${category}`)
    .then(function({ data: response }) {
      if (response.success) {
        // setBusinessData(response.data)
        setTags(response.data)
      } else {
        message.warn(response.message)
      }
      console.log(response)
    })
    .catch(function(error) {
      console.log(error)
    })
}
