import { SendOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { updateMayorMessages } from 'pages/Dashboard/dashboard.slice'
import React from 'react'
import { io } from 'socket.io-client'
import { _fetchMessages } from './api'
import './styles.scss'
import { connect } from 'react-redux'
import store from 'redux/store'
import constants from 'config/constants'

class Messanger extends React.Component {
  state = {
    isSpinning: false,
    business_id: '',
    mayorId: ''
  }
  formRef = React.createRef()
  socket = io(constants.SOCKET)

  componentDidMount() {
    _fetchMessages(
      this.setIsSpinning,
      this.props.match.params.business_id,
      mayorId => this.setState({ mayorId })
    )
    // client-side
    this.socket.on('connect', () => {
      this.socket.emit('join', {
        user_id: 'bus_' + this.props.match.params.business_id
      })
    })

    this.socket.on('new_msg', data => {
      store.dispatch(
        updateMayorMessages([...this.props.dashboard.mayorMessages, data])
      )
      const doc = document.querySelector('#message-chat')
      doc.scrollTo(0, doc.scrollHeight)
    })

    this.socket.on('disconnect', () => {
      console.log(this.socket.id)
    })
  }
  // his.props.match.

  setIsSpinning = isSpinning => this.setState({ isSpinning })

  handleFinish = values => {
    if (!values.message) return
    const msg = {
      auther_id: 'bus_' + this.props.match.params.business_id,
      text: values.message,
      receiver_id: this.state.mayorId
    }

    store.dispatch(
      updateMayorMessages([...this.props.dashboard.mayorMessages, msg])
    )

    this.socket.emit('new_message', msg)
    this.formRef.current.setFieldsValue({
      message: ''
    })

    const doc = document.querySelector('#message-chat')
    doc.scrollTo(0, doc.scrollHeight)
  }

  render() {
    if (
      this.props.dashboard.mayorMessages &&
      this.props.dashboard.mayorMessages.length
    )
      return (
        <div className="messanger-wrapper">
          <div style={{padding: '8px 20px 0 20px', borderBottom: '1px solid #aaa'}}>
            <h2>Mayor's Office</h2>
          </div>
          <div id={'message-chat'} className={'messages'}>
            {this.props.dashboard.mayorMessages &&
              this.props.dashboard.mayorMessages.map(msg => {
                if (msg.auther_id.includes('admin'))
                  return (
                    <>
                      <div className="receiver-wrapper name-receiver">
                        Mayor's Office
                      </div>
                      <div className="receiver-wrapper">
                        <pre className="receiver">{msg.text}</pre>
                      </div>
                    </>
                  )
                else
                  return (
                    <>
                      <div className="sender-wrapper name-sender">
                        {this.props.name}
                      </div>
                      <div className="sender-wrapper">
                        <pre className="sender">{msg.text}</pre>
                      </div>
                    </>
                  )
              })}
          </div>

          <div>
            <Form
              name="messanger"
              ref={this.formRef}
              onFinish={this.handleFinish}
            >
              <div className="input-container">
                <Form.Item name="message" autoComplete={false}>
                  <Input
                    placeholder="Type here"
                    style={{ width: '302px' }}
                    autoComplete={'off'}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit">
                    <SendOutlined />
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      )
    else return <div></div>
  }
}

const mapStateToProps = ({ dashboard }) => ({
  dashboard
})

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Messanger)
