import { message } from 'antd'
import axios from 'axios'
import constants from 'config/constants'

export function fetchCategotyList(setCategoryList) {
  axios
    .get(`${constants.HOST}/manager/category/list`)
    .then(function({ data: response }) {
      if (response.success) {
        setCategoryList(response.data)
      }
    })
    .catch(function(error) {})
}

export function updateCategory(setShowLoader, values, record) {
  setShowLoader(true)
  axios
    .post(`${constants.HOST}/manager/category/update`, {
      category_id: record.id,
      category: values.category,
      category_chamoru: values.category_chamoru,
      category_korean: values.category_korean,
      category_chinese: values.category_chinese,
      category_chuukese: values.category_chuukese,
      category_japanese: values.category_japanese
    })
    .then(function({ data: response }) {
      setShowLoader(false)
      if (response.success) message.success(response.data)
      else message.error(response.message)
    })
    .catch(function(error) {
      setShowLoader(false)
    })
}
