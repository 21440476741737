import BusinessDetails from 'pages/SignUp/BusinessDetails'
import EnterBusinessName from 'pages/SignUp/EnterBusinessName'
import SelectLocation from 'pages/SignUp/SelectLocation'
import SelectService from 'pages/SignUp/SelectService'
import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import background from 'assets/images/bakery.jpg'
import SuccessMessage from 'pages/SignUp/SuccessMessage'
import { createNewBusiness } from './api'
// import NewBusinessName from './BusinessName'
// import NewSelectService from './BusinessService'
// import NewSelectLocation from './BusinessLocation'
// import NewBusinessDetails from './BusinessDetails'

const NewBusinessModal = props => {
  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector('#dash_sub_header'))
        document.querySelector('#dash_sub_header').style.display = 'none'
      if (document.querySelector('#dash-banner'))
        document.querySelector('#dash-banner').style.display = 'none'
    }, 200)
    return () => {
      try {
        document.querySelector('#dash_sub_header').style.display = 'block'
        document.querySelector('#dash-banner').style.display = 'block'
      } catch (e) {}
    }
  }, [])

  return (
    <div>
      <div
        className="signup-wrapper"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="signup-card">
          <div className="signup-card-container">
            <Switch>
              <Route
                path="/dashboard/new/success"
                component={props => (
                  <SuccessMessage
                    onFinish={() => props.history.push('/dashboard')}
                    {...props}
                  />
                )}
              />
              <Route
                path="/dashboard/new/details"
                component={props => (
                  <BusinessDetails
                    onFinish={(history, setIsSpinning) =>
                      createNewBusiness(history, setIsSpinning)
                    }
                    {...props}
                  />
                )}
              />
              <Route
                path="/dashboard/new/location"
                component={props => (
                  <SelectLocation
                    onFinish={() =>
                      props.history.push('/dashboard/new/details')
                    }
                    {...props}
                  />
                )}
              />
              <Route
                path="/dashboard/new/service"
                component={props => (
                  <SelectService
                    onFinish={() =>
                      props.history.push('/dashboard/new/location')
                    }
                    {...props}
                  />
                )}
              />
              <Route
                path="/dashboard/new"
                component={props => (
                  <EnterBusinessName
                    onFinish={() =>
                      props.history.push('/dashboard/new/service')
                    }
                    {...props}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewBusinessModal
