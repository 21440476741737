import { Button } from 'antd'
import React from 'react'

const PasswordResetMessage = (props) => {
  return (
    <div>
      <h1>Password Reset Link Sent</h1>
      <br />
      <p>A password reset link has been sent to your resgistered email</p>
      <Button
        onClick={() => props.history.push('/signin/email')}
        size="large"
        type="primary"
        htmlType="submit"
        style={{ width: '100%' }}
      >
        OK
      </Button>
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        Don't have an account?{' '}
        <u onClick={() => props.history.push('/signup')}>Sign Up Now</u>
      </div>
    </div>
  )
}

export default PasswordResetMessage
