import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Button } from 'antd'
import BusinessInfromation from 'components/BusinessInformation'
import ProductCarousel from 'components/ProductsCarousel'
import BusinessHeader from '../BusinessHeader'
import { _getUserInfo } from '../api'
import SpinLoader from 'components/SpinLoader'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const HomeDashboard = (props) => {
  const [data, setBusinessData] = useState('')
  const [isSpinning, setIsSpinning] = useState(false)
  const dashboard = useSelector((state) => state.dashboard)
  const params = useParams()
  useEffect(() => {
    _getUserInfo(params.business_id)
    return () => {}
  }, [])
  return (
    <div className="home-dashboard">
      <div className="page-title">
        <h1>Profile</h1>
      </div>
      <SpinLoader spinning={isSpinning}>
        <div className="business-description">
          <BusinessHeader info={dashboard.businessInfo} />

          <div className="actions">
            <Button
              type="primary"
              onClick={() =>
                props.history.push('/dashboard/' + params.business_id + '/new')
              }
            >
              Add New Products/ Services
            </Button>
          </div>
        </div>

        <ProductCarousel
          handleEdit={(id) =>
            props.history.push(
              '/dashboard/' + params.business_id + '/edit-' + id
            )
          }
          loader={false}
          products={dashboard.products ? dashboard.products : ''}
        />
        <BusinessInfromation
          update
          businessData={dashboard.businessInfo}
          location={dashboard.businessInfo}
        />
      </SpinLoader>
    </div>
  )
}

export default HomeDashboard
