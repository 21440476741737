import Footer from 'components/Footer'
import Header from 'components/Header'
import PageNotFound from 'components/PageNotFound'
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import store from 'redux/store'
import Admin from './Mayor'
import { updateSelectedVillage } from './common.slice'
import Dashboard from './Dashboard'
import EmailVerification from './EmailVerification'
import BusinessSearchPage from './ExploreBusiness'
import Home from './Home'
import Login from './Login'
import SignUp from './SignUp'
import './styles.scss'
import Manager from './Manager'
import Chatbot from 'components/ChatBot'

const VillageResourceDirectory = () => {
  useEffect(() => {
    store.dispatch(
      updateSelectedVillage(window.localStorage.getItem('selected-village'))
    )
  }, [])
  return (
    <Router>
      <Chatbot />

      <div className="high-order-component">
        <Route path="" component={Header} />
        <div>
          <Switch>
            <Route path="/email" component={EmailVerification} />
            <Route path="/signin" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/business" component={BusinessSearchPage} />
            <Route path="/mayor" component={Admin} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/manager" component={Manager} />
            <Route path="/" component={Home} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
        <Route path="" component={Footer} />
      </div>
    </Router>
  )
}

export default VillageResourceDirectory
