import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Col, Empty, Rate, Row, Select } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
// import { HeartOutlined } from '@ant-design/icons'
import logo from 'assets/images/businesslogo.png'
import { DownOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchBusinessList,
  fetchCategoryList,
  fetchSubcategoryList
} from '../api'
import { getParameterByName, insertParam } from 'helpers/string'
import { updateBusinessList, updateSubCategoryList } from 'pages/common.slice'
import banner1 from 'assets/images/banner.png'
import SpinLoader from 'components/SpinLoader'
import { fetchAllVillages } from 'components/Header/api'
import {
  handleBusinessLanguage,
  handleCategoryLanguage,
  handleSubCategoryLanguage
} from 'helpers/language'
import locales from 'locales'

const { Option } = Select

const BusinessSearchPage = props => {
  const common = useSelector(state => state.common)
  const [isSubcategoryLoading, setIsSubcategoryLoading] = useState(false)
  const dispatch = useDispatch()
  function handleChange(value) {
    console.log(`selected ${value}`)
  }

  const ratingValues = [4, 3, 2, 1]
  function handleCategory(category) {
    // dispatch(updateSubCategoryList([]))
    // let searchUrl = insertParam('category', category, () =>
    //   insertParam('search', category)
    // )
    // props.history.push('/business?' + searchUrl)
    // fetchSubcategoryList(category, setIsSubcategoryLoading)
    // fetchBusinessList()
  }

  function handleSubCategory(category) {
    let searchUrl = getParameterByName('subcategory') || ''
    let urlArr = ''
    urlArr = searchUrl.split(',')
    const index = urlArr.indexOf(category)
    if (index !== -1) {
      searchUrl = [
        ...urlArr.slice(0, index),
        ...urlArr.slice(index + 1, urlArr.length)
      ].join(',')
    } else searchUrl += `,${category}`
    props.history.push('/business?' + insertParam('subcategory', searchUrl))
    fetchBusinessList()
  }

  function handleVillage(villageName) {
    let searchUrl = getParameterByName('village') || ''
    let urlArr = ''
    urlArr = searchUrl.split(',')
    const index = urlArr.indexOf(villageName)

    if (index !== -1) {
      searchUrl = [
        ...urlArr.slice(0, index),
        ...urlArr.slice(index + 1, urlArr.length)
      ].join(',')
    } else searchUrl += `,${villageName}`
    props.history.push('/business?' + insertParam('village', searchUrl))
    fetchBusinessList()
  }

  useEffect(() => {
    dispatch(updateSubCategoryList([]))
    dispatch(updateBusinessList([]))
    fetchBusinessList()
    if (!common.villages.length) fetchAllVillages()
    if (!common.categories.length) fetchCategoryList()
    fetchSubcategoryList(
      getParameterByName('category'),
      setIsSubcategoryLoading
    )
    return () => {}
  }, [])
  return (
    <div className="business-search-page">
      <SpinLoader spinning={common.businessLoader}>
        <div
          className="search-banner"
          style={{ backgroundImage: `url(${banner1})` }}
        ></div>
        <Row>
          <Col span={24}>
            <div className="search-result-info">
              <div>
                {!!common.businesses.length && (
                  <>
                    1-{common.businesses.length} of over{' '}
                    {common.businesses.length} for "
                    <span style={{ textTransform: 'capitalize' }}>
                      {getParameterByName('search')}
                    </span>
                    "
                  </>
                )}
              </div>
              {/* <div>
                <span>Sort By </span>
                <Select
                  defaultValue="lucy"
                  style={{ width: 120 }}
                  onChange={handleChange}
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
              </div> */}
            </div>
          </Col>
          <Col span={24}>
            <div className="search-result-wrapper">
              <Row>
                <Col xs={24} sm={24} md={6} lg={5} xl={5}>
                  <div className="search-sidebar">
                    {/* <div>
                    <b>Avg. Customer Review</b>
                    <br />
                    <br />
                    {ratingValues.map((rating) => (
                      <div>
                        <Rate
                          // character={<HeartOutlined />}
                          defaultValue={rating}
                          disabled
                        />{' '}
                        & up
                      </div>
                    ))}
                  </div>
                  <br /> */}
                    <div className="categories">
                      {!!getParameterByName('category') && (
                        <b>
                          {locales.categories}
                          <br /> <br />
                        </b>
                      )}

                      <div>
                        {common.categories.map(categoryObj => {
                          let active = getParameterByName('category')?.trim()
                          active = active
                            ? active.includes(categoryObj.category?.trim())
                            : false

                          if (!active) return <div></div>
                          return (
                            <div className={`category`}>
                              <div>
                                <span
                                  onClick={() =>
                                    handleCategory(categoryObj.category)
                                  }
                                >
                                  <LeftOutlined
                                    style={{
                                      fontSize: '10px',
                                      marginBottom: '2px'
                                    }}
                                  />

                                  {handleCategoryLanguage(categoryObj)}
                                </span>
                              </div>
                              <div className="subcategories">
                                <>
                                  {isSubcategoryLoading && <LoadingOutlined />}
                                  {common.subCategories
                                    .filter(
                                      subCategoryObj =>
                                        subCategoryObj.category_id ===
                                        categoryObj.id
                                    )
                                    .map(subCategoryObj => {
                                      const checked = getParameterByName(
                                        'subcategory'
                                      )
                                        ? getParameterByName(
                                            'subcategory'
                                          ).includes(subCategoryObj.subcategory)
                                        : false
                                      return (
                                        <div>
                                          <Checkbox
                                            checked={checked}
                                            onClick={() => {
                                              handleSubCategory(
                                                subCategoryObj.subcategory
                                              )
                                            }}
                                          >
                                            {handleSubCategoryLanguage(
                                              subCategoryObj
                                            )}
                                          </Checkbox>
                                        </div>
                                      )
                                    })}
                                  <br />
                                </>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <b>{locales.villages}</b>
                      <br /> <br />
                      <div>
                        {common.villages.map(villageObj => (
                          <div>
                            <Checkbox
                              checked={
                                getParameterByName('village')
                                  .split(',')
                                  .filter(v => v === villageObj.village).length
                              }
                              onClick={() => {
                                handleVillage(villageObj.village)
                              }}
                            >
                              {villageObj.village}
                            </Checkbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={18} lg={19} xl={19}>
                  <div className="search-results">
                    <Row>
                      {!common.businessLoader && !common.businesses.length && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                      {common.businesses.map(business => (
                        <Col xs={24} sm={24} md={12} lg={5} xl={6}>
                          <div className="business-info-card">
                            <img
                              // src={logo}
                              src={
                                business.image_url ||
                                `https://guma-bus-registry.s3.us-west-1.amazonaws.com/business/logo/${business.id}.png`
                              }
                              alt=""
                              onClick={() =>
                                props.history.push('business/' + business.id)
                              }
                            />
                            <div>
                              <div className="title">
                                {handleBusinessLanguage(business)}
                              </div>
                              {/* <div className="rating">
                              <Rate
                                // character={<HeartOutlined />}
                                allowHalf
                                defaultValue={4}
                                disabled
                                style={{ fontSize: 14 }}
                              />
                              {'   '}
                              <span className="count">
                                <DownOutlined />
                                {'10, 000'}
                              </span>
                            </div> */}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/*
         */}
      </SpinLoader>
    </div>
  )
}

export default BusinessSearchPage
