import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import logo from 'assets/images/GUMA IBM LOGO - ROUND.png'
import { _handleSignout } from 'redux/utils'

const HeaderDashboard = () => {
  const id = window.location.pathname.split('/')
  const history = useHistory()
  return (
    <div className="header-wrapper">
      <div className="top-header">
        <div className="info">
          <Link to="/">
            <span>
              <img src={logo} alt="" />
            </span>
            <span>Village Resource Directory</span>
          </Link>
        </div>
        <div className="info">
          <span className="tab">
            <Link to="/dashboard">
              {window.localStorage.getItem('name')}
              {/* <img
                className="avatar"
                src={window.localStorage.getItem('image_url')}
                alt="profile"
              /> */}
            </Link>
          </span>
          <span className="tab sign-out" onClick={_handleSignout}>
            Sign Out
          </span>
        </div>
      </div>
      <div id="dash_sub_header" className="bottom-header">
        <nav>
          <ul>
            <li>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/dashboard/' + id[2])}
              >
                Profile
              </span>
              <span>|</span>
            </li>
            <li>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/dashboard/' + id[2] + '/status')}
              >
                Registration Status
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default HeaderDashboard
