import { LoadingOutlined } from '@ant-design/icons'
import { Space, Table } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchVillages } from './api'
import EditVillage from './EditVillage'
import './styles.scss'

const VillagesManagement = () => {
  const [villagesList, setVillagesList] = useState('')
  const columns = [
    {
      title: 'Mun gis',
      dataIndex: 'mun_gis',
      key: 'mun_gis'
    },
    {
      title: 'Village',
      dataIndex: 'village',
      key: 'village'
    },
    {
      title: 'Chamoru Name',
      dataIndex: 'chamoru_name',
      key: 'chamoru_name'
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Link>ok</Link>
    //     </Space>
    //   )
    // }
  ]

  useEffect(() => {
    fetchVillages(setVillagesList)
    return () => {}
  }, [])
  if (!villagesList)
    return (
      <div className="full-screen-loader">
        <div>
          <LoadingOutlined />
        </div>
      </div>
    )

  return (
    <div className="manager-villages-wrapper">
      <h1>Villages</h1>
      <Table
        pagination={false}
        dataSource={
          villagesList
            ? villagesList.map((cat, key) => {
                return { ...cat, key }
              })
            : []
        }
        columns={columns}
        expandable={{
          expandedRowRender: record => <EditVillage record={record} />
        }}
      />
    </div>
  )
}

export default VillagesManagement
