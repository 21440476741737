import { Button, Form, Input } from 'antd'
import SpinLoader from 'components/SpinLoader'
import React, { useState } from 'react'
import { _updateAdminProfile } from '../api'
import './styles.scss'

const Profile = () => {
  const [formData, setFormData] = useState({})
  const [isSpinning, setIsSpinning] = useState(false)
  const [form] = Form.useForm()
  const onFinish = (values) => {
    _updateAdminProfile(formData, setIsSpinning)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    setFormData({ ...formData, [attr]: value })
  }

  return (
    <div className="admin-profile-wrapper">
      <SpinLoader spinning={isSpinning}>
        <div className="page-title">
          <h1>Profile</h1>
        </div>
        <div className="form-wrapper">
          <Form
            form={form}
            requiredMark={false}
            labelAlign="left"
            name="profileDetails"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 8 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="User Name"
              name="userName"
              rules={[
                { required: true, message: 'Please input your username!' }
              ]}
            >
              <Input
                className="input-style"
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' }
              ]}
            >
              <Input
                className="input-style"
                type="password"
                onChange={(e) => handleChange('description', e.target.value)}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 0 }}>
              <Button type="primary" htmlType="submit">
                Update Information
              </Button>
            </Form.Item>
          </Form>
        </div>
      </SpinLoader>
    </div>
  )
}

export default Profile
