import { CheckOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Tag } from 'antd'
import {
  fetchCategoryList,
  fetchSubcategoryList
} from 'pages/ExploreBusiness/api'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategory } from '../api'
import { _handleForm, _updateSubcategoryList } from '../signup.slice'

const SelectService = props => {
  const [form] = Form.useForm()
  const signup = useSelector(state => state.signup)
  const common = useSelector(state => state.common)
  const dispatch = useDispatch()
  const onFinish = values => {
    getCategory(signup)
    if (signup.subcategory.length) {
      if (props.onFinish) {
        props.onFinish(values)
        return
      }
      props.history.push('/signup/business/location')
    } else message.warn('Please select at least 1 service')
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  function handleChange(attr, value) {
    dispatch(_handleForm({ attr, value }))
  }

  function handleSelectSubCategory(subcategory) {
    if (signup.subcategory.includes(subcategory)) {
      const index = signup.subcategory.indexOf(subcategory)
      dispatch(
        _updateSubcategoryList([
          ...signup.subcategory.slice(0, index),
          ...signup.subcategory.slice(index + 1, signup.subcategory.length)
        ])
      )
    } else {
      dispatch(_updateSubcategoryList([...signup.subcategory, subcategory]))
    }
  }
  useEffect(() => {
    form.setFieldsValue({
      service: signup.service
    })

    fetchCategoryList()
    fetchSubcategoryList()
    return () => {}
  }, [])
  return (
    <div className="email-signup-form">
      <div
        style={{ cursor: 'pointer', width: '30px', marginBottom: '12px' }}
        onClick={() => props.history.goBack()}
      >
        <LeftOutlined />
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="signup-card-flex">
          <div className="top-container">
            <h1>Grow your business</h1>
            <h4>What type of service you want to provide?</h4>
            {common.categories.length && common.subCategories.length ? (
              <div>
                {common.categories.map(categoryObj => (
                  <div>
                    <div>{categoryObj.category}</div>
                    <div>
                      {common.subCategories
                        .filter(
                          subcategory =>
                            categoryObj.id === subcategory.category_id
                        )
                        .map(subCatergoryObj => {
                          const checked = signup.subcategory.includes(
                            subCatergoryObj.subcategory
                          )
                          return (
                            <Tag.CheckableTag
                              color="green"
                              checked={checked}
                              onChange={() =>
                                handleSelectSubCategory(
                                  subCatergoryObj.subcategory
                                )
                              }
                            >
                              {checked ? <CheckOutlined /> : '+'}{' '}
                              {subCatergoryObj.subcategory}
                            </Tag.CheckableTag>
                          )
                        })}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <LoadingOutlined />
            )}
          </div>
          <div className="bottom-container"></div>
          <Form.Item>
            <div
              className="btn-flex"
              style={{ width: '100%', marginTop: '32px' }}
            >
              <Button
                size="large"
                type={'default'}
                style={{ width: '130px' }}
                onClick={() => props.history.goBack()}
              >
                Back
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ width: '130px' }}
              >
                Continue
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default SelectService
