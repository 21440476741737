import React, { useEffect, useState } from 'react'
import { Table, Tag, Space } from 'antd'
import './styles.scss'
import { getParameterByName } from 'helpers/string'
import { LoadingOutlined } from '@ant-design/icons'
import EditProduct from './EditProduct'
import { fetchProdcuts } from './api'

const Products = props => {
  const [productList, setProductList] = useState('')
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'product_name',
      key: 'product_name'
    },
    {
      title: 'Description',
      dataIndex: 'product_description',
      key: 'product_description'
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Link>ok</Link>
    //     </Space>
    //   )
    // }
  ]

  const catgory_id = getParameterByName('category')
  useEffect(() => {
    fetchProdcuts(setProductList)
    return () => {}
  }, [])

  if (!productList)
    return (
      <div className="full-screen-loader ">
        <div>
          <LoadingOutlined />
        </div>
      </div>
    )
  return (
    <div className="products-managerment-wrapper">
      <h1>Products</h1>
      <Table
        // pagination={false}
        columns={columns}
        dataSource={
          productList
            ? productList.map((cat, key) => {
                return { ...cat, key }
              })
            : []
        }
        expandable={{
          expandedRowRender: record => <EditProduct record={record} />
        }}
      />
    </div>
  )
}
export default Products
