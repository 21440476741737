import React, { useEffect, useState } from 'react'
import { Table, Tag, Space } from 'antd'
import './styles.scss'
import { fetchCategotyList } from './api'
import { Link } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import EditCategory from './EditCategory'

const CategoryManagement = () => {
  const [categoryList, setCategoryList] = useState('')
  const columns = [
    {
      title: 'Category Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Category code',
      dataIndex: 'category_code',
      key: 'category_code'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link to={'/manager/subcategory?category=' + record.id}>
              Subcategories
            </Link>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    fetchCategotyList(setCategoryList)
    return () => {}
  }, [])
  if (!categoryList)
    return (
      <div className="full-screen-loader ">
        <div>
          <LoadingOutlined />
        </div>
      </div>
    )
  return (
    <div className="category-managerment-wrapper">
      <h1>Category</h1>

      <Table
        pagination={false}
        columns={columns}
        dataSource={
          categoryList
            ? categoryList.map((cat, key) => {
                return { ...cat, key }
              })
            : []
        }
        expandable={{
          expandedRowRender: record => <EditCategory record={record} />
        }}
      />
    </div>
  )
}

export default CategoryManagement
