import axios from 'axios'
import constants from 'config/constants'
import {
  handleChatAnsLanguage,
  handleChatQueLanguage,
  handleChatTypeLanguage
} from 'helpers/language'

export function fetchChatBotFAQ(setChatBotFaq) {
  axios
    .get(`${constants.HOST}/lookup/chatbot`)
    .then(function({ data: response }) {
      if (response.success) {
        const obj = [
          {
            id: '0',
            message: 'Welcome to Village resource library!',
            trigger: '1'
          }
        ]
        const typesSet = new Set(response.data.map(t => t.type.trim()))
        let types = Array.from(typesSet)
        obj.push({
          id: '1',
          end: false,
          options: types.map((type, index) => {
            const langType = handleChatTypeLanguage(
              response.data.filter(t => type === t.type.trim())[0]
            )
            return {
              label: langType,
              value: langType,
              trigger: `${index + 3}`
            }
          })
        })
        types.map((type, x) => {
          obj.push({
            id: `${x + 3}`,
            end: false,
            options: response.data
              .filter(d => d.type.trim() === type)
              .map((item, y) => {
                return {
                  label: handleChatQueLanguage(item),
                  value: handleChatQueLanguage(item),
                  trigger: item.answer ? `${(x + 1) * 100 + y}` : '1'
                }
              })
          })
        })

        types.map((type, x) => {
          response.data
            .filter(d => d.type.trim() === type)
            .map((item, y) => {
              if (item.answer) {
                obj.push({
                  id: `${(x + 1) * 100 + y}`,
                  message: handleChatAnsLanguage(item),
                  trigger: '1'
                })
              }
            })
        })

        console.log(obj)

        setChatBotFaq(obj)
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}
