import { CopyRightFooter } from 'components/Footer'
import React from 'react'
import './styles.scss'

const SignUpFooter = () => {
  return (
    <div className="signup-footer-wrapper">
      <CopyRightFooter />
    </div>
  )
}

export default SignUpFooter
