import { FacebookFilled, GoogleOutlined, MailFilled } from '@ant-design/icons'
import { Button } from 'antd'
import Btn from 'components/Button'
import SpinLoader from 'components/SpinLoader'
import { initGoogleapi, initGoogleClient } from 'helpers/google'
import { _postLoginWithAuth } from 'pages/Login/api'
import React, { useEffect, useState } from 'react'
import SignInLabel from '../SignInLabel'
import './styles.scss'
import logo from 'assets/images/GUMA IBM LOGO - ROUND.png'

const Register = props => {
  const [isLoading, setIsLoading] = useState(true)
  function handleContinue() {
    const isBusinessPage = window.location.pathname.includes('business')
    if (isBusinessPage) props.history.push('/signup/business/email')
    else props.history.push('/signup/email')
  }

  function loginWithGoogle() {
    initGoogleClient(data => {
      _postLoginWithAuth(data, setIsLoading, props.history)
    })
  }
  useEffect(() => {
    setTimeout(() => {
      initGoogleapi(setIsLoading)
    }, 100)
  }, [])
  return (
    <div className="signup-home ">
      <SpinLoader spinning={isLoading}>
        <div className="top-container">
          <img src={logo} width={130} alt="logo" />
          <br />
          <br />
          <h1>Sign Up</h1>
          <br />
          <div onClick={loginWithGoogle}>
            <Btn icon={<GoogleOutlined />} label="Sign Up With Google" />
          </div>{' '}
          {/* <Btn icon={<FacebookFilled />} label="Sign Up With Facebook" /> */}
          <div style={{ cursor: 'pointer' }} onClick={handleContinue}>
            <Btn icon={<MailFilled />} label="Sign Up With Email" />
          </div>
        </div>
        <div className="bottom-container">
          {/* <Button type="primary" size="large" onClick={handleContinue}>
          Continue
        </Button> */}
          <SignInLabel />
        </div>
      </SpinLoader>
    </div>
  )
}

export default Register
