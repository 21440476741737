import React from 'react'
import { Space, Table, Tag } from 'antd'
// import reqwest from 'reqwest'
import './styles.scss'
import { _getBusinessList } from '../api'
import {
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  MessageFilled,
  PrinterFilled
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params
})

class RegistrationDashboard extends React.Component {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10
    },
    loading: false
  }

  componentDidMount() {
    const { pagination } = this.state
    this.fetch({ pagination })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    })
  }

  fetch = (params = {}) => {
    console.log(params)
    this.setState({ loading: true })
    _getBusinessList(data => {
      this.setState({
        loading: false,
        data,
        pagination: {
          ...params.pagination,
          total: 200
        }
      })
    })

    // fetch({
    //   url: 'https://randomuser.me/api',
    //   method: 'get',
    //   type: 'json',
    //   data: getRandomuserParams(params)
    // }).then((data) => {
    //   console.log(data)
    //   this.setState({
    //     loading: false,
    //     data: data.results,
    //     pagination: {
    //       ...params.pagination,
    //       total: 200
    //       // 200 is mock data, you should read it from server
    //       // total: data.totalCount,
    //     }
    //   })
    // })
  }
  columns = [
    {
      title: 'Business',
      dataIndex: 'business_name',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'name'
      // filters: [
      //   { text: 'Male', value: 'male' },
      //   { text: 'Female', value: 'female' }
      // ],
      // width: '20%'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Contact No',
      dataIndex: 'phone'
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category'
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      render: status => {
        switch (status) {
          case 'APPROVED':
            return <Tag color="green">{status}</Tag>
          case 'REJECTED':
            return <Tag color="error">{status}</Tag>
          case 'SUBMITTED':
            return <Tag color="blue">{status}</Tag>
          default:
            return <Tag>{status}</Tag>
        }
      }
    },
    {
      title: 'Status Date',
      dataIndex: 'status_date',
      render: status_date => {
        return status_date ? new Date(status_date).toLocaleDateString() : ''
      }
    },
    {
      title: 'Actions',
      dataIndex: '',
      render: data => {
        return (
          <div>
            <Space>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.props.history.push('/mayor/edit-' + data.id)
                }
              >
                <EditFilled />
              </span>
              {/* <DownloadOutlined />
              <PrinterFilled />
              <DeleteFilled /> */}
            </Space>
          </div>
        )
      }
    },
    {
      title: 'Messages',
      dataIndex: '',
      render: data => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              this.props.history.push(
                `/mayor/messages?id=${data.id}&name=${data.business_name}`
              )
            }
          >
            <MessageFilled />
          </div>
        )
      }
    }
  ]

  render() {
    const { data, pagination, loading } = this.state

    return (
      <div className="registration-dashboard">
        <div className="page-title">
          <h1>Registration Dashboard</h1>
        </div>
        <div className="table-data">
          <h2>{localStorage.getItem('admin_village')?localStorage.getItem('admin_village'):'Dededo'} Mayors's Office</h2>
          <Table
            columns={this.columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={false}
            loading={loading}
            // rowSelection
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    )
  }
}

export default RegistrationDashboard
