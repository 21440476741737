// https://developers.google.com/identity/sign-in/web/reference
window.GoogleAuth = null // Google Auth object.
export function initGoogleapi(setIsLoading) {
  if (window.GoogleAuth) {
    setIsLoading(false)
    return
  }
  try {
    window.gapi.load('auth2', async function () {
      /* Ready. Make a call to gapi.auth2.init or some other API */
      window.gapi.auth2
        .init({
          clientId:
            '772104518025-610q3ca9bdp5bh73qftdpcufp5igph9c.apps.googleusercontent.com'
        })
        .then(function () {
          setIsLoading(false)
          window.GoogleAuth = window.gapi.auth2.getAuthInstance()
        })
    })
  } catch (e) {}
}

export function initGoogleClient(callback) {
  try {
    window.GoogleAuth.signIn().then((data) => onSuccess(data, callback))
  } catch (e) {}
}

const onSuccess = (googleUser, callback) => {
  let auth = googleUser.getAuthResponse().id_token
  // localStorage.setItem('admin-token', auth)
  const profile = googleUser.getBasicProfile()
  const email = profile.getEmail()
  const name = profile.getName()
  callback && callback({ name, email })
}
