import { LoadingOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import locales from 'locales'
import {
  handleIsVisibleVillageModal,
  updateSelectedVillage
} from 'pages/common.slice'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllVillages } from '../api'

const SelectVillage = props => {
  const dispatch = useDispatch()
  const common = useSelector(state => state.common)
  const handleCancel = () => {
    dispatch(handleIsVisibleVillageModal(false))
  }

  function onSelectVillage(village) {
    window.localStorage.setItem('selected-village', village)
    dispatch(updateSelectedVillage(village))
    handleCancel()
  }

  useEffect(() => {
    if (!common.villages.length) {
      fetchAllVillages()
    }
  }, [])
  return (
    <span>
      <Modal
        centered
        visible={common.isVisibleVillageModal}
        onCancel={handleCancel}
        footer={null}
        header={null}
        width={1000}
      >
        <div className="village-modal-wrapper">
          <h2>{locales.selectYourVillage}</h2>
          <div className="list">
            {common.villages.length ? (
              common.villages.map(villageObj => (
                <div
                  className={`village-btn ${
                    common.selectedVillage === villageObj.village
                      ? 'active'
                      : ''
                  }`}
                >
                  <Button
                    type={
                      common.selectedVillage === villageObj.village
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => {
                      onSelectVillage(villageObj.village)
                    }}
                  >
                    {villageObj.village}
                  </Button>
                </div>
              ))
            ) : (
              <div className="spin-loader-font">
                <LoadingOutlined />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </span>
  )
}

export default SelectVillage
