import { Button } from 'antd'
import React from 'react'
import './styles.scss'

const SuccessMessage = (props) => {
  const { pathname } = window.location
  const isBusinessPage = pathname.includes('business')

  function handleContinue() {
    if (props.onFinish) {
      props.onFinish()
      return
    }
    props.history.push('/signin')
  }
  return (
    <div className="success-message-signup">
      <h2>Account Created Successfully</h2>
      {isBusinessPage ? (
        <div>
          Your account has been created successfully. Your business registration
          will be reviewed by your Village Mayor's Office. Please sign in now
          and track the status of your submission.
        </div>
      ) : (
        <div>
          Your account has been created successfully. Please sign in now
        </div>
      )}
      <br />
      <Button
        size="large"
        type="primary"
        onClick={handleContinue}
        style={{ width: '100%' }}
      >
        Continue to Sign In
      </Button>
    </div>
  )
}

export default SuccessMessage
