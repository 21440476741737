const routes = {
  //signin & signup
  login: '/user/login',
  createUser: '/user/signup',
  verifyEmail: '/user/email/verify',
  resendVerificationEmail: '/user/email/resend',
  createBusiness: '/user/business/create',
  loginWithAuth: '/user/signin/auth',

  // dashbaord
  userBusinesses: '/user/businesses',
  dashboard: '/user/dashboard',
  businessInfo: '/user/info',
  uploadBusinessLogo: '/business/upload/logo',
  uploadBusinessProduct: '/business/upload/product',
  updateBusinessInfo: '/business/update/info',

  addProduct: '/business/add/product',
  getProduct: '/business/get/product',
  updateProduct: '/business/update/product',
  deleteProduct: '/business/delete/product',

  // business
  fetchBusinesses: '/business/list',
  fetchBusiness: '/business/product/list',
  createNewBusiness: '/business/add-new',

  // genral
  fetchAllVillages: '/village/list',
  fetchSubCategories: '/subcategory/list',
  fetchCategories: '/category/list',
  fetchLocationString: '/lookup/location',
  fetchTags: '/lookup/tags',

  //admin

  adminFetchBusinessAll: '/admin/business/getall',
  adminFetchBusinessData: '/admin/business/fetch',
  adminUpdateStatus: '/admin/status/update',
  adminLogin: '/admin/login'
}

export default routes
