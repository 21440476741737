import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  villages: [],
  businesses: [],
  subCategories: [],
  products: [],
  categories: [],
  isVisibleVillageModal: false,
  selectedVilllage: '',
  signedIn: window.localStorage.getItem('email')
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateSelectedVillage: (state, action) => {
      state.selectedVillage = action.payload
    },
    handleIsVisibleVillageModal: (state, action) => {
      state.isVisibleVillageModal = action.payload
    },
    updateVillages: (state, action) => {
      state.villages = action.payload
    },
    updateBusinessList: (state, action) => {
      state.businesses = action.payload
    },
    updateCategoryList: (state, action) => {
      state.categories = action.payload
    },
    updateSubCategoryList: (state, action) => {
      state.subCategories = action.payload
    },
    updateBusinessData: (state, action) => {
      state.businessData = action.payload
    },
    updateProductsData: (state, action) => {
      state.products = action.payload
    },
    updateLocationData: (state, action) => {
      state.location = action.payload
    },
    updateBusinessListLoader: (state, action) => {
      state.businessLoader = action.payload
    },
    updatProductsListLoader: (state, action) => {
      state.productLoader = action.payload
    },
    updatSignInStatus: (state, action) => {
      state.signedIn = action.payload
    }
  }
})

export const {
  updateSelectedVillage,
  handleIsVisibleVillageModal,
  updateVillages,
  updateBusinessList,
  updateSubCategoryList,
  updateBusinessData,
  updateProductsData,
  updateCategoryList,
  updateLocationData,
  updateBusinessListLoader,
  updatProductsListLoader,
  updatSignInStatus
} = commonSlice.actions
export default commonSlice.reducer
