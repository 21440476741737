import { Drawer } from 'antd'
import React from 'react'

const DrawerMenu = ({ visible, onClose }) => {
  return (
    <div>
      <Drawer
        width={'100%'}
        // title="Basic Drawer"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer>
    </div>
  )
}

export default DrawerMenu
