import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import EmailForm from './EmailForm'
import './styles.scss'
import SuccessMessage from './SuccessMessage'
import VerifyEMail from './VerifyEMail'
import background from 'assets/images/bakery.jpg'
import Register from './Register'
import SignUpHeader from './SignUpHeader'
import SignUpFooter from './SignUpFooter'
import PageNotFound from 'components/PageNotFound'
import SelectService from './SelectService'
import SelectLocation from './SelectLocation'
import BusinessDetails from './BusinessDetails'
import EnterBusinessName from './EnterBusinessName'
import { _handleForm } from './signup.slice'
import { useDispatch } from 'react-redux'
import { fetchUserAccountDetails } from './api'
import { LoadingOutlined } from '@ant-design/icons'

const SignUp = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    document.querySelector('header').style.display = 'none'
    document.querySelector('footer').style.display = 'none'

    fetchUserAccountDetails(props.history, setIsLoading)

    const email = window.localStorage.getItem('email')
    dispatch(_handleForm({ attr: 'email', value: email }))
    return () => {
      document.querySelector('header').style.display = 'block'
      document.querySelector('footer').style.display = 'block'
    }
  }, [])

  if (isLoading) return <LoadingOutlined />

  return (
    <div
      className="signup-wrapper"
      style={{ backgroundImage: `url(${background})` }}
    >
      <Route component={SignUpHeader} />
      <div className="signup-card">
        <div className="signup-card-container">
          <Switch>
            <Route path="/signup/business/success" component={SuccessMessage} />
            <Route path="/signup/business/info" component={BusinessDetails} />
            <Route
              path="/signup/business/location"
              component={SelectLocation}
            />
            <Route path="/signup/business/service" component={SelectService} />
            <Route path="/signup/business/name" component={EnterBusinessName} />
            <Route path="/signup/business/verify" component={VerifyEMail} />
            <Route path="/signup/business/email" component={EmailForm} />
            <Route path="/signup/business" component={Register} exact />

            <Route path="/signup/success" component={SuccessMessage} />
            <Route path="/signup/verify" component={VerifyEMail} />
            <Route path="/signup/email" component={EmailForm} />
            <Route path="/signup" component={Register} exact />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
      <Route component={SignUpFooter} />
    </div>
  )
}

export default SignUp
